import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'antd/lib/button'
import { useSt } from 'state/state'
import ExecuteActionModal from 'components/modals/ExecuteActionModal'
import { renderIcon } from 'helpers/icons'
import { renderFullDate } from 'helpers/time'
import { UserTimeline } from 'types/timeline'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import Countdown from 'react-countdown'

type EventTypesParams = {
    event: UserTimeline
}

const RESEND_COUNTDOWN = 60

export const EventTypes: FunctionComponent<EventTypesParams> = ({ event }) => {
    const st = useSt()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [resendDisabled, setResendDisabled] = useState(false)
    const [countdown, setCountdown] = useState(() => {
        const lastResend = localStorage.getItem(`exchangeLastResend_${event.OperationId}`)
        if (lastResend) {
            const diff = Math.floor((new Date().getTime() - parseInt(lastResend)) / 1000)
            if (diff < RESEND_COUNTDOWN) {
                setResendDisabled(true)
                return RESEND_COUNTDOWN - diff
            }
        }
        return 0
    })

    const fullDate = renderFullDate(event.DateOfOccurence || event.DropOffDate)
    const startAction = async (isDropoff?: boolean) => {
        setIsLoading(true)
        isDropoff ? await st.http.startDropoff(event.OperationId) : await st.http.startPickup(event.OperationId)
        setIsLoading(false)

        st.addModal({
            Comp: (
                <ExecuteActionModal
                    onExec={() =>
                        isDropoff ? st.http.executeDropoff(event.OperationId) : st.http.executePickup(event.OperationId)
                    }
                    onFinish={() => {
                        st.http.refreshTimeline()
                        st.http.loadExchanges()
                    }}
                />
            ),
            title: { key: 'feedback.locker.open' },
            description: 'feedback.locker.description',
            icon: renderIcon({ name: 'unlock', circleType: 'primary' }),
        })
    }

    const cancel = async (isDropoff?: boolean, options?: { title?: { key: string }; description?: string }) => {
        st.addModal({
            Comp: (
                <ExecuteModal
                    onExec={() =>
                        isDropoff ? st.http.cancelDropoff(event.OperationId) : st.http.cancelPickup(event.OperationId)
                    }
                    onFinish={() => {
                        st.http.refreshTimeline()
                    }}
                />
            ),
            title: { key: 'feedback.cancelDropOff.title' },
            description: 'feedback.cancelDropOff.description',
            icon: renderIcon({ name: 'trash', circleType: 'primary' }),
            ...(options || {}),
        })
    }

    const sendEmail = async (emailType: 'dropoff' | 'pickup' | 'payment') => {
        localStorage.setItem(`exchangeLastResend_${event.OperationId}`, new Date().getTime().toString())

        setCountdown(RESEND_COUNTDOWN)
        setResendDisabled(true)
        switch (emailType) {
            case 'dropoff':
                st.http.sendDropoffEmail(event.OperationId)
                break
            case 'pickup':
                st.http.sendPickupEmail(event.OperationId)
                break
            case 'payment':
                st.http.sendPaymentEmail(event.OperationId)
                break
            default:
                return
        }
    }

    switch (event.Name) {
        case 'UserDropOffStep':
            return (
                <div>
                    <p>
                        <b>{t('events.userDropOffStep', { name: event.DropOffUser.Name, date: fullDate })}</b>
                    </p>
                    <div className="row mt-3">
                        <Button onClick={() => cancel(true)} type="ghost" className="mr-4">
                            {t('common.cancel')}
                        </Button>
                        <Button disabled={resendDisabled} onClick={() => sendEmail('dropoff')} type="primary">
                            {t('common.resendEmail')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span className="ml-2">({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setResendDisabled(false)
                                        localStorage.removeItem(`exchangeLastResend_${event.OperationId}`)
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )
        case 'UserDropOffFinishedStep':
            return <p>{t('events.userDropOffFinishedStep', { name: event.DropOffUser.Name })}</p>
        case 'GaragePickUpStep':
            return (
                <div>
                    <p className="mb-3">
                        <b>{t('events.garagePickUpStep')}</b>
                    </p>
                    <Button type="primary" onClick={() => startAction()} loading={isLoading}>
                        {t('common.retrieveKey')}
                    </Button>
                </div>
            )
        case 'PickUpFinishedStep':
            if (st.currentUser?.fullName === event.PickUpUser.Name) {
                return <p>{t('events.pickUpFinishedStep', { name: event.PickUpUser.Name })}</p>
            }

            return <p>{t('events.pickUpFinishedStep', { name: event.PickUpUser.Name })}</p>
        case 'GarageDropOffStep':
            return (
                <div>
                    <p className="mb-3">
                        <b>{t('events.garageDropOffStep')}</b>
                    </p>
                    <Button type="primary" onClick={() => startAction(true)} loading={isLoading}>
                        {t('common.openLocker')}
                    </Button>
                </div>
            )
        case 'GarageDropOffFinishedStep':
            return <p>{t('events.garageDropOffFinishedStep', { name: event.DropOffUser.Name })}</p>
        case 'GarageDropOffCanceledStep':
            return <p>{t('events.garageDropOffCanceledStep', { name: event.DropOffUser.Name })}</p>
        case 'UserDropOffPlanedStep':
            return <p>{t('events.userDropOffPlanedStep', { name: event.DropOffUser.Name })}</p>

        case 'PickUpCanceledStep':
            return <p>{t('events.pickUpCanceledStep', { name: event.PickUpUser.Name })}</p>
        case 'PaidGarageReturnItemPlanedStep':
            return <p>{t('events.paidGarageReturnItemPlanedStep', { name: event.PickUpUser.Name })}</p>
        case 'FreeGarageReturnItemPlanedStep':
            return <p>{t('events.freeGarageReturnItemPlanedStep', { name: event.PickUpUser.Name })}</p>
        case 'UserPaymentStep':
            return (
                <div>
                    <p>
                        <b>{t('events.userPaymentStep')}</b>
                    </p>
                    <div className="row mt-3">
                        <Button type="text" className="mr-4">
                            {t('common.cancelAndKey')}
                        </Button>
                        <Button disabled={resendDisabled} onClick={() => sendEmail('payment')} type="primary">
                            {t('common.resendEmail')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span className="ml-2">({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setResendDisabled(false)
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )
        case 'UserPaymentDoneStep':
            return (
                <div>
                    <p>{t('events.userPaymentDoneStep')}</p>
                    <div className="mt-3">
                        <Button onClick={() => st.http.downloadInvoice(event.OperationId)} type="text" className="mr-4">
                            {t('common.downloadInvoice')}
                        </Button>
                    </div>
                </div>
            )
        case 'UserPickUpStep':
            return (
                <div>
                    <p>
                        <b>{t('events.userPickUpStep')}</b>
                    </p>
                    <div className="row mt-3">
                        <Button
                            onClick={() =>
                                cancel(false, {
                                    title: { key: 'feedback.cancelRetrieve.title' },
                                    description: 'feedback.cancelRetrieve.description',
                                })
                            }
                            type="text"
                            className="mr-4"
                        >
                            {t('common.cancelAndKey')}
                        </Button>
                        <Button disabled={resendDisabled} onClick={() => sendEmail('pickup')} type="primary">
                            {t('common.resendEmail')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span className="ml-2">({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setResendDisabled(false)
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )
        default:
            return <div />
    }
}
