export const TRANSLATIONS_IT = {
    common: {
        at: 'a',
        noResults: '0 risultati trovati',
        back: 'Indietro',
        modifyInfo: 'Modifica',
        delete: 'Eliminare',
        cancel: 'Cancella',
        save: 'Registra',
        confirm: 'Confermare',
        now: 'Ora',
        resendEmail: "Invia di nuovo l'email esplicativa",
        retrieveKey: 'Recuperare la chiave',
        cancelAndKey: 'Annullare e recuperare la chiave',
        downloadInvoice: 'Scarica la fattura',
        previous: 'Precedente',
        next: 'Prossimo',
        validate: 'Convalidare',
        hello: 'Ciao',
        openLocker: "Aprire l'armadietto",
        lockerIsOpened: "L'armadietto è aperto",
        unavailable: 'Non disponibile',
        noReturn: 'Senza ritorno',
        noDeparture: 'Nessuna partenza',
        all: 'Tutti',
        home: 'Casa',
        noObjects: 'Nessun oggetto',
        generalInformations: 'Informazioni generali',
        details: 'Dettagli',
        logout: 'Disconnettere',
        vehicle: 'Veicolo',
        dropOffKey: 'Rimuovere la chiave',
        registration: 'Registrazione',
        search: 'Cerca',
        seeMore: 'Vedere di più',
        summary: 'Riassunto',
        notSpecified: 'Non noto',
        add: 'Aggiungi',
    },

    header: {
        exchanges: 'Scambi',
        reservations: 'Prenotazioni',
        vehicles: 'Veicoli',
        clients: 'Clienti',
    },

    language: {
        french: 'Francese',
        english: 'Inglese',
        italian: 'Italiano',
    },

    informations: {
        clientID: 'ID cliente',
        Lastname: 'Nome',
        Firstname: 'Nome',
        Address: 'Indirizzo completo',
        PhoneNumber: 'Telefono',
        Email: 'Email',
        brand: 'Marca',
        model: 'Modello',
        registrationNumber: 'Immatricolazione',
        vehicleId: 'ID del veicolo',
        reservationId: 'ID prenotazione',
        status: 'Stato',
        startDate: 'Data di partenza',
        endDate: 'Data di ritorno',
        totalReservations: 'Totale della prenotazione',
        waitingVehicleAssociation: 'In attesa del deposito delle chiavi',
        waitingVehiclePickup: 'In attesa del ritorno',
        waitingVehicleDropOff: 'In attesa di partire',
        closed: 'Completato',
        canceled: 'Cancellato',
        companyID: "ID dell'azienda",
        companyName: "Nome dell'azienda",
        totalAgency: 'Numero di agenzie',
        totalEmployees: 'Numero di dipendenti',
        garageID: 'ID del garage',
        createdAt: 'Creato il',
        job: 'Posta',
        registrationDate: 'Data di registrazione',
        lastModified: 'Ultima modifica il',
        PreferedLanguage: 'Lingua preferita',
        roles: 'Ruoli',
    },

    feedback: {
        confirmAction: 'La sua richiesta è stata presa in considerazione.',
        planDropOff: "L'appuntamento per la consegna è stato creato.",
        planReturn: 'La tua richiesta è stata creata.',
        lockerOpened: "L'armadietto è stato aperto.",
        redirectPayment: 'Sarai reindirizzato alla pagina di pagamento...',
        objectCreated: "L'oggetto è stato creato.",
        error: 'Si è verificato un errore. Si prega di riprovare più tardi.',
        clientExist: 'Questo indirizzo e-mail esiste già.',
        locker: {
            open: 'Sei sicuro di voler davvero aprire il Locker?',
            description: 'Stai per aprire il Locker, questa azione è irreversibile',
        },
        client: {
            modified: 'Il cliente è stato modificato.',
            created: 'Il cliente è stato creato.',
            deleted: "L'utente {{ name }} è stato cancellato.",
            confirmDelete: 'Sei sicuro di voler davvero eliminare {{name}} ?',
        },
        vehicle: {
            created: 'Il veicolo è stato creato.',
            deleted: 'Il veicolo {{name}} è stato eliminato.',
            confirmDelete: 'Sei sicuro di voler davvero rimuovere il veicolo {{name}} ?',
            descriptionDelete: 'Stai per cancellare il veicolo, fai attenzione che questa azione è irreversibile',
            pickUp: "Stai per riavere la chiave dell'auto. Vuoi continuare?",
        },
        agency: {
            created: "L'agenzia è stata creata.",
            deleted: "L'agenzia {{ name }} è stata eliminata.",
            confirmDelete: "Sei sicuro di voler davvero cancellare l'agenzia {{name}}?",
            descriptionDelete: 'Stai per cancellare questa agenzia, fai attenzione che questa azione è irreversibile',
            modified: "L'agenzia {{ name }} è stata modificata.",
        },
        employee: {
            created: "L'impiegato è stato creato.",
            deleted: 'Il dipendente {{ name }} è stato eliminato.',
            confirmDelete: "Sei sicuro di voler davvero rimuovere l'impiegato {{name}} ?",
            descriptionDelete: 'Stai per cancellare questo dipendente, questa azione è irreversibile',
            modified: 'Il dipendente {{ name }} è stato modificato.',
        },
    },

    error: {
        email: "Per favore, inserisci un'email valida",
        password: 'Inserisci una password valida',
        phone: 'Inserisci un numero di telefono valido',
    },

    form: {
        label: {
            password: 'Password',
            Firstname: 'Nome',
            Lastname: 'Cognome',
            Name: 'Nome',
            Email: 'Email',
            PhoneNumber: 'Telefono',
            Address: 'Indirizzo',
            PersonalItemName: "Nome dell'oggetto",
            fullName: 'Nom completare o e-mail',
            selectObject: 'Storia degli oggetti',
            agency: 'Agenzia',
            locker: 'Armadietto',
            dropOff: 'Data di presentazione',
            pickUp: 'Data di ritiro',
            registration: 'Immatricolazione',
            brand: 'Marque',
            model: 'Modello',
            rentalId: 'ID prenotazione',
            fullAddress: 'Indirizzo completo',
            Post: 'Invia',
            searchVehicle: 'Ricerca di veicoli',
            PreferedLanguage: 'Lingua preferita',
            Roles: 'Ruoli',
        },
        placeholder: {
            email: 'Inserisci la tua email',
            password: 'Inserisci la tua password',
            confirmPassword: 'Conferma la tua password',
            search: 'Cerca un cliente',
            object: "Nome dell'oggetto",
            selectObject: 'Selezionare un oggetto',
            Firstname: 'Inserisci il nome del cliente',
            Lastname: 'Inserisci il tuo nome',
            Email: 'Entrez sono e-mail',
            PhoneNumber: 'Inserisci il suo numero di telefono',
            Address: 'Inserisci il suo indirizzo',
            PersonalItemName: 'Inserisci il nome del nuovo oggetto',
            dropOffDate: 'Selezionare la data di deposito',
            pickUpDate: 'Selezionare la data di ritiro',
            price: '€ 1,000.00',
            fullName: "Inserisci il nome completo o l'email del cliente",
            agency: 'Scegli la tua agenzia',
            locker: 'Scegliere un armadietto',
            dropOff: 'Scegliere la data di deposito',
            registration: 'Inserire la registrazione del veicolo',
            brand: 'Inserisci la marca del veicolo',
            model: 'Inserire il modello del veicolo',
            rentalId: "Inserisci l'ID della prenotazione",
            agencyName: "Inserisci il nome dell'agenzia",
            fullAddress: 'Inserisci il suo indirizzo completo',
            Post: 'Entrez sono post',
            searchVehicle: 'Nome del veicolo',
            Roles: 'Seleziona uno o più ruoli',
        },
        forgetPassword: 'Hai dimenticato la tua password?',
        skipStep: 'Passare questa fase',
        messages: {
            required: 'Questo campo è obbligatorio.',
            pattern: {
                mismatch: 'Il campo non è valido.',
            },
        },
    },

    login: {
        title: 'Benvenuto',
        subtitle: 'Accedi al tuo account',
        submit: 'Entrare',
        noAccount: 'Non hai ancora un account?',
        registerLink: 'Iscriviti',
    },

    register: {
        title: 'Creare un account',
        submit: 'Iscriviti',
        loginText: 'Hai già un account?',
        loginLink: 'Entrare',
    },

    resetPassword: {
        title: 'Ripristina la password',
        subtitle:
            "Inserisci l'indirizzo email associato al tuo account e ti invieremo un link per resettare la tua password.",
        submit: 'Continua',
        loginLink: 'Torna alla pagina di accesso',
    },

    clientPage: {
        timeline: 'Cronologia',
        client: 'File del cliente',
        reservations: 'Prenotazioni',
        exchanges: 'Scambi',
        invoices: 'Fatture',
        object: 'Oggetto',
        addAction: "Aggiungere un'azione",
        delete: 'Eliminare il cliente',
        descriptionDelete: 'Stai per eliminare questo cliente, fai attenzione, questa azione è irreversibile',
        historyReservations: 'Storia della prenotazione',
    },

    reservationPage: {
        timeline: 'Cronologia',
        vehicle: 'Veicolo',
        clientLink: 'Vedere il file client',
        vehicleLink: 'Vedere la scheda del veicolo',
    },

    vehiclePage: {
        timeline: 'Cronologia',
        vehicle: 'Foglio del veicolo',
        reservation: 'Prenotazione',
        damages: 'Danno',
        delete: 'Cancellare il veicolo',
    },

    clientModal: {
        title: 'Aggiungere un nuovo cliente',
        description: 'Si prega di compilare le informazioni del cliente',
    },

    objectModal: {
        title: 'Allega un oggetto al client (opzionale)',
        description: 'Inserisci un oggetto a cui questo cliente sarà collegato',
        selectTitle: "Compilare l'oggetto riguardante lo scambio",
        selectDescription: "Selezionate o aggiungete l'oggetto interessato dalla vostra richiesta",
        selectPlaceholder: 'Chiave della macchina',
        addObject: 'Aggiungere un nuovo oggetto',
        backHistory: "Ritorno alla storia dell'oggetto",
    },

    vehiclesModal: {
        title: '(1/3) Assegnare un veicolo',
        description: 'Per favore, inserisci la registrazione, la marca o il modello del veicolo che stai cercando',
    },

    createVehicleModal: {
        title: 'Creare un veicolo',
    },

    selectClientModal: {
        title: '(2/3) Assegnare la prenotazione',
        description: "Inserisci il nome o l'indirizzo e-mail del cliente a cui sarà assegnata la prenotazione",
        newClient: 'Aggiungere un nuovo cliente',
        existingClient: 'Cliente esistente',
    },

    reservationsDateModal: {
        title: '(3/3) Scegliete le vostre date',
        description: 'Inserisci la data di partenza e/o la data di ritorno',
        pickUpDate: 'Partenza',
        pickUpPlaceholder: 'Scegliere la data di partenza',
        dropOffDate: 'Indietro',
        dropOffPlaceholder: 'Scegliere la data di ritorno',
    },

    dropOffModal: {
        title: 'Armadietto / Data del deposito',
        description: "Si prega di selezionare l'armadietto in cui l'oggetto sarà depositato e la data del deposito",
    },

    invoiceModal: {
        title: 'Importare la fattura',
        description: 'Si prega di importare la fattura in modo da poterla inviare al cliente',
        import: 'Clicca per importare',
        drag: 'o trascinare e rilasciare',
        restriction: 'PDF (max. 800x400px)',
    },

    agencyModal: {
        title: "Aggiungere un'agenzia",
        description: "Si prega di compilare i dettagli dell'agenzia",
        modifyInfo: "Modificare un'agenzia",
    },

    employeeModal: {
        title: 'Aggiungere un dipendente',
        description: 'Si prega di compilare i dati del dipendente',
        modifyInfo: 'Modificare un dipendente',
    },

    events: {
        userDropOffStep: 'In attesa di rimozione da parte di {{name}} il {{date}}',
        userDropOffFinishedStep: 'Inserito da {{name}}',
        garagePickUpStep: 'In attesa di recupero da parte del garage...',
        pickUpFinishedStep: 'Recuperato da {{name}}',
        garageDropOffStep: 'In attesa che il garage lo rimuova...',
        garageDropOffFinishedStep: 'Inserito da {{name}}',
        garageDropOffCanceledStep: 'Cancellato da {{name}}',
        userDropOffPlanedStep: 'Pianificato da {{name}}',
        pickUpCanceledStep: 'Cancellato da {{name}}',
        paidGarageReturnItemPlanedStep: 'Pianificato da {{name}}',
        freeGarageReturnItemPlanedStep: 'Pianificato da {{name}}',
        userPaymentStep: 'In attesa di pagamento...',
        userPaymentDoneStep: 'Pagamento effettuato!',
        userPickUpStep: 'In attesa di recupero da parte del cliente...',
        vehicle: {
            pickUpVehicleAppointmentMadeStep: 'Partenza prevista per la prenotazione n. {{id}} di {{name}}',
            appointmentVehicleAssociationStep: 'Aspettando che la chiave del veicolo sia depositata da un agente...',
            appointmentVehicleAssociatedStep: 'Chiave del veicolo rimossa',
            appointmentVehiclePickUpStep: 'Aspettando che {{name}} se ne vada...',
            appointmentVehiclePickedUpStep: 'Partenza effettuata',
            dropOffVehicleAppointmentMadeStep: 'Ritorno programmato per la prenotazione n. {{id}} di {{name}}',
            appointmentVehiclePickedUpStepCanceled: 'Partenza annullata della prenotazione n. {{id}} di {{name}}',
            appointmentVehicleDroppedOffStepCanceled: 'Ritorno annullato della prenotazione n. {{id}} di {{name}}',
            appointmentVehicleDropOffStep: 'Aspettando il ritorno di {{name}}...',
            appointmentVehicleDroppedOffStep: 'Restituito da {{name}}',
        },
    },

    newAction: {
        creation: {
            title: 'Elenco di oggetti',
        },
        list: {
            title: "Dicci di più sull'azione",
            description: "Selezionare l'oggetto pertinente",
            button: 'Aggiungere un nuovo oggetto',
        },
        type: {
            title: "Dicci di più sull'azione",
            description: "Si prega di selezionare l'azione desiderata dalle scelte qui sotto",
            dropOff: 'Prenda un appuntamento per la consegna',
            planFree: 'Restituire la chiave senza pagamento',
            planPaid: 'Restituire la chiave con il pagamento',
            reservation: 'Creare una prenotazione',
        },
        date: {
            title: 'Indicare la data e il luogo del deposito',
            inDoor: 'Interno',
            outDoor: "All'aperto",
        },
        invoice: {
            pickUp: 'Indicare la data e il luogo del recupero',
            button: 'Importare la fattura',
            description: 'Si prega di importare la fattura in modo da poterla inviare al cliente',
            price: 'Importo',
        },
    },

    404: {
        title: 'ERRORE 404',
        subtitle: 'Spiacente, questa pagina non è disponibile',
        text: 'Questa pagina non può essere trovata... Stai cercando una pagina?',
    },

    dropOffPage: {
        hero: {
            subHeading: 'È bello vederti!',
            description:
                'Il nostro servizio post-vendita ByMyCar è dotato di un armadietto digitale e sicuro per non dover più aspettare che un consulente sia disponibile per consegnare la chiave del suo veicolo.',
        },
        section1: {
            subHeading: 'Molto importante',
            title: 'Alcune istruzioni',
            text: {
                1: "1. Vai all'armadietto delle chiavi digitali vicino alla reception.",
                2: '2. Poi clicca sul pulsante qui sotto.',
                3: "3. Premi il pulsante dell'armadietto dei tasti quando inizia a lampeggiare.",
                4: "4. Metta la chiave del suo veicolo nell'armadietto rilasciato e lo chiuda.",
                5: "I nostri team saranno informati in tempo reale dell'arrivo del suo veicolo.",
            },
        },
        section2: {
            subHeading: 'Non dimenticare',
            title: 'Da presentare entro',
            text: {
                1: 'Orari disponibili',
                2: 'Dal lunedì al sabato: 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Sì, ma dove?',
            title: 'Luogo di deposito',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Telefono',
                schedules: 'Da lunedì a sabato: 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'Vi terremo informati via e-mail non appena riceveremo il vostro veicolo.',
                2: 'Buona giornata.',
                3: 'Il tuo partner ByMyCar',
            },
        },
    },

    rentalDropOffPage: {
        hero: {
            subHeading: 'È bello vederti!',
            description:
                'La nostra agenzia è dotata di un armadietto digitale e sicuro per non dover più aspettare che un consulente sia disponibile per restituire la chiave del suo veicolo a noleggio.',
        },
        section1: {
            subHeading: 'Molto importante',
            title: 'Alcune istruzioni',
            text: {
                1: "1. Incontrarsi nell'armadietto delle chiavi digitali vicino alla reception.",
                2: '2. Poi clicca sul pulsante qui sotto.',
                3: "3. Premere il pulsante dell'armadietto dei tasti quando inizia a lampeggiare.",
                4: "4. Mettete la chiave della vostra auto a noleggio nell'armadietto rilasciato e chiudetelo.",
                5: 'I nostri team saranno informati in tempo reale della restituzione del suo veicolo a noleggio.',
            },
        },
        section2: {
            subHeading: 'Non dimenticare',
            title: 'Da presentare entro',
            text: {
                1: 'Orari disponibili',
                2: 'Dal lunedì al sabato: 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Sì, ma dove?',
            title: 'Luogo di deposito',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Telefono',
                schedules: 'Da lunedì a sabato: 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'Vi terremo informati via e-mail non appena riceveremo il vostro veicolo.',
                2: 'Buona giornata.',
                3: 'Il tuo partner ByMyCar',
            },
        },
    },

    paymentFailed: {
        title: 'Pagamento fallito!',
        text: "Vi invitiamo a ripetere l'operazione utilizzando un altro metodo di pagamento <br /> <br /> Potete chiudere questa finestra.",
        button: 'Torna alla pagina iniziale',
    },

    paymentSuccess: {
        subHeading: 'Congratulazioni',
        title: 'Pagamento riuscito!',
        text: {
            1: 'In pochi istanti, riceverai via e-mail le istruzioni per il recupero del tuo veicolo. <br /> <br /> Potete chiudere questa finestra.',
            2: 'Grazie per la vostra fiducia!<br /> <br /> Il tuo partner ByMyCar',
        },
        button: 'Torna alla pagina iniziale',
    },

    paymentPage: {
        subHeading: 'In attesa di pagamento',
        text: ' Il tuo veicolo è pronto!<br /> <br /> Per risparmiare tempo al momento della riscossione, vi invitiamo a pagare la fattura direttamente tramite il nostro servizio online sicuro.',
        button: 'Procedere al pagamento',
    },

    pickUpPage: {
        hero: {
            subHeading: 'È bello vederti!',
            description:
                'Il nostro servizio post-vendita ByMyCar è dotato di un armadietto digitale e sicuro per non dover più aspettare che un consulente sia disponibile per consegnare la chiave del suo veicolo..',
        },
        section1: {
            subHeading: 'Molto importante',
            title: 'Alcune istruzioni',
            text: {
                1: "1. Vai all'armadietto delle chiavi digitali vicino alla reception.",
                2: '2. Poi clicca sul pulsante qui sotto.',
                3: " 3. Premi il pulsante dell'armadietto dei tasti quando inizia a lampeggiare.",
                4: "4. Metta la chiave del suo veicolo nell'armadietto rilasciato e lo chiuda.",
                5: '5. Ritira il tuo veicolo direttamente dal parcheggio.',
                6: "I nostri team saranno informati in tempo reale dell'arrivo del suo veicolo.",
            },
        },
        section2: {
            subHeading: 'Non dimenticare',
            title: 'Da presentare entro',
            text: {
                1: 'Orari disponibili',
                2: 'Dal lunedì al sabato: 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Sì, ma dove?',
            title: 'Luogo di deposito',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Telefono',
                schedules: 'Da lunedì a sabato: 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'Vi terremo informati via e-mail non appena il lavoro sul vostro veicolo sarà completato.',
                2: 'Buona giornata.',
                3: 'Il tuo partner ByMyCar',
            },
        },
    },

    rentalPickUpPage: {
        hero: {
            subHeading: 'È bello vederti!',
            description:
                "Per recuperare la chiave della sua auto a noleggio, vada all'armadietto delle chiavi digitali e clicchi su questo pulsante.",
        },
        section1: {
            subHeading: 'Molto importante',
            title: 'Alcune istruzioni',
            text: {
                1: "1. Incontrarsi nell'armadietto delle chiavi digitali vicino alla reception.",
                2: '2. Poi clicca sul pulsante qui sopra.',
                3: "3. Premere il pulsante dell'armadietto dei tasti quando inizia a lampeggiare.",
                4: "4. Recupera la chiave del tuo veicolo dall'armadietto rilasciato e chiudilo.",
                5: '5. Ritirare il veicolo direttamente dal parcheggio.',
                6: 'I nostri team saranno informati in tempo reale della vostra partenza.',
            },
        },
        section2: {
            subHeading: 'Non dimenticare',
            title: 'Data di inizio',
            text: {
                1: 'Orari disponibili',
                2: 'Da lunedì a domenica: 00:00 - 23:59',
            },
        },
        section3: {
            subHeading: 'Sì, ma dove?',
            title: 'Luogo di raccolta',
            company: 'Avis Location Voiture - Tolone',
            text: {
                1: 'Gare de Toulon',
                2: 'Gare Sncf Place De L Europe Retours Parking Effia -1, 83000 Toulon',
            },
            phone: {
                title: 'Telefono',
                schedules: 'Da lunedì a domenica: 00:00 - 23:59',
                phoneNumber: '0 820 61 16 45',
            },
        },
        footer: {
            text: {
                1: 'Buona giornata.',
                2: 'Avis Noleggio auto - Tolone',
            },
        },
    },

    parametersPage: {
        title: 'Parametri',
        subtitle: 'Gestisci tutte le tue impostazioni',
        addLocker: 'Aggiungere un armadietto',
        tabs: {
            company: 'Azienda',
            agency: 'Agenzie',
            locker: 'Locker',
            stripe: 'Stripe',
            interface: 'Interfaccia',
        },
        stripe: {
            title: 'Stripe',
            subtitle: 'Gestisci tutte le tue interazioni con il tuo account Stripe',
        },
        interface: {
            title: 'La mia interfaccia',
            subtitle: "Personalizza la tua interfaccia per adattarla all'immagine della tua azienda",
            logo: 'Logo',
            primaryColor: 'Colore principale',
        },
    },

    agencyPage: {
        addEmployee: 'Aggiungere un dipendente',
        delete: "Cancellare l'agenzia",
        addAgency: "Aggiungere un'agenzia",
    },

    employeePage: {
        delete: 'Cancellare il dipendente',
    },

    listVehicles: {
        title: 'Veicoli',
        subtitle: 'Gestisci tutti i tuoi veicoli',
        add: 'Aggiungere un veicolo',
        deleteVehicle: 'Cancellare il veicolo',
    },

    listExchanges: {
        title: 'Scambi',
        subtitle: 'Gestisci tutti i tuoi scambi',
        add: 'Aggiungere uno scambio',
    },

    listClients: {
        title: 'Clienti',
        subtitle: 'Gestisci tutti i tuoi clienti',
        add: 'Aggiungere un cliente',
    },

    listReservations: {
        title: 'Prenotazioni',
        subtitle: 'Gestisci tutte le tue prenotazioni',
        add: 'Aggiungere una prenotazione',
        clientLink: 'Vedere il file client',
        vehicleLink: 'Vedere la scheda del veicolo',
    },

    gridExchange: {
        searchPlaceholder: 'Cerca uno scambio',
        columns: {
            dropOffUser: 'Presentato da',
            dropOffDate: 'Data di presentazione',
            pickUpUser: 'Per',
            pickUpDate: 'Data di recupero',
            depositDate: 'Data di presentazione',
            status: 'Stato',
        },
        tabs: {
            waiting: 'In attesa di deposito',
            delivered: 'Archiviato',
            retrieved: 'Recuperato',
            canceled: 'Cancellato',
        },
        tags: {
            waiting: 'In attesa di deposito',
            delivered: 'Archiviato',
            retrieved: 'Recuperato',
            canceled: 'Cancellato',
        },
    },

    gridReservations: {
        searchPlaceholder: 'Cerca una prenotazione',
        columns: {
            reservation: 'Prenotazione',
            client: 'Cliente',
            registrationNumber: 'Immatricolazione',
            brandModel: 'Marca/Modello',
            startDate: 'Data di partenza',
            returnDate: 'Data di ritorno',
            status: 'Stato',
        },
        tabs: {
            waitingAssociation: 'In attesa di deposito',
            waitingPickup: 'In attesa di partire',
            waitingDropOff: 'In attesa del ritorno',
            closed: 'Completato',
            canceled: 'Cancellato',
        },
        tags: {
            waitingVehicleAssociation: 'In attesa del deposito delle chiavi',
            waitingVehiclePickup: 'In attesa di partire',
            waitingVehicleDropOff: 'In attesa del ritorno',
            closed: 'Completato',
            canceled: 'Cancellato',
        },
    },

    gridVehicles: {
        columns: {
            id: 'ID',
            registrationNumber: 'Immatricolazione',
            brandModel: 'Marca/Modello',
            status: 'Stato',
        },
        tabs: {
            available: 'Disponibile',
            booked: 'Riservato',
            unavailable: 'Non disponibile',
        },
        tags: {
            available: 'Disponibile',
            booked: 'Riservato',
            unavailable: 'Non disponibile',
        },
    },

    gridClients: {
        columns: {
            fullName: 'Nome completo',
            email: 'Email',
            phone: 'Numero di telefono',
            id: 'ID',
            registrationNumber: 'Immatricolazione',
            startDate: 'Data di partenza',
            returnDate: 'Data di ritorno',
            status: 'Stato',
        },
    },

    gridAgencies: {
        title: 'Agenzie',
        summary: 'Riepilogo delle agenzie',
        columns: {
            name: 'Nome',
            address: 'Indirizzo',
            phoneNumber: 'Telefono',
        },
    },

    gridEmployees: {
        title: 'Dipendenti',
        summary: 'Riassunto',
        columns: {
            name: 'Nome completo',
            job: 'Poste',
            email: 'Email',
            phoneNumber: 'Telefono',
        },
        tabs: {
            employee: "Dipendenti dell'agenzia",
        },
    },

    gridLocker: {
        title: 'Locker',
        columns: {
            name: 'Nome',
            agency: 'Agenzia',
            address: 'Indirizzo',
            openingHours: 'Tempi di accesso',
        },
    },

    timeline: {
        title: 'Cronologia',
        noActions: {
            vehicles: "Si prega di aggiungere un'azione per questa linea temporale",
            clients: "Aggiungi un'azione per questo cliente",
        },
    },

    expiredPage: {
        title: 'Il link a cui si sta cercando di accedere è scaduto',
    },
}
