import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

const StripeView: FC = () => {
    const { t } = useTranslation()
    return (
        <section>
            <Title level={4}>{t('parametersPage.stripe.title')}</Title>
            <Text type="secondary" className="mt-1">
                {t('parametersPage.stripe.subtitle')}
            </Text>
            <span className="separator" />
        </section>
    )
}
export default StripeView
