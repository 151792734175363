import { UserPickUp, UserPostType } from './user'
import { VehicleType } from './vehicle'

export const garageEventTypes = [
    'UserDropOffStep',
    'UserDropOffFinishedStep',
    'GaragePickUpStep',
    'PickUpFinishedStep',
    'GarageDropOffStep',
    'GarageDropOffFinishedStep',
    'UserDropOffPlanedStep',
    'GarageDropOffCanceledStep',
    'PickUpCanceledStep',
    'PaidGarageReturnItemPlanedStep',
    'FreeGarageReturnItemPlanedStep',
    'UserPaymentStep',
    'UserPaymentDoneStep',
    'UserPickUpStep',
]

export type UserTimeline = {
    DateOfOccurence?: string
    DropOffDate: string
    DropOffUser: UserPostType
    ItemId: string
    Name: string
    OperationId: string
    PickUpDate: string | null
    PickUpUser: UserPickUp
    Type: string
}

// Timeline Vehicle
export const vehicleEventTypes = [
    'PickUpVehicleAppointmentMadeStep',
    'AppointmentVehicleAssociationStep',
    'AppointmentVehicleAssociatedStep',
    'AppointmentVehiclePickUpStep',
    'AppointmentVehiclePickedUpStep',
    'DropOffVehicleAppointmentMadeStep',
    'AppointmentVehicleDroppedOffStepCanceled',
    'AppointmentVehiclePickedUpStepCanceled',
    'AppointmentVehicleDropOffStep',
    'AppointmentVehicleDroppedOffStep',
]

export type VehicleTimeline = {
    AppointmentId: string
    Name: string
    RentalId: string,
    RentalNumber: string,
    Type: string
    DateOfOccurence?: string
    AppointmentDate?: string
    User: UserPostType
    Vehicle: VehicleType
}
