import { FormInstance } from 'antd/lib/form'
import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import ObjectSchema from 'yup/lib/object'
import { State } from './state'

export type Steps = {
    comp: JSX.Element
    alternativeComp?: JSX.Element
    title: string
    description?: string
    icon?: JSX.Element
    size?: 'sm' | 'md'
}

type SubmitForm = (data: any) => void

export class ModalFormState {
    state: State
    currentStep: number
    renderAlternative = false
    steps: Steps[]
    schema: ObjectSchema<any>
    onSubmit: SubmitForm
    antdForm: FormInstance
    data: { [key: string]: any } = {}

    constructor(state: State, schema: ObjectSchema<any>, steps: Steps[], onSubmit: SubmitForm, antdForm: FormInstance) {
        makeAutoObservable(this)
        this.currentStep = 0
        this.steps = steps
        this.state = state
        this.schema = schema
        this.onSubmit = onSubmit
        this.antdForm = antdForm

        // this.generateData(this.getData, this.schema.fields)
    }

    get getData(): any {
        return this.data
    }

    get current(): Steps {
        return this.steps[this.currentStep]
    }

    get modalSize(): number | string {
        const size = this.steps[this.currentStep].size

        if (size === 'sm') {
            return 400
        }

        return '100%'
    }

    prevStep = () => this.close()

    nextStep = (data: any) => {
        this.fillData(data)
        this.renderAlternative = false

        if (this.currentStep !== this.steps.length - 1) {
            this.currentStep += 1
        } else {
            this.onSubmit(this.getData)
            this.close()
            this.reset()
        }
    }

    fillData = (data: any) => {
        const formData = this.data

        Object.keys(data).map((field: any) => {
            formData[field] = data[field]
        })

        this.data = formData
    }

    fillValues = (data: any) => {
        Object.keys(data).map((key: any) => {
            this.antdForm.setFieldsValue({
                [key]: data[key],
            })
        })
        this.fillData(data)
    }

    setDataValue = (key: string, data: any) => {
        const formData = this.getData
        formData[key] = data
        this.data = formData
    }

    getField = (key: string) => {
        const data = this.getData
        return data[key]
    }

    removeField = (key: string) => {
        const data = this.getData
        delete data[key]
    }

    close = () => (this.state.modalForm = null)
    reset = () => this.antdForm.resetFields()
}

export const ModalFormContext = createContext<ModalFormState | null>(null)

export const useModalForm = (): ModalFormState => {
    const val = useContext(ModalFormContext)

    if (val == null) throw new Error('forgot to add ctx provider ?')
    return val
}
