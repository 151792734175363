import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalForm } from 'state/modalForm'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'

const CreateVehicleModal: FC = () => {
    const { t } = useTranslation()
    const modalForm = useModalForm()

    return (
        <Fragment>
            <div className="form-body">
                <Form.Item label={t('form.label.registration')} name="RegistrationNumber" rules={[{ required: true }]}>
                    <Input id="vehicle-registration" type="text" placeholder={t(`form.placeholder.registration`)} />
                </Form.Item>
                <Form.Item label={t('form.label.brand')} name="Brand" rules={[{ required: true }]}>
                    <Input id="vehicle-brand" type="text" placeholder={t(`form.placeholder.brand`)} />
                </Form.Item>
                <Form.Item label={t('form.label.model')} name="Model" rules={[{ required: true }]}>
                    <Input id="vehicle-model" type="text" placeholder={t(`form.placeholder.model`)} />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
}
export default CreateVehicleModal
