import { makeAutoObservable } from 'mobx'
import { State } from './state'
import { EmployeeData, EmployeeType } from 'types/employee'
import { AgencyRoles } from 'types/agency'
import { GridData } from 'types/grid'

export class Employee {
    state: State
    all: GridData<EmployeeData> | null = null
    current: EmployeeData | null = null
    currentByIds: EmployeeData | null = null
    roles: AgencyRoles[] | null = null

    constructor(state: State) {
        makeAutoObservable(this)
        this.state = state
    }

    get rolesName(): string | undefined {
        return this.current?.AccountRoles.map((role) => role.RoleName).join(', ')
    }

    loadAll = async (agencyId: string, page?: number) => {
        const req = await this.state.http.loadEmployeesByAgency(agencyId, page)

        this.all = req.data
    }

    load = async (employeeId: string) => {
        const req = await this.state.http.loadEmployee(employeeId)
        this.current = req.data
        req.data.AccountRoles = this.convertRolesToIDs(req.data.AccountRoles as AgencyRoles[])
        this.currentByIds = req.data
    }

    loadRoles = async (ID: string) => {
        const req = await this.state.http.getRole(ID)
        this.roles = req.data
    }

    // Change roles to array with -> [roleId, ...] to prevent error on form
    convertRolesToIDs = (data: AgencyRoles[]) => {
        return data.map((role) => role.RoleId)
    }

    // Change array of [roleId, ...] -> [{AgencyRole}, ...]
    convertRolesToObj = (data: EmployeeType | EmployeeData) => {
        return data.AccountRoles.map((roleId, index) => {
            const matchRole = this.roles?.find((role) => role.RoleId === roleId)
            if (matchRole) data.AccountRoles[index] = matchRole
        })
    }
}
