import { FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useModalForm } from 'state/modalForm'
import { useSt } from 'state/state'
import { renderIcon } from 'helpers/icons'

import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import localeEn from 'antd/es/date-picker/locale/en_US'

const DropOffModal: FC = observer(() => {
    const st = useSt()
    const { t, i18n } = useTranslation()
    const modalForm = useModalForm()
    const locale = i18n.language === 'en' ? localeEn : localeFr

    useEffect(() => {
        !st.agency.roles && st.agency.loadAll()
    }, [])

    return (
        <Fragment>
            <div className="form-body">
                <Form.Item label={t('form.label.agency')} name="AgencyId" rules={[{ required: true }]}>
                    <Select
                        id="dropoff-agency"
                        placeholder={t('form.placeholder.agency')}
                        suffixIcon={renderIcon({ name: 'arrowDown' })}
                    >
                        {st.agency.all?.Data.map((agency) => (
                            <Select.Option key={agency.AgencyId.Value} value={agency.AgencyId.Value}>
                                {agency.Name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={t('form.label.locker')} name="DropOffLocker" rules={[{ required: true }]}>
                    <Select
                        id="dropoff-locker"
                        placeholder={t('form.placeholder.locker')}
                        suffixIcon={renderIcon({ name: 'arrowDown' })}
                    >
                        <Select.Option value="keycafe">KeyCafe</Select.Option>
                        <Select.Option value="sharebox">ShareBox</Select.Option>
                        <Select.Option value="acses">Acses</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={t('form.label.dropOff')} name="DropOffDate" rules={[{ required: true }]}>
                    <DatePicker
                        id="dropoff-date"
                        locale={{
                            ...locale,
                            lang: {
                                ...locale.lang,
                                now: t('common.now'),
                                ok: t('common.confirm'),
                            },
                        }}
                        showTime
                        placeholder={t('form.placeholder.dropOff')}
                        // onChange={(date, dateString) => modalForm.setDataValue('DropOffDate', dateString)}
                        suffixIcon={renderIcon({ name: 'calendar' })}
                    />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})

export default DropOffModal
