import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { agencyColumns } from 'static/agency'
import { AgencyData } from 'types/agency'
import CustomGrid from 'components/grid/CustomGrid'
import ListInfo from 'components/ListInfo'
import { Col, Row } from 'antd/lib/grid'

const AgencyView: FC = observer(() => {
    const st = useSt()
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        !st.agency.all && st.agency.loadAll()
    }, [])

    return (
        <Row justify="space-between" className="mb-8">
            <Col span={15}>
                <CustomGrid
                    name="agencies"
                    dataset={st.agency.all}
                    columns={agencyColumns(t)}
                    onRowClick={(agency: AgencyData) => history.push(`agency/${agency.AgencyId.Value}`)}
                    isSmall={{
                        title: t('gridAgencies.title'),
                    }}
                    onPageChange={(page) => st.agency.loadAll(page)}
                />
            </Col>
            <Col span={8}>
                <ListInfo
                    title={t('gridAgencies.summary')}
                    data={{
                        totalAgency: st.agency.all?.Data.length || 0,
                    }}
                />
            </Col>
        </Row>
    )
})
export default AgencyView
