import { FunctionComponent } from 'react'
import Spin from 'antd/lib/spin'
import { LoadingOutlined } from '@ant-design/icons'

type LoaderParams = {
    isStatic?: boolean
}

export const Loader: FunctionComponent<LoaderParams> = ({ isStatic }) => {
    return (
        <div className={isStatic ? 'loader' : 'loader absolute'}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: '3rem' }} spin />} />
        </div>
    )
}

export default Loader
