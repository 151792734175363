import { TFunction } from 'i18next'
import { EmployeeType } from 'types/employee'
import { ColumnsType } from 'antd/lib/table'

// export const fakeEmployees: EmployeeType[] = [
//     {
//         Id: 0,
//         Name: 'Thomas Sezen',
//         Job: 'Commercial',
//         Email: 'thomas@gmail.com',
//         PhoneNumber: '+33 7 67 89 09 76',
//     },
//     {
//         Id: 1,
//         Name: 'Kévin Özdemir',
//         Job: 'Commercial',
//         Email: 'kevin.ozdemir@gmail.com',
//     },
//     {
//         Id: 2,
//         Name: 'Victor Roche',
//         Job: 'Commercial',
//         Email: 'victor.roche@gmail.com',
//         PhoneNumber: '+33 7 67 89 09 76',
//     },
// ]

export const employeesColumns = (t: TFunction): ColumnsType<EmployeeType> => [
    {
        title: t('gridEmployees.columns.name'),
        dataIndex: 'Name',
        key: 'Name',
        render: (user, row) => (
            <b>
                {row.Firstname} {row.Lastname}
            </b>
        ),
    },
    {
        title: t('gridEmployees.columns.job'),
        dataIndex: 'Post',
        key: 'Post',
    },
    {
        title: t('gridEmployees.columns.email'),
        dataIndex: 'Email',
        key: 'Email',
    },
    {
        title: t('gridEmployees.columns.phoneNumber'),
        dataIndex: 'PhoneNumber',
        key: 'PhoneNumber',
        render: (phone) => (phone ? phone : t('common.notSpecified')),
    },
]
