import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { TRANSLATIONS_FR } from './fr/translations'
import { TRANSLATIONS_EN } from './en/translations'
import { TRANSLATIONS_IT } from './it/translations'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            fr: {
                translation: TRANSLATIONS_FR,
            },
            en: {
                translation: TRANSLATIONS_EN,
            },
            it: {
                translation: TRANSLATIONS_IT,
            },
        },
        fallbackLng: 'fr',
        // supportedLngs: ['fr', 'en'],
    })

export default i18n
// i18n.changeLanguage('fr')
// i18n.changeLanguage('en')
