import * as yup from 'yup'

export const DropOffSchema = yup.object().shape({
    Object: yup
        .object()
        .shape({
            Id: yup.string(),
        })
        .required(),
    AgencyId:yup.string(),
    DropOffUser: yup
        .object()
        .shape({
            Id: yup.string(),
            Name: yup.string(),
            EmailAddress: yup.string(),
            PreferedLanguage: yup.string().required(),
        })
        .required(),
    DropOffDate: yup.string().required(),
    DropOffLocker: yup.string().required(),
})

export type DropOffData = yup.InferType<typeof DropOffSchema>

export const PickUpSchema = yup.object().shape({
    Object: yup.object().shape({
        Id: yup.string(),
    }),
    PickUpUser: yup.object().shape({
        Id: yup.string(),
        Name: yup.string(),
        EmailAddress: yup.string(),
    }),
    PickUpDate: yup.string().required(),
})

export type PickUpData = yup.InferType<typeof PickUpSchema>
