import { State } from 'state/state'
import { User } from 'state/user'
import { ExchangeData } from 'types/exchange'
import { UserPostType, UserType } from 'types/user'
import { DropOffData, PickUpData } from 'types/event'
import {
    downloadInvoice,
    garageCancelDropoff,
    garageCancelPickup,
    garageExecDropOff,
    garageExecPickup,
    garageFreeReturn,
    garagePaidReturn,
    garagePlanDropoff,
    garageSendInvoice,
    garageStartDropOff,
    garageStartPickup,
    getAllGarages,
    sendDropoffEmail,
    sendPaymentEmail,
    sendPickupEmail,
} from './garage'
import {
    createCustomer,
    createCustomerItem,
    editCustomer,
    getAllCustomers,
    getCustomer,
    getUserTimeline,
    removeCustomer,
} from './user'
import { VehicleType } from 'types/vehicle'
import {
    createVehicle,
    deleteVehicle,
    getAllAvailableVehicles,
    getAllVehicles,
    getVehicle,
    getVehicleTimeline,
    setVehicleAccessible,
    setVehicleUnaccessible,
} from './vehicles'
import {
    createReservationDropOff,
    createReservationPickUp,
    getAllReservations,
    getReservation,
    getReservationTimeline,
    getClientReservations,
    cancelReservation,
    sendVehicleDropoffEmail,
    sendVehiclePickupEmail,
} from './reservation'
import { createAgency, deleteAgency, editAgency, getAgency, getAllAgencies, getAllRoles, getRole } from './agency'
import { ReservationDropOff, ReservationPickUp } from 'types/reservation'
import { AgencyType } from 'types/agency'
import { createEmployee, deleteEmployee, editEmployee, getEmployee, getEmployeesByAgency } from './employee'
import { EmployeeType } from 'types/employee'
import { GridData } from 'types/grid'

export class GlobalHttp {
    state: State
    constructor(state: State) {
        this.state = state
    }

    loadExchanges = async (page?: number, pageSize?: number, params?: { [key: string]: any }) => {
        const data: GridData<ExchangeData> = await getAllGarages(page, pageSize, params)

        this.state.exchanges = data
        return data
    }

    // CLIENTS
    loadClients = async (page?: number, pageSize?: number, params?: { [key: string]: any }) => {
        const clients: GridData<User> = await getAllCustomers(page, pageSize, params)

        this.state.clients = clients
        return clients
    }

    loadClient = async (clientId: string) => {
        const st = this.state
        const customer = await getCustomer(clientId).then(({ data }) => {
            st.currentUser = data

            if (st.currentUser.PersonalItems[0]) {
                const object = st.currentUser.PersonalItems[0].Name
                st.currentObject = object
            }

            return data
        })
        const steps = await getUserTimeline(clientId).then(({ data }) => (st.currentTimeline = data.Steps))

        return await Promise.all([customer, steps])
    }

    createClient = async (data: UserType) => await createCustomer(data)

    editClient = async (data: UserType) => {
        const reqEdit = await editCustomer(data)

        if (reqEdit.status === 200) {
            this.state.modal = null
            this.state.http.refreshUser()
        }

        return reqEdit
    }

    deleteClient = async (ID: string) => {
        const deletedReq = await removeCustomer(ID)

        if (deletedReq.status === 204) {
            this.state.modal = null
            this.loadClients()
        }

        return deletedReq
    }

    createObject = async (clientID: string, itemName: string) => {
        const objectCreated = await createCustomerItem({
            CustomerId: clientID,
            PersonalItemName: itemName,
        })

        return objectCreated
    }

    refreshUser = async () => {
        const st = this.state
        if (st.currentUser) {
            await getCustomer(st.currentUser.Id).then(({ data }) => {
                st.currentUser = data
            })
        }
    }

    refreshTimeline = async () => {
        const st = this.state
        if (st.currentUser) {
            await getUserTimeline(st.currentUser.Id).then(({ data }) => (st.currentTimeline = data.Steps))
        }
    }

    refreshVehicleTimeline = async (reservationId) => {
        const st = this.state
        await getReservationTimeline(reservationId).then(({ data }) => (st.currentVehicleTimeline = data.Steps))
    }

    // DROPOFF
    startDropoff = async (id: string) => await garageStartDropOff(id)

    cancelDropoff = async (id: string) => await garageCancelDropoff(id)

    executeDropoff = async (id: string) => await garageExecDropOff(id)

    sendDropoffEmail = async (id: string) => await sendDropoffEmail(id)

    submitDropoff = async (data: DropOffData) => {
        const planDropOff = await garagePlanDropoff({
            Object: data.Object,
            AgencyId : data.AgencyId,
            DropOffUser: data.DropOffUser,
            DropOffDate: data.DropOffDate,
            Locker: data.Locker,
        })

        if (planDropOff.status === 200) {
            this.refreshUser()
            this.refreshTimeline()
        }

        return planDropOff
    }

    // PICKUP
    startPickup = async (id: string) => await garageStartPickup(id)
    O
    cancelPickup = async (id: string) => await garageCancelPickup(id)

    executePickup = async (id: string) => await garageExecPickup(id)

    sendPickupEmail = async (id: string) => await sendPickupEmail(id)

    submitPickup = async (data: PickUpData, isFreePlan?: boolean) => {
        const reqPickup = isFreePlan ? await garageFreeReturn(data) : await garagePaidReturn(data)

        if (reqPickup.status === 200) {
            this.refreshUser()
            this.refreshTimeline()
        }

        return reqPickup
    }

    attachPDF = async (operationId: string, price: string, file: { name: string; blob: Blob }) => {
        const formData = new FormData()
        formData.append('OperationId', operationId)
        formData.append('Price', price)

        if (file.blob) {
            formData.append('File', file.blob, file.name)
        }

        return await garageSendInvoice(formData)
    }

    sendPaymentEmail = async (id: string) => await sendPaymentEmail(id)

    // Vehicles
    createVehicle = async (data: VehicleType) => {
        const reqCreate = await createVehicle(data)

        return reqCreate
    }

    deleteVehicle = async (vehicleId: string) => {
        const reqDelete = await deleteVehicle(vehicleId)

        return reqDelete
    }

    loadVehicle = async (vehicleId: string) => {
        const vehicle = await getVehicle(vehicleId).then(({ data }) => data)
        const timeline = await getVehicleTimeline(vehicleId).then(({ data }) => data.Steps)

        return await Promise.all([vehicle, timeline])
    }

    loadAllVehicles = async (page?: number, pageSize?: number, params?: { [key: string]: any }) => {
        const vehicles = await getAllVehicles(page, pageSize, params)

        this.state.vehicles = vehicles
        return vehicles
    }

    loadAllAvailableVehicles = async (onlyAccessibleVehicle: boolean) => {
        const vehicles = (await getAllAvailableVehicles(onlyAccessibleVehicle)).data

        return vehicles
    }

    makeVehicleAccessible = async (vehicleId: string) => await setVehicleAccessible(vehicleId)

    makeVehicleUnaccessible = async (vehicleId: string) => await setVehicleUnaccessible(vehicleId)

    // Reservations
    loadAllReservations = async (page?: number, pageSize?: number, params?: any) =>
        await getAllReservations(page || 1, pageSize || 10, params)

    loadReservation = async (reservationId: string) => {
        const st = this.state
        const reservation = await getReservation(reservationId).then(({ data }) => data)
        const steps = await getReservationTimeline(reservationId).then(
            ({ data }) => (st.currentVehicleTimeline = data.Steps),
        )

        return await Promise.all([reservation, steps])
    }

    createReservationDropoff = async (data: ReservationDropOff) => await createReservationDropOff(data)

    createReservationPickup = async (data: ReservationPickUp) => await createReservationPickUp(data)

    loadClientReservations = async (data: UserPostType, page?: number, pageSize?: number, params?: any) =>
        await getClientReservations(data, page, pageSize, params)

    cancelReservation = async (reservationId: string) => await cancelReservation(reservationId)

    sendVehicleDropoffEmail = async (id: string) => await sendVehicleDropoffEmail(id)

    sendVehiclePickupEmail = async (id: string) => await sendVehiclePickupEmail(id)

    // Agencies
    loadAllAgencies = async (page?: number) => await getAllAgencies(page)

    loadAgency = async (Id: string) => await getAgency(Id)

    createAgency = async (data: AgencyType) => await createAgency(data)

    editAgency = async (data: AgencyType) => await editAgency(data)

    deleteAgency = async (Id: string) => {
        const deletedReq = await deleteAgency(Id)

        if (deletedReq.status >= 200 && deletedReq.status <= 204) {
            this.state.modal = null
            this.state.agency.loadAll()
        }

        return deletedReq
    }

    getAllRoles = async () => await getAllRoles()

    getRole = async (agencyId: string) => await getRole(agencyId)

    // Employees
    loadEmployeesByAgency = async (Id: string, page?: number) => await getEmployeesByAgency(Id, page)

    loadEmployee = async (Id: string) => await getEmployee(Id)

    createEmployee = async (data: EmployeeType) => await createEmployee(data)

    editEmployee = async (data: EmployeeType) => await editEmployee(data)

    deleteEmployee = async (Id: string) => await deleteEmployee(Id)

    downloadInvoice = async (id: string) => await downloadInvoice(id)
}
