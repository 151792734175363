import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import { renderIcon } from 'helpers/icons'
import { useHistory } from 'react-router'

const Page404: FunctionComponent = () => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="container error-container">
            <Text className="mb-3">{t('404.title')}</Text>
            <Title level={1}>{t('404.subtitle')}</Title>
            <p className="mt-6 c-gray-500 error-text">{t('404.text')}</p>
            <div className="row mt-12">
                <Button
                    type="ghost"
                    icon={renderIcon({ name: 'arrowLeft' })}
                    size="large"
                    onClick={() => history.goBack()}
                >
                    {t('common.back')}
                </Button>
                <Button type="primary" size="large" className="ml-3" onClick={() => history.push('/')}>
                    {t('common.home')}
                </Button>
            </div>
        </div>
    )
}

export default Page404
