import { FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import HeaderUI from 'components/Header'
import PageContainer from 'components/PageContainer'
import ListInfo from 'components/ListInfo'
import Loader from 'components/Loader'
import CreateEmployeeModal from 'components/modals/CreateEmployeeModal'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import { renderDate } from 'helpers/time'
import { Icon, renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import { useSt } from 'state/state'

import Text from 'antd/lib/typography/Text'
import { Col, Row } from 'antd/lib/grid'
import Button from 'antd/lib/button'

const EmployeePage: FC = observer(() => {
    const st = useSt()
    const history = useHistory()
    const { t } = useTranslation()
    const params: { employeeId: string } = useParams()
    const employee = st.employees.currentByIds
    const deleteEmployee = async () => {
        st.addModal({
            Comp: (
                <ExecuteModal
                    onExec={() => st.http.deleteEmployee(params.employeeId)}
                    onFinish={(status) => {
                        displayNotif(t, status, {
                            key: t('feedback.employee.deleted'),
                            options: { name: `${employee?.Firstname} ${employee?.Lastname}` },
                        })

                        if (status >= 200 && status <= 204) {
                            history.push(`/agency/${employee?.Agency}`)
                        }
                    }}
                    isDangerBtn
                />
            ),
            title: {
                key: 'feedback.employee.confirmDelete',
                options: {
                    name: `${employee?.Firstname} ${employee?.Lastname}`,
                },
            },
            description: 'feedback.employee.descriptionDelete',
            icon: renderIcon({ name: 'trash', circleType: 'error' }),
        })
    }

    useEffect(() => {
        st.employees.load(params.employeeId)
    }, [])

    if (!employee) return <Loader />
    return (
        <Fragment>
            <HeaderUI />
            <PageContainer
                title={`${employee.Firstname} ${employee.Lastname}`}
                subtitle={<Text type="secondary">{employee.Post}</Text>}
                renderSingle={{
                    comp: (
                        <Row justify="space-between">
                            <Col span={15}>
                                <ListInfo
                                    data={{
                                        Lastname: employee.Lastname,
                                        Firstname: employee.Firstname,
                                        Email: employee.Email,
                                        PhoneNumber: employee.PhoneNumber,
                                    }}
                                    title={t('common.generalInformations')}
                                    onEdit={() =>
                                        st.addModal({
                                            Comp: <CreateEmployeeModal employeeData={employee} />,
                                            title: { key: 'employeeModal.modifyInfo' },
                                            icon: <Icon name="userPlus" circleType="primary" />,
                                        })
                                    }
                                />
                            </Col>
                            <Col span={8}>
                                <ListInfo
                                    data={{
                                        job: employee.Post,
                                        roles: st.employees.rolesName,
                                    }}
                                    title={t('common.details')}
                                    onEdit={() =>
                                        st.addModal({
                                            Comp: <CreateEmployeeModal employeeData={employee} />,
                                            title: { key: 'employeeModal.modifyInfo' },
                                            icon: <Icon name="userPlus" circleType="primary" />,
                                        })
                                    }
                                />
                                <div className="mb-8"></div>
                                <ListInfo
                                    data={{
                                        registrationDate: renderDate(employee.CreatedOn),
                                    }}
                                    title={t('common.registration')}
                                />
                            </Col>
                        </Row>
                    ),
                    button: (
                        <Button className="btn-error mr-3" onClick={deleteEmployee}>
                            {t('employeePage.delete')}
                        </Button>
                    ),
                }}
            />
        </Fragment>
    )
})
export default EmployeePage
