import { notSecuredHttpPost, securedHttpGet, securedHttpPost } from './http'
import { ReservationAction, ResponseGet, ResponsePost } from './types'
import { AppointmentData, ReservationData, ReservationDropOff, ReservationPickUp } from 'types/reservation'
import { VehicleTimeline } from 'types/timeline'
import { UserPostType } from 'types/user'
import { GridData } from 'types/grid'

export const getAllReservations = (
    page: number,
    limit: number,
    params?: { [key: string]: any },
): ResponseGet<GridData<ReservationData>> =>
    securedHttpGet(`/rentalagency-rental-get-all?page=${page}&limit=${limit}`, params || {})

export const getReservation = (RentalId: string): ResponsePost<ReservationData> =>
    securedHttpPost(`/rentalagency-rental-get`, { RentalId })

export const getReservationTimeline = (RentalId: string): ResponsePost<{ Steps: VehicleTimeline[] }> =>
    securedHttpPost(`/rentalagency-rental-timeline`, { RentalId })

export const createReservationDropOff = (data: ReservationDropOff): ResponsePost<unknown> =>
    securedHttpPost('/rentalagency-appointment-dropoff-create', data)

export const createReservationPickUp = (data: ReservationPickUp): ResponsePost<unknown> =>
    securedHttpPost('/rentalagency-appointment-pickup-create', data)

export const getAppointment = (
    AppointmentId: string,
    TenantId: string,
    AgencyId: string,
): ResponsePost<AppointmentData> =>
    notSecuredHttpPost('/rentalagency-appointment-get', { AppointmentId, TenantId, AgencyId })

export const reservationExecPickup = (data: ReservationAction): ResponsePost<unknown> =>
    notSecuredHttpPost('/rentalagency-vehicle-pickup', data)

export const reservationExecDropoff = (data: ReservationAction): ResponsePost<unknown> =>
    notSecuredHttpPost('/rentalagency-vehicle-dropoff', data)

export const getClientReservations = (User: UserPostType, page = 1, limit = 10, params = {}): ResponsePost<null> =>
    securedHttpPost(`/rentalagency-rental-get-all-for-user?page=${page}&limit=${limit}`, { User }, undefined, params)

export const cancelReservation = (RentalId: string): ResponsePost<null> =>
    securedHttpPost(`/rentalagency-rental-cancel`, { RentalId })

export const sendVehicleDropoffEmail = (AppointmentId: string): ResponsePost<null> =>
    securedHttpPost('/send-vehicle-dropOff-appointment-email', { AppointmentId })

export const sendVehiclePickupEmail = (AppointmentId: string): ResponsePost<null> =>
    securedHttpPost('/send-vehicle-pickup-appointment-email', { AppointmentId })

export const checkAppointmentExpiry = (
    TenantId: string,
    AgencyId: string,
    AppointmentId: string,
): ResponsePost<boolean> => notSecuredHttpPost('/check-appointment-expiry', { TenantId, AgencyId, AppointmentId })
