import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getAgencyPortalAvailability, openAgencyPortal } from 'api/agency'
import { Icon, renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import { convertUTCDateToLocalDate, renderFullDate, renderTime } from 'helpers/time'
import { checkAppointmentExpiry, getAppointment, reservationExecDropoff } from 'api/reservation'
import { AppointmentData } from 'types/reservation'
import { UserStaticPages } from 'types/user'

import Loader from 'components/Loader'
import HeaderUI from 'components/Header'
import ExpiredPage from 'components/ExpiredPage'

import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Modal from 'antd/lib/modal'
import i18n from 'i18n/config'
import jwt_decode from 'jwt-decode'

import { ExclamationCircleFilled } from '@ant-design/icons'
import Countdown from 'react-countdown'

const OPEN_PORTAL_COUNTDOWN = 60

const { confirm } = Modal

const RentalDropoffPage: FunctionComponent = () => {
    const { t } = useTranslation()
    const { lang, token } = useParams<UserStaticPages>()
    let formattedToken = token
    const [appointmentExpiry, setAppointmentExpiry] = useState(false)
    const [hasPortal, setPortal] = useState(false)
    const [portalReq, setPortalReq] = useState({
        loading: false,
        success: false,
    })
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [appointment, setAppointment] = useState<AppointmentData | null>(null)
    formattedToken = token.replace(/€/g, '.')

    const decodedJson = jwt_decode(formattedToken) as any
    const guid = decodedJson.AppointmentId
    const tenantId = decodedJson.TenantId
    const agencyId = decodedJson.AgencyId
    let expiredOn = decodedJson.exp as Date
    console.log('>>> expiredOn', expiredOn)
    console.log('>>> date now', Date.now() / 1000)
    expiredOn = convertUTCDateToLocalDate(expiredOn)
    console.log('>>> expiredOn converted', expiredOn.getTime())
    const expirationLimit = expiredOn.getTime() < Date.now() / 1000
    console.log('>>> expirationLimit', expirationLimit)

    const [openPortalDisabled, setOpenPortalDisabled] = useState(false)
    const [countdown, setCountdown] = useState(() => {
        const lastResend = localStorage.getItem(`rentalDropOff_${guid}`)
        if (lastResend) {
            const diff = Math.floor((new Date().getTime() - parseInt(lastResend)) / 1000)
            if (diff < OPEN_PORTAL_COUNTDOWN) {
                setOpenPortalDisabled(true)
                return OPEN_PORTAL_COUNTDOWN - diff
            }
        }
        return 0
    })

    useEffect(() => {
        const getAppointmentExpiry = async () => {
            const reqAppointmentExpiry = await checkAppointmentExpiry(tenantId, agencyId, guid)
            setAppointmentExpiry(reqAppointmentExpiry.data)

            // If appointment not expired, fetch others data
            if (!reqAppointmentExpiry.data && !expirationLimit) {
                getPortalStatus()
                fetchAppointment()
            }
        }
        const getPortalStatus = async () => {
            const portal = await getAgencyPortalAvailability(tenantId, agencyId)
            setPortal(portal.data)
        }
        const fetchAppointment = async () => {
            const appointment = await getAppointment(guid, tenantId, agencyId)
            const data = appointment.data
            setAppointment(data)
        }

        getAppointmentExpiry()
        i18n.changeLanguage(lang)
    }, [guid])

    const executeDropOff = async () => {
        if (appointment) {
            setLoading(true)
            const dropOff = await reservationExecDropoff({
                AppointmentId: guid,
                TenantId: tenantId,
                AgencyId: agencyId,
                User: {
                    Id: appointment.User.Id,
                    Name: appointment.User.Name,
                    EmailAddress: appointment.User.EmailAddress,
                    PreferedLanguage: appointment.User.PreferedLanguage,
                },
            })

            setLoading(false)
            dropOff.status === 200 && setSuccess(true)
            displayNotif(t, dropOff.status, { key: 'feedback.lockerOpened' })
        }
    }
    const openPortal = async () => {
        confirm({
            title: t('common.openPortal'),
            icon: <ExclamationCircleFilled />,
            content: t('agencyPortal.openConfirm'),
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: async () => {
                localStorage.setItem(`rentalDropOff_${guid}`, new Date().getTime().toString())
                setCountdown(OPEN_PORTAL_COUNTDOWN)
                setOpenPortalDisabled(true)

                setPortalReq((prevState) => ({
                    ...prevState,
                    loading: true,
                }))

                const openPortalReq = await openAgencyPortal(tenantId, agencyId, guid)

                setPortalReq({
                    success: openPortalReq.status === 200,
                    loading: false,
                })
            },
        })
    }

    if (expirationLimit || appointmentExpiry) return <ExpiredPage />
    if (!appointment && !appointmentExpiry) return <Loader />
    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <section className="static-hero container bg-gray-50">
                {/* <Text className="c-primary fw-600 mb-3">{t('dropOffPage.hero.subHeading')}</Text> */}
                <Title className="fw-600">
                    {t('common.hello')} {appointment?.User.Name}
                </Title>
                <Text type="secondary" className="mt-4">
                    {t('rentalDropOffPage.hero.description')}
                </Text>
                {/* If has portal and portal is opened */}
                {hasPortal && (
                    <div>
                        <Text type="secondary" className="mt-8 mb-4">
                            {t('rentalDropOffPage.hero.openPortal')}
                        </Text>
                        <Button
                            type="primary"
                            size="large"
                            onClick={openPortal}
                            className={`${openPortalDisabled ? 'btn-success' : ''}`}
                            loading={portalReq.loading}
                            disabled={openPortalDisabled}
                        >
                            {openPortalDisabled ? t('common.portalIsOpened') : t('common.openPortal')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span style={{ marginLeft: '4px' }}>({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setOpenPortalDisabled(false)
                                        localStorage.removeItem(`rentalDropOff_${guid}`)
                                    }}
                                />
                            )}
                            {renderIcon({ name: openPortalDisabled ? 'checkCircle' : 'unlock', position: 'right' })}
                        </Button>
                    </div>
                )}

                <Button
                    type="primary"
                    onClick={executeDropOff}
                    className={`mt-6 ${success ? 'btn-success' : ''}`}
                    loading={loading}
                    disabled={success}
                    size="large"
                >
                    {success ? t('common.lockerIsOpened') : t('common.openLocker')}
                    {renderIcon({ name: success ? 'checkCircle' : 'unlock', position: 'right' })}
                </Button>
            </section>
            <section className="static-section container">
                <Icon name="alert" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('rentalDropOffPage.section1.subHeading')}</Text>
                <Title level={2} className="fw-600">
                    {t('rentalDropOffPage.section1.title')}
                </Title>
                <Text type="secondary" className="mt-6 mb-4">
                    {t('rentalDropOffPage.section1.text.1')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('rentalDropOffPage.section1.text.2')}
                </Text>

                <Text type="secondary" className="mb-4">
                    {t('rentalDropOffPage.section1.text.3')}
                </Text>
                <Text type="secondary" className="mb-6">
                    {t('rentalDropOffPage.section1.text.4')}
                </Text>
                <Text type="secondary">{t('rentalDropOffPage.section1.text.5')}</Text>
                <Divider />
            </section>
            <section className="static-section container">
                <Icon name="calendar" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('rentalDropOffPage.section2.subHeading')}</Text>
                <Title level={2} className="fw-600">
                    {t('rentalDropOffPage.section2.title')}
                </Title>
                {appointment?.Date && (
                    <Text type="secondary" className="my-10">
                        {renderFullDate(appointment.Date)} &agrave; {renderTime(appointment.Date)}
                    </Text>
                )}
                <div>
                    <Text strong className="mb-2">
                        {t('rentalDropOffPage.section2.text.1')}
                    </Text>
                    <Text type="secondary">{t('rentalDropOffPage.section2.text.2')}</Text>
                </div>
                <Divider />
            </section>
            <section className="static-section container">
                <Icon name="pin" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('rentalDropOffPage.section3.subHeading')}</Text>
                <Title level={2} className="fw-600">
                    {t('rentalDropOffPage.section3.title')}
                </Title>
                <div className="my-10">
                    <Text type="secondary" className="mb-4">
                        ByMyCar
                    </Text>
                    <Text type="secondary">{t('rentalDropOffPage.section3.text.1')}</Text>
                </div>
            </section>
            <section className="container">
                <div className="static-phone">
                    <Button
                        type="primary"
                        icon={renderIcon({ name: 'phone' })}
                        onClick={() => window.open(`tel:${t('rentalDropOffPage.section3.phone.phoneNumber')}`)}
                    />
                    <Text>{t('rentalDropOffPage.section3.phone.title')}</Text>
                    <p className="mt-2">{t('rentalDropOffPage.section3.phone.schedules')}</p>
                    <a href={`tel:${t('rentalDropOffPage.section3.phone.phoneNumber')}`}>
                        {t('rentalDropOffPage.section3.phone.phoneNumber')}
                    </a>
                </div>
                <Divider />
            </section>
            <section className="static-section container">
                <Text type="secondary" className="mb-4">
                    {t('rentalDropOffPage.footer.text.1')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('rentalDropOffPage.footer.text.2')}
                </Text>
                <Text type="secondary">{t('rentalDropOffPage.footer.text.3')}</Text>
            </section>
        </Fragment>
    )
}

export default RentalDropoffPage
