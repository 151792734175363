import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import { renderStatusExchange } from 'helpers/renderStatus'
import { renderFormatDate } from 'helpers/time'
import { ExchangeData, ExchangeUser } from 'types/exchange'
import { ColumnsType } from 'antd/lib/table'
import { GridTab } from 'types/grid'

export const gridColumns = (t: TFunction): ColumnsType<ExchangeData> => [
    {
        title: t('gridExchange.columns.dropOffUser'),
        dataIndex: 'DropOffUser',
        key: 'DropOffUser',
        sorter: (a: ExchangeData, b: ExchangeData): number => {
            if (a.DropOffUser.Name && b.DropOffUser.Name) return a.DropOffUser.Name.length - b.DropOffUser.Name.length

            return 0
        },
        render: (user: ExchangeUser) => {
            return (
                <div>
                    <b>{user.Name}</b>
                    <p>{user.EmailAddress}</p>
                </div>
            )
        },
    },
    {
        title: t('gridExchange.columns.pickUpUser'),
        dataIndex: 'PickUpUser',
        key: 'PickUpUser',
        responsive: ['md'],
        render: (user: ExchangeUser) => (
            <div>
                <b>{user.Name}</b>
                <p>{user.EmailAddress}</p>
            </div>
        ),
    },
    {
        title: t('gridExchange.columns.dropOffDate'),
        dataIndex: 'DropOffDate',
        key: 'DropOffDate',
        // responsive: ['md'],
        defaultSortOrder: 'descend',
        sorter: (a: ExchangeData, b: ExchangeData): number => {
            const dateA = dayjs(a.DropOffDate).valueOf()
            const dateB = dayjs(b.DropOffDate).valueOf()
            return dateA - dateB
        },
        render: (dropOffDate: string) => renderFormatDate(t, dropOffDate),
    },
    {
        title: t('gridExchange.columns.pickUpDate'),
        dataIndex: 'PickUpDate',
        key: 'PickUpDate',
        responsive: ['md'],
        sorter: (a: ExchangeData, b: ExchangeData): number => {
            const dateA = dayjs(a.PickUpDate).valueOf()
            const dateB = dayjs(b.PickUpDate).valueOf()
            return dateA - dateB
        },
        render: (pickUpDate: string) => (pickUpDate ? renderFormatDate(t, pickUpDate) : t('common.unavailable')),
    },
    {
        title: t('gridExchange.columns.status'),
        dataIndex: 'Status',
        key: 'Status',
        responsive: ['md'],
        render: (status: number) => renderStatusExchange(t, status),
    },
]

export const tabsExchanges = (t: TFunction): GridTab[] => [
    {
        name: t('common.all'),
        key: '',
    },
    {
        name: t('gridExchange.tabs.waiting'),
        key: '0',
    },
    {
        name: t('gridExchange.tabs.delivered'),
        key: '1',
    },
    {
        name: t('gridExchange.tabs.retrieved'),
        key: '2',
    },
    {
        name: t('gridExchange.tabs.canceled'),
        key: '3',
    },
]
