import dayjs from 'dayjs'
import { Fragment } from 'react'
import type { TFunction } from 'react-i18next'
import type { ColumnsType } from 'antd/lib/table'
import { ReservationData } from 'types/reservation'
import { renderFormatDate } from 'helpers/time'
import { renderStatusReservations } from 'helpers/renderStatus'
import { GridTab } from 'types/grid'

export const columnsReservations = (t: TFunction): ColumnsType<ReservationData> => [
    {
        title: t('gridReservations.columns.reservation'),
        dataIndex: 'Id',
        key: 'Id',
        sorter: (a: ReservationData, b: ReservationData): number => {
            const idA = dayjs(a.Id).valueOf()
            const idB = dayjs(b.Id).valueOf()
            return idA - idB
        },
        render: (text, row) => <b>{row.Number}</b>,
    },
    {
        title: t('gridReservations.columns.client'),
        dataIndex: 'Client',
        key: 'Client',
        render: (text, row) => (
            <Fragment>
                <p className="c-gray-900">{row.User.Name}</p>
                <p>{row.User.EmailAddress}</p>
            </Fragment>
        ),
    },
    {
        title: t('gridReservations.columns.registrationNumber'),
        dataIndex: 'RegistrationNumber',
        key: 'RegistrationNumber',
        responsive: ['md'],
        render: (text, row) => <p className="c-gray-900">{row.Vehicle.RegistrationNumber}</p>,
    },
    {
        title: t('gridReservations.columns.brandModel'),
        dataIndex: 'Brand/Model',
        key: 'Brand/Model',
        responsive: ['md'],
        render: (text, row) => {
            return (
                <Fragment>
                    <p className="c-gray-900">{row.Vehicle.Brand}</p>
                    <p>{row.Vehicle.Model}</p>
                </Fragment>
            )
        },
    },
    {
        title: t('gridReservations.columns.startDate'),
        dataIndex: 'StartDate',
        key: 'StartDate',
        defaultSortOrder: 'descend',
        sorter: (a: ReservationData, b: ReservationData): number => {
            const dateA = dayjs(a.StartDate).valueOf()
            const dateB = dayjs(b.StartDate).valueOf()
            return dateA - dateB
        },
        render: (text, row) => (row.StartDate ? renderFormatDate(t, row.StartDate) : t('common.noDeparture')),
    },
    {
        title: t('gridReservations.columns.returnDate'),
        dataIndex: 'ReturnDate',
        key: 'ReturnDate',
        responsive: ['md'],
        sorter: (a: ReservationData, b: ReservationData): number => {
            const dateA = dayjs(a.EndDate).valueOf()
            const dateB = dayjs(b.EndDate).valueOf()
            return dateA - dateB
        },
        render: (text, row) => (row.EndDate ? renderFormatDate(t, row.EndDate) : t('common.noReturn')),
    },
    {
        title: t('gridReservations.columns.status'),
        dataIndex: 'Status',
        key: 'Status',
        responsive: ['md'],
        render: (status: string) => renderStatusReservations(t, status),
    },
]

export const tabsReservations = (t: TFunction): GridTab[] => [
    {
        name: t('common.all'),
        key: '',
    },
    {
        name: t('gridReservations.tabs.waitingAssociation'),
        key: 'WaitingVehicleAssociation',
    },
    {
        name: t('gridReservations.tabs.waitingPickup'),
        key: 'WaitingVehiclePickup',
    },
    {
        name: t('gridReservations.tabs.waitingDropOff'),
        key: 'WaitingVehicleDropOff',
    },
    {
        name: t('gridReservations.tabs.closed'),
        key: 'Closed',
    },
    {
        name: t('gridReservations.tabs.canceled'),
        key: 'Canceled',
    },
]
