import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { User } from 'state/user'
import { useSt } from 'state/state'
import { gridClientsColumns } from 'static/client'
import { Icon } from 'helpers/icons'
import { displayNotif, displayError } from 'helpers/displayNotif'
import { UserSchema, UserType } from 'types/user'
import ClientModal from 'components/modals/ClientModal'
import HeaderUI from 'components/Header'
import CustomGrid from 'components/grid/CustomGrid'
import ObjectModal from 'components/modals/ObjectModal'

import { Col, Row } from 'antd/lib/grid'
import Title from 'antd/lib/typography/Title'
import Typography from 'antd/lib/typography'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import { delay } from 'helpers/delay'

const ListClients: FunctionComponent = observer(() => {
    const st = useSt()
    const history = useHistory()
    const { t } = useTranslation()
    const submitClient = async (data: UserType) => {
        const createdClient = await st.http.createClient(data)

        if (createdClient.request.status == 409) {
            displayError(t, 'feedback.clientExist')
        } else {
            displayNotif(t, createdClient.status, { key: 'feedback.client.created' })
        }

        // Create object if field empty
        if (createdClient.status === 200 && data.PersonalItemName) {
            const createdObject = await st.http.createObject(createdClient.data.Id, data.PersonalItemName)
            displayNotif(t, createdObject.status, { key: 'feedback.objectCreated' })
        }

        if (createdClient.status === 200) {
            st.http.loadClients()
        }
    }
    const [filterParams, setFilterParams] = useState({})

    useEffect(() => {
        !st.clients && st.http.loadClients()
    }, [])

    return (
        <Fragment>
            <HeaderUI />
            <section className="container">
                <Row justify="space-between">
                    <Col>
                        <Title level={2}>{t('listClients.title')}</Title>
                        <Typography.Text type="secondary" className="mt-1 fz-16">
                            {t('listClients.subtitle')}
                        </Typography.Text>
                    </Col>
                    <Col className="my-sm-2">
                        <Button
                            type="primary"
                            icon={<Icon name="add" />}
                            onClick={() => {
                                st.addModalForm({
                                    schema: UserSchema,
                                    steps: [
                                        {
                                            comp: <ClientModal />,
                                            title: 'clientModal.title',
                                            description: 'clientModal.description',
                                            icon: <Icon name="userPlus" circleType="primary" />,
                                        },
                                        {
                                            comp: <ObjectModal skipStep />,
                                            title: 'objectModal.title',
                                            description: 'objectModal.description',
                                            icon: <Icon name="userPlus" circleType="primary" />,
                                        },
                                    ],
                                    submit: (data: UserType) => submitClient(data),
                                })
                            }}
                        >
                            {t('listClients.add')}
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <CustomGrid
                    name="clients"
                    dataset={st.clients}
                    columns={gridClientsColumns(t)}
                    onRowClick={(client: User) => history.push(`/client/${client.Id}`)}
                    onPageChange={(page, pageSize) => st.http.loadClients(page, pageSize, filterParams)}
                    onFilter={delay((params) => {
                        const { page, pageSize, ...filters } = params
                        setFilterParams(filters)
                        st.http.loadClients(page, pageSize, filters)
                    }, 1000)}
                />
            </section>
        </Fragment>
    )
})

export default ListClients
