import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Select from 'antd/lib/select'
import { Icon, renderIcon } from 'helpers/icons'
import { useSt } from 'state/state'

const SwitchLang: FC = () => {
    const st = useSt()
    const { i18n } = useTranslation()

    return (
        <Select
            defaultValue={i18n.language}
            suffixIcon={renderIcon({ name: 'arrowDown' })}
            className="header-lang"
            dropdownClassName="header-lang_dropdown"
            onSelect={(key) => {
                st.lang = key
                i18n.changeLanguage(key)
                dayjs.locale(key)
            }}
        >
            <Select.Option value={i18n.language === 'fr-FR' ? 'fr-FR' : 'fr'}>
                <Icon name="fr" />
                <p className="ml-2">FR</p>
            </Select.Option>
            <Select.Option value={i18n.language === 'en-EN' ? 'en-EN' : 'en'}>
                <Icon name="us" />
                <p className="ml-2">US</p>
            </Select.Option>
            <Select.Option value={i18n.language === 'it-IT' ? 'it-IT' : 'it'}>
                <Icon name="it" />
                <p className="ml-2">IT</p>
            </Select.Option>
        </Select>
    )
}
export default SwitchLang
