import { securedHttpGet, securedHttpPost } from './http'
import { ResponseGet, ResponsePost, TimelineType } from './types'
import { VehicleType } from 'types/vehicle'
import { VehicleTimeline } from 'types/timeline'
import { GridData } from 'types/grid'

export const createVehicle = (data: VehicleType): ResponsePost<null> =>
    securedHttpPost('/rentalagency-vehicle-add', data)

export const deleteVehicle = (VehicleId: string): ResponsePost<null> =>
    securedHttpPost('/rentalagency-vehicle-remove', { VehicleId })

export const getAllVehicles = (
    page = 1,
    limit = 10,
    params?: { [key: string]: any },
): ResponseGet<GridData<VehicleType>> =>
    securedHttpGet(`/rentalagency-vehicle-get-all?page=${page}&limit=${limit}`, params || {})

export const getAllAvailableVehicles = (OnlyAccessibleVehicle: boolean): ResponsePost<VehicleType[]> =>
    securedHttpPost('/rentalagency-vehicle-all-not-booked', { OnlyAccessibleVehicle })

export const getVehicle = (VehicleId: string): ResponsePost<null> =>
    securedHttpPost('/rentalagency-vehicle-get', { VehicleId })

export const getVehicleTimeline = (VehicleId: string): ResponsePost<TimelineType<VehicleTimeline>> =>
    securedHttpPost('/rentalagency-vehicle-timeline', { VehicleId })

export const setVehicleAccessible = (VehicleId: string): ResponsePost<null> =>
    securedHttpPost('/rentalagency-vehicle-makeaccessible', { VehicleId })

export const setVehicleUnaccessible = (VehicleId: string): ResponsePost<null> =>
    securedHttpPost('/rentalagency-vehicle-makeunaccessible', { VehicleId })
