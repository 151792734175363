import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Icon } from 'helpers/icons'
import { getUserGarage } from 'api/user'
import { userPaymentInitiate } from 'api/user'
import { ExchangeData } from 'types/exchange'
import { UserStaticPages } from 'types/user'
import { displayNotif } from 'helpers/displayNotif'

import Loader from 'components/Loader'
import HeaderUI from 'components/Header'

import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import jwt_decode from "jwt-decode";

const UserPaymentPage: FunctionComponent = () => {
    const { t, i18n } = useTranslation()
    const { lang, token} = useParams<UserStaticPages>()
    let formattedToken = token;
    const [execLoading, setExecLoading] = useState(false)
    const [exchange, setExchange] = useState<ExchangeData | null>(null)
    formattedToken = token.replace(/€/g, '.');
    const decoded = jwt_decode(formattedToken) as any[];
    const guid=Object.values(decoded)[0]as string;
    const tenantId= Object.values(decoded)[1] as string;
    const agencyId= Object.values(decoded)[2] as string;
    useEffect(() => {
        const fetchGarage = async () => {
            const garage = await getUserGarage(guid, tenantId,agencyId)
            const data = garage.data
            setExchange(data)
        }
        fetchGarage()
        i18n.changeLanguage(lang)
    }, [guid])

    if (!exchange) return <Loader />

    const executePayment = async () => {
        setExecLoading(true)
        const payment = await userPaymentInitiate({
            OperationId: guid,
            TenantId: exchange.TenantId,
            AgencyId:exchange.AgencyId,
            PickUpUser: {
                Id: exchange.PickUpUser.Id,
                Name: exchange.PickUpUser.Name,
                EmailAddress: exchange.PickUpUser.EmailAddress,
                PreferedLanguage: exchange.PickUpUser.PreferedLanguage,
            },
        })

        displayNotif(t, payment.status, { key: 'feedback.redirectPayment' })
        if (payment.status === 200) window.location.href = payment.data.url
        setExecLoading(false)
    }

    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <section className="static-section container centered">
                <Icon name="creditCard" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('paymentPage.subHeading')}</Text>
                <Title className="fw-600">
                    {t('common.hello')} {exchange.PickUpUser.Name}
                </Title>
                <Text type="secondary" className="my-10">
                    <Trans i18nKey="paymentPage.text" />
                </Text>
                <Button
                    type="primary"
                    size="large"
                    onClick={executePayment}
                    className="mt-4 mb-4"
                    loading={execLoading}
                >
                    {t('paymentPage.button')}
                </Button>
            </section>
        </Fragment>
    )
}

export default UserPaymentPage
