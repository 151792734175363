import { Fragment, FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Icon, renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import { useSt } from 'state/state'
import { UserSchema, UserType } from 'types/user'
import { garageEventTypes } from 'types/timeline'
import HeaderUI from 'components/Header'
import Timeline from 'components/timeline/Timeline'
import ClientModal from 'components/modals/ClientModal'
import Loader from 'components/Loader'
import ListInfo from 'components/ListInfo'
import ClientDropdown from 'components/ClientDropdown'
import PageContainer from 'components/PageContainer'
import Dropdown from 'components/Dropdown'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import ClientReservations from 'components/ClientReservations'

import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Text from 'antd/lib/typography/Text'

const ClientPage: FunctionComponent = observer(() => {
    const { t } = useTranslation()
    const st = useSt()
    const history = useHistory()
    const params: { clientId: string } = useParams()
    const user = st.currentUser

    useEffect(() => {
        st.http.loadClient(params.clientId)

        return () => {
            st.currentUser = null
            st.searchValue = ''
            st.currentObject = ''
        }
    }, [params.clientId, st])

    if (!st.currentTimeline || !user) return <Loader />

    const editClient = () => {
        st.addModalForm({
            schema: UserSchema,
            steps: [
                {
                    comp: <ClientModal editInfo={user || undefined} />,
                    title: 'common.modifyInfo',
                    icon: <Icon name="userPlus" circleType="primary" />,
                },
            ],
            submit: async (data: UserType) => {
                const client = await st.http.editClient(data)
                st.http.loadClients()
                displayNotif(t, client.status, {
                    key: 'feedback.client.modified',
                })
            },
        })
    }
    const listObjects = () => {
        const list: { text: string; onClick: () => void }[] = []

        user.PersonalItems.map((item) => {
            list.push({
                text: item.Name,
                onClick: () => (st.currentObject = item.Name),
            })
        })

        return list
    }

    const tabs = [
        {
            key: 'timeline',
            name: t('clientPage.timeline'),
            render: <Timeline timeline={st.currentTimeline} listEvents={garageEventTypes} />,
            button: (
                <div className="row">
                    {st.claims.includes('exchanges-manager') && (
                        <div className="mr-3">
                            <Dropdown
                                button={{
                                    type: 'ghost',
                                    text: listObjects().length === 0 ? t('common.noObjects') : st.currentObject,
                                }}
                                items={listObjects()}
                            />
                        </div>
                    )}
                    <ClientDropdown user={user} />
                </div>
            ),
        },
        {
            key: 'client',
            name: (
                <p className="row">
                    {renderIcon({ name: 'user', color: '#667085' })} {t('clientPage.client')}
                </p>
            ),
            render: (
                <Row justify="space-between">
                    <Col span={15}>
                        <ListInfo
                            data={{
                                Firstname: user.Firstname,
                                Lastname: user.Lastname,
                                Address: user.Address,
                                PhoneNumber: user.PhoneNumber,
                                Email: user.Email,
                                PreferedLanguage: user.PreferedLanguage,
                            }}
                            title={t('common.generalInformations')}
                            onEdit={editClient}
                        />
                    </Col>
                    <Col span={8}>
                        <ListInfo
                            data={{
                                clientID: user.Id,
                            }}
                            title={t('common.details')}
                        />
                    </Col>
                </Row>
            ),
            button: (
                <div className="row">
                    <div className="mr-3">
                        <Button
                            className="btn-error"
                            onClick={async () => {
                                st.addModal({
                                    Comp: (
                                        <ExecuteModal
                                            onExec={() => st.http.deleteClient(user.getID || '')}
                                            onFinish={(status) => {
                                                displayNotif(t, status, {
                                                    key: t('feedback.client.deleted'),
                                                    options: { name: user.fullName },
                                                })

                                                if (status >= 200 && status <= 204) {
                                                    history.push('/clients')
                                                    st.http.loadClients()
                                                }
                                            }}
                                            isDangerBtn
                                        />
                                    ),
                                    title: {
                                        key: 'feedback.client.confirmDelete',
                                        options: {
                                            name: user.fullName,
                                        },
                                    },
                                    description: 'clientPage.descriptionDelete',
                                    icon: renderIcon({ name: 'trash', circleType: 'error' }),
                                })
                            }}
                        >
                            {t('clientPage.delete')}
                        </Button>
                    </div>
                    <ClientDropdown user={user} />
                </div>
            ),
        },
        // {
        //     key: 'exchanges',
        //     name: <p className="row">{renderIcon({ name: 'exchange', color: '#667085' })} {t('clientPage.exchanges')}</p>,
        //     render: <p>Tab 3</p>,
        // },

        // {
        //     key: 'invoices',
        //     name: (
        //         <p className="row">{renderIcon({ name: 'creditCard', color: '#667085' })} {t('clientPage.invoices')}</p>
        //     ),
        //     render: <p>Tab 5</p>,
        // },
    ]

    if (st.claims.includes('reservations-manager')) {
        tabs.push({
            key: 'reservations',
            name: (
                <p className="row">
                    {renderIcon({ name: 'calendar', color: '#667085' })} {t('clientPage.reservations')}
                </p>
            ),
            render: <ClientReservations />,
            button: <ClientDropdown user={user} />,
        })
    }

    return (
        <Fragment>
            <HeaderUI />
            <PageContainer title={user.fullName} subtitle={<Text type="secondary">{user.Email}</Text>} tabs={tabs} />
        </Fragment>
    )
})

export default ClientPage
