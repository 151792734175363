import { User } from 'state/user'
import { notSecuredHttpPost, securedHttpPost, securedHttpGet } from './http'
import { CustomItem, ResponseGet, ResponsePost, TimelineType, UserDropoff, UserPickup } from './types'
import { ExchangeData } from 'types/exchange'
import { UserData, UserType } from 'types/user'
import { UserTimeline } from 'types/timeline'
import { GridData } from 'types/grid'

export const getCustomer = (Id: string): ResponsePost<User> =>
    securedHttpPost('/customer-get', { Id }, (data) => {
        return new User(JSON.parse(data))
    })

export const getAllCustomers = (page = 1, limit = 10, params?: { [key: string]: any }): ResponseGet<GridData<User>> =>
    securedHttpGet(`/customer-get-all?page=${page}&limit=${limit}`, params || {}, (data) => {
        const parsedData = JSON.parse(data)

        return {
            ...parsedData,
            Data: parsedData.Data.map((user: UserData) => new User(user)),
        }
    })

export const createCustomer = (data: UserType): ResponsePost<{ Id: string }> => securedHttpPost('/customer-add', data)

export const editCustomer = (data: UserType): ResponsePost<null> => securedHttpPost('/customer-update', data)

export const getUserGarage = (OperationId: string, TenantId: string, AgencyId: string): ResponsePost<ExchangeData> =>
    notSecuredHttpPost('/garage-user-operation-get', { OperationId, TenantId, AgencyId })

export const createCustomerItem = (data: CustomItem): ResponsePost<null> =>
    securedHttpPost('/customer-add-personalitem', data)

export const removeCustomer = (CustomerId: string): ResponsePost<null> =>
    securedHttpPost('/customer-remove', { CustomerId })

export const getUserTimeline = (UserId: string): ResponsePost<TimelineType<UserTimeline>> =>
    securedHttpPost('/garage-usertimeline-get', { UserId })

// DROPOFF
export const userStartDropoff = (data: UserDropoff): ResponsePost<null> =>
    notSecuredHttpPost('/garage-user-dropoff-start', data)

export const userExecDropoff = (data: UserDropoff): ResponsePost<null> =>
    notSecuredHttpPost('/garage-user-dropoff-execute', data)

// PICKUP
export const userStartPickup = (data: UserPickup): ResponsePost<null> =>
    notSecuredHttpPost('/garage-user-pickup-start', data)

export const userExecPickup = (data: UserPickup): ResponsePost<null> =>
    notSecuredHttpPost('/garage-user-pickup-execute', data)

// PAYMENT
export const userPaymentInitiate = (data: UserPickup): ResponsePost<{ url: string }> =>
    notSecuredHttpPost('/garage-user-pickup-payment-initiate', data)

export const checkOperationExpiry = (TenantId: string, AgencyId: string, OperationId: string): ResponsePost<boolean> =>
    notSecuredHttpPost('/check-operation-expiry', { TenantId, AgencyId, OperationId })
