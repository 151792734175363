import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ValidateMessages } from 'rc-field-form/lib/interface'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import './styles/app.less'
import 'i18n/config'
import localeFr from 'antd/lib/locale/fr_FR'
import localeEn from 'antd/lib/locale/en_US'
import localeIt from 'antd/lib/locale/it_IT'

import { ProtectedAppRoutes } from 'router/router'
import ConfigProvider from 'antd/lib/config-provider'
import Modals from 'components/Modals'
import ModalForm from 'components/ModalForm'
import { useSt } from 'state/state'
import EmployeePage from 'pages/EmployeePage'
import UserDropoffPage from 'pages/UserDropoffPage'
import UserPickupPage from 'pages/UserPickupPage'
import RentalDropoffPage from 'pages/RentalDropoffPage'
import RentalPickupPage from 'pages/RentalPickupPage'
import UserPaymentPage from 'pages/UserPaymentPage'
import PaymentSuccessPage from 'pages/PaymentSuccessPage'
import PaymentFailedPage from 'pages/PaymentFailedPage'

require('dayjs/locale/fr')
require('dayjs/locale/en')
require('dayjs/locale/it')

export const App = observer(() => {
    const st = useSt()
    const { t, i18n } = useTranslation()

    const validateMessages: ValidateMessages = t('form.messages', { returnObjects: true })
    const isProd = process.env.NODE_ENV === 'production'

    const locales = {
        en: localeEn,
        fr: localeFr,
        it: localeIt,
    }

    useEffect(() => {
        if (i18n.language === 'fr-FR' || i18n.language === 'fr') st.lang = 'fr'
        if (i18n.language === 'it-IT' || i18n.language === 'it') st.lang = 'it'

        dayjs.locale(st.lang)
    }, [])

    return (
        <ConfigProvider form={{ validateMessages }} locale={locales[st.lang]}>
            <Helmet>
                <title>{isProd ? 'Efikeys' : 'Seqube'}</title>
                <link rel="icon" href={`/${isProd ? 'favicon.ico' : 'favicon.png'}`} />
            </Helmet>
            <Router>
                <Switch>
                    <Route path="/employee/:employeeId" component={EmployeePage}></Route>
                    <Route path="/user-dropoff/:lang/:token" component={UserDropoffPage}></Route>
                    <Route path="/user-pickup/:lang/:token" component={UserPickupPage}></Route>
                    <Route path="/rental-user-dropoff/:lang/:token" component={RentalDropoffPage}></Route>
                    <Route path="/rental-user-pickup/:lang/:token" component={RentalPickupPage}></Route>
                    <Route path="/user-payment/:lang/:token" component={UserPaymentPage}></Route>
                    <Route path="/payment/success" component={PaymentSuccessPage}></Route>
                    <Route path="/payment/failed" component={PaymentFailedPage}></Route>
                    <Route path="/" component={ProtectedAppRoutes} />
                </Switch>
                <Modals />
                <ModalForm />
            </Router>
        </ConfigProvider>
    )
})

export default App
