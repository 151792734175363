import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { columnsVehicles, tabsVehicles } from 'static/vehicle'
import { useSt } from 'state/state'
import { VehicleData, VehicleSchema } from 'types/vehicle'
import HeaderUI from 'components/Header'
import CustomGrid from 'components/grid/CustomGrid'
import CreateVehicleModal from 'components/modals/CreateVehicleModal'
import { renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'

import { Col, Row } from 'antd/lib/grid'
import Title from 'antd/lib/typography/Title'
import Button from 'antd/lib/button'
import Typography from 'antd/lib/typography'
import Divider from 'antd/lib/divider'
import { delay } from 'helpers/delay'

const ListVehicles: FunctionComponent = observer(() => {
    const { t } = useTranslation()
    const history = useHistory()
    const st = useSt()

    const [filterParams, setFilterParams] = useState({})

    useEffect(() => {
        !st.vehicles && st.http.loadAllVehicles()

        return () => {
            st.vehicles = null
        }
    }, [])

    return (
        <Fragment>
            <HeaderUI />
            <section className="container">
                <Row justify="space-between">
                    <Col>
                        <Title level={2}>{t('listVehicles.title')}</Title>
                        <Typography.Text type="secondary" className="mt-1 fz-16">
                            {t('listVehicles.subtitle')}
                        </Typography.Text>
                    </Col>
                    <Col className="my-sm-2">
                        <Button
                            type="primary"
                            icon={renderIcon({ name: 'add' })}
                            onClick={() =>
                                st.addModalForm({
                                    schema: VehicleSchema,
                                    steps: [
                                        {
                                            comp: <CreateVehicleModal />,
                                            title: 'createVehicleModal.title',
                                            icon: renderIcon({ name: 'car', circleType: 'primary' }),
                                        },
                                    ],
                                    submit: async (data) => {
                                        data.VehicleId = data.RegistrationNumber
                                        const createdVehicle = await st.http.createVehicle(data)
                                        displayNotif(t, createdVehicle.status, { key: 'feedback.vehicle.created' })

                                        if (createdVehicle.status === 200) {
                                            st.http.loadAllVehicles()
                                        }
                                    },
                                })
                            }
                        >
                            {t('listVehicles.add')}
                        </Button>
                    </Col>
                    <Divider />
                </Row>
                <CustomGrid
                    name="vehicles"
                    dataset={st.vehicles}
                    columns={columnsVehicles(t)}
                    tabs={tabsVehicles(t)}
                    onFilter={delay((params) => {
                        const { page, pageSize, ...filters } = params
                        setFilterParams(filters)
                        st.http.loadAllVehicles(page, pageSize, filters)
                    }, 1000)}
                    onRowClick={(vehicle: VehicleData) => history.push(`/vehicle/${vehicle.Id}`)}
                    onPageChange={(page, pageSize) => st.http.loadAllVehicles(page, pageSize, filterParams)}
                />
            </section>
        </Fragment>
    )
})

export default ListVehicles
