import dayjs from 'dayjs'
import { TFunction } from 'i18next'

const getHours = (hours: number) => hours.toString().padStart(2, '0')
const getMinutes = (minute: number) => minute.toString().padStart(2, '0')

export const renderDate = (date: string): string => {
    const d = dayjs(date)
    const currentDate = d.format('DD/MM/YYYY')

    return currentDate
}

export const renderTime = (date: string): string => {
    const formatDate = dayjs(date)
    return `${getHours(formatDate.hour())}h${getMinutes(formatDate.minute())}`
}

export const renderFullDate = (date: string): string => {
    const formatDate = dayjs(date)

    return formatDate.format('DD MMMM YYYY')
}

export const renderFormatDate = (t: TFunction, date: string): string => {
    return `${renderDate(date)} ${t('common.at')} ${renderTime(date)}`
}
export function convertUTCDateToLocalDate(date :Date) : Date{
    const dateLocal = new Date(date);
    const newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset());
    return newDate;
  }
