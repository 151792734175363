import { Fragment, FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'helpers/icons'
import HeaderUI from 'components/Header'

import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

const PaymentFailedPage: FunctionComponent = () => {
    const { t } = useTranslation()
    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <section className="static-section container centered">
                <Icon name="alert" circleType="error" />
                <Title className="fw-600 mt-6">{t('paymentFailed.title')}</Title>
                <Text type="secondary" className="my-10">
                    <Trans i18nKey="paymentFailed.text" />
                </Text>
            </section>
        </Fragment>
    )
}

export default PaymentFailedPage
