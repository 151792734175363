import { ReactSVG } from 'react-svg'
import { FC } from 'react'

const icons = [
    'add',
    'address',
    'arrowBack',
    'alert',
    'arrowDown',
    'arrowRight',
    'arrowLeft',
    'calendar',
    'car',
    'check',
    'checkCircle',
    'close',
    'creditCard',
    'dot',
    'email',
    'exchange',
    'file',
    'fr',
    'key',
    'logo',
    'logoEfikeys',
    'logout',
    'logoCircle',
    'phone',
    'pin',
    'settings',
    'search',
    'trash',
    'unlock',
    'user',
    'us',
    'it',
    'userPlus',
    'upload',
] as const

type IconsType = typeof icons[number]

type IconParams = {
    name: IconsType
    color?: string
    position?: 'left' | 'right'
    circleType?: 'primary' | 'error' | 'secondary'
}

export const Icon: FC<IconParams> = ({ name, color, position = 'left', circleType }) => {
    const classCircle = circleType ? `circle-${circleType}` : ''
    return (
        <ReactSVG
            src={`/icons/${name}.svg`}
            style={{ color }}
            className={`svg ${position === 'left' ? 'left' : 'right'} ${classCircle}`}
            wrapper="span"
        />
    )
}

export const renderIcon = (p: IconParams): JSX.Element => {
    const list: { [key in IconsType]?: JSX.Element } = {}

    icons.map(
        (icon) =>
            (list[icon as IconsType] = (
                <Icon name={icon} color={p.color} position={p.position} circleType={p.circleType} />
            )),
    )

    return list[p.name] || <div />
}
