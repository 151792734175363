import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { Icon } from 'helpers/icons'
import { renderTime } from 'helpers/time'
import { groupBy } from 'helpers/groupBy'
import { EventTypes } from './TimelineTypes'

import Text from 'antd/lib/typography/Text'
import AntTimeline from 'antd/lib/timeline'
import { UserTimeline } from 'types/timeline'

type TimelineParams = {
    timeline: any[]
    listEvents: any[]
}

const Timeline: FunctionComponent<TimelineParams> = observer(({ timeline, listEvents }) => {
    const st = useSt()
    const { t } = useTranslation()
    const groupByObject = groupBy('ItemId', timeline, listEvents).get(st.currentObject)
    const timelineByDate = groupBy('DateOfOccurence', groupByObject, listEvents)
    const timelineData = Array.from(timelineByDate.keys())

    return (
        <div className="pb-8">
            <Text className="mb-8" strong>
                {t('timeline.title')}
            </Text>
            {timelineData.length === 0 && <Text>{t('timeline.noActions.clients')}</Text>}
            {timelineData.map((date, idx) => {
                return (
                    <div key={idx} className="mb-10">
                        <Text strong>{date}</Text>
                        <AntTimeline className="mt-7">
                            {timelineByDate.get(date).map((event: UserTimeline, idx: number) => (
                                <AntTimeline.Item
                                    key={idx}
                                    className={event.Type === 'OccuredStep' ? 'event-completed' : ''}
                                    dot={
                                        event.Type === 'OccuredStep' && (
                                            <div className="custom-dot">
                                                <Icon name="check" />
                                            </div>
                                        )
                                    }
                                >
                                    <p className="event-date mr-8 c-gray-500">
                                        {`${t('common.at')} ${renderTime(event.DateOfOccurence || event.DropOffDate)}`}
                                    </p>
                                    <EventTypes event={event} />
                                </AntTimeline.Item>
                            ))}
                        </AntTimeline>
                    </div>
                )
            })}
        </div>
    )
})

export default Timeline
