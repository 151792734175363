import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { renderIcon } from 'helpers/icons'

import Button from 'antd/lib/button'
import Title from 'antd/lib/typography/Title'
import Tabs from 'antd/lib/tabs'

type PageContainerParams = {
    title: string
    subtitle?: ReactNode
    suffixTitle?: ReactNode
    hideReturn?: boolean
    tabs?: {
        key: string
        name: ReactNode
        render: ReactNode
        button?: ReactNode
        [key: string]: any
    }[]
    renderSingle?: {
        comp: ReactNode
        button?: ReactNode
    }
}

const PageContainer: FC<PageContainerParams> = ({
    title,
    subtitle,
    tabs = [],
    suffixTitle,
    hideReturn,
    renderSingle,
}) => {
    const [currentTab, setCurrentTab] = useState(tabs[0]?.key || '')
    const history = useHistory()
    const { t } = useTranslation()
    const activeTab = tabs ? tabs.find((el) => el.key === currentTab) : false

    return (
        <section className="pageContainer container">
            <div className="row between">
                <div className="pageContainer-header">
                    {!hideReturn && (
                        <Button
                            type="link"
                            onClick={() => history.goBack()}
                            icon={renderIcon({ name: 'arrowBack' })}
                            className="mb-5"
                        >
                            {t('common.back')}
                        </Button>
                    )}
                    <div className="row mb-1">
                        <Title level={2}>{title}</Title>
                        {suffixTitle && <div className="ml-2">{suffixTitle}</div>}
                    </div>
                    {subtitle && <div className="row row-info">{subtitle}</div>}
                </div>
                {activeTab && activeTab.button}
                {renderSingle && renderSingle.button}
            </div>

            {renderSingle ? (
                <div className="pageContainer-separator">{renderSingle.comp}</div>
            ) : (
                <Tabs onChange={(key) => setCurrentTab(key)}>
                    {tabs.map((tab) => (
                        <Tabs.TabPane key={tab.key} tab={tab.name}>
                            {tab.render}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            )}
        </section>
    )
}
export default PageContainer
