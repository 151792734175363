import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { Icon } from 'helpers/icons'
import { renderTime } from 'helpers/time'
import { groupBy } from 'helpers/groupBy'
import { vehicleEventTypes, VehicleTimeline } from 'types/timeline'
import { TimelineVehicleTypes } from './TimelineVehicleTypes'

import Text from 'antd/lib/typography/Text'
import AntTimeline from 'antd/lib/timeline'

type TimelineVehicleParams = {
    timeline: VehicleTimeline[]
}

const TimelineVehicle: FunctionComponent<TimelineVehicleParams> = observer(({ timeline }) => {
    const { t } = useTranslation()
    const timelineByDate = groupBy('DateOfOccurence', timeline, vehicleEventTypes)
    const timelineData = Array.from(timelineByDate.keys())

    return (
        <div className="pb-8">
            <Text className="mb-8" strong>
                {t('timeline.title')}
            </Text>
            {timelineData.length === 0 && <Text>{t('timeline.noActions.vehicles')}</Text>}
            {timelineData.map((date, idx) => (
                <div key={idx} className="mb-10">
                    <Text strong>{date}</Text>
                    <AntTimeline className="mt-7">
                        {timelineByDate.get(date).map((event: VehicleTimeline, idx: number) => {
                            return (
                                <AntTimeline.Item
                                    key={idx}
                                    className={event.Type === 'OccuredStep' ? 'event-completed' : ''}
                                    dot={
                                        event.Type === 'OccuredStep' && (
                                            <div className="custom-dot">
                                                <Icon name="check" />
                                            </div>
                                        )
                                    }
                                >
                                    <p className="event-date mr-8 c-gray-500">
                                        {`${t('common.at')} ${renderTime(event.DateOfOccurence || dayjs().toString())}`}
                                    </p>
                                    <TimelineVehicleTypes event={event} />
                                </AntTimeline.Item>
                            )
                        })}
                    </AntTimeline>
                </div>
            ))}
        </div>
    )
})

export default TimelineVehicle
