import { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { Grid } from 'state/grid'
import GridView from './GridView'
import { GridData, GridTab } from 'types/grid'

import { ColumnsType } from 'antd/lib/table'

type CustomGridParams = {
    name: string
    dataset: GridData<any> | null
    columns: ColumnsType<any>
    tabs?: GridTab[]
    onFilter?: (params: any) => Promise<any> | void
    onRowClick?: (item: any) => void
    onPageChange?: (page: number, pageSize?: number) => Promise<any>
    isSmall?: {
        title: string
        withSearch?: boolean
    }
    searchPlaceholder?: string
}

const CustomGrid: FunctionComponent<CustomGridParams> = observer(
    ({ name, dataset, columns, tabs, onFilter, onRowClick, onPageChange, isSmall, searchPlaceholder }) => {
        const grid = new Grid(name, dataset, columns, tabs, onFilter, onRowClick, onPageChange)
        const gridClass = isSmall ? 'grid grid--single' : 'grid'

        return (
            <div className={gridClass}>
                <GridView grid={grid} isSmall={isSmall} searchPlaceholder={searchPlaceholder} />
            </div>
        )
    },
)

export default CustomGrid
