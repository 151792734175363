import { FormEvent } from 'react'
import { makeAutoObservable } from 'mobx'
import { GridData, GridTab } from 'types/grid'
import { ColumnsType } from 'antd/lib/table'

type FilterType<P> = (params: any) => Promise<GridData<P>> | void
type RowClickType<P> = (item: P) => void
type PageChangeType<P> = (page: number, pageSize?: number) => Promise<GridData<P>>

export class Grid<P> {
    name: string
    dataset: GridData<P> | null = null
    data: P[] | null = null
    filteredData: P[] | null = null
    searchResults: P[] | null = null
    columns: ColumnsType<P>
    searchValue: string
    activeTab?: string
    tabs?: GridTab[]
    onFilter?: FilterType<P>
    filterParams: any = {
        page: 1,
        pageSize: 10,
    }
    currentPage = 1
    currentPageSize = 10
    onRowClick?: RowClickType<P>
    onPageChange?: PageChangeType<P>

    constructor(
        name: string,
        dataset: GridData<P> | null,
        columns: ColumnsType<P>,
        tabs?: GridTab[],
        onFilter?: FilterType<P>,
        onRowClick?: RowClickType<P>,
        onPageChange?: (page: number, pageSize?: number) => Promise<GridData<P>>,
    ) {
        makeAutoObservable(this)
        this.name = name
        this.dataset = dataset
        this.columns = columns
        this.searchValue = ''
        this.tabs = tabs
        this.onFilter = onFilter
        this.activeTab = tabs ? tabs[0].key : undefined
        this.onRowClick = onRowClick
        this.onPageChange = onPageChange

        if (dataset) {
            this.data = dataset.Data
            this.currentPage = dataset.CurrentPage
        }
    }

    updateTab = (tab: string) => {
        this.activeTab = tab
        this.filterParams['status'] = tab

        this.currentPage = 1
        this.filterParams['page'] = this.currentPage

        if (this.onFilter) this.onFilter(this.filterParams)
    }

    filterBySearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const value = (e.target as any).value
        if (value && value.length > 2) {
            this.filterParams['term'] = value
            if (this.onFilter) this.onFilter(this.filterParams)
        }
    }

    changePage = async (page: number, pageSize?: number) => {
        this.currentPage = page
        if (pageSize) this.currentPageSize = pageSize

        this.filterParams['page'] = this.currentPage
        this.filterParams['pageSize'] = this.currentPageSize

        // if (this.onFilter) this.onFilter(this.filterParams)
        if (this.onPageChange) this.onPageChange(page, pageSize)
    }

    get getData(): P[] | null {
        return this.data
    }

    get rowKey(): string {
        if (this.data && this.data[0]) {
            if (!('Id' in this.data[0]) && !('id' in this.data[0])) {
                return 'Email'
            }

            if ('Id' in this.data[0]) {
                return 'Id'
            }
        }

        return 'id'
    }

    get total(): number {
        if (this.filteredData) {
            return this.getData?.length || 0
        }
        if (this.dataset) {
            return this.dataset.Total
        }

        return 0
    }
}
