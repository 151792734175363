import { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import HeaderUI from 'components/Header'
import VehiclesModal from 'components/modals/VehiclesModal'
import CustomGrid from 'components/grid/CustomGrid'
import ClientModal from 'components/modals/ClientModal'
import SelectClientModal from 'components/modals/SelectClientModal'
import ReservationsDateModal from 'components/modals/ReservationsDateModal'
import {
    CreateReservationType,
    ReservationData,
    ReservationDropOff,
    ReservationPickUp,
    ReservationSchema,
} from 'types/reservation'
import { GridData } from 'types/grid'
import { Icon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import { columnsReservations, tabsReservations } from 'static/reservations'
import { useSt } from 'state/state'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import { UserType } from 'types/user'
import { v4 as uuid } from 'uuid'
import { delay } from 'helpers/delay'

const ListReservations: FC = observer(() => {
    const st = useSt()
    const [reservations, setReservations] = useState<GridData<ReservationData> | null>(null)
    const history = useHistory()
    const { t } = useTranslation()

    const [filterParams, setFilterParams] = useState({})

    const getReservations = async (page?: number, pageSize?: number, params?: { [key: string]: any }) => {
        const req = await st.http.loadAllReservations(page, pageSize, params)

        setReservations(req)

        return req
    }
    const uniqueId = uuid()

    const createReservation = async (data: CreateReservationType) => {
        data.Id = uniqueId
        // Create user if needed
        if (data.Firstname && data.Lastname && data.Email) {
            const userData: UserType = {
                Firstname: data.Firstname,
                Lastname: data.Lastname,
                Email: data.Email,
                PhoneNumber: data.PhoneNumber,
                Address: data.Address,
                PreferedLanguage: data.PreferedLanguage,
            }
            const createUser = await st.http.createClient(userData)
            displayNotif(t, createUser.status, { key: 'feedback.client.created' })
            if (createUser.status >= 200 || createUser.status <= 204) st.http.loadClients()

            data.User = {
                Id: createUser.data.Id,
                Name: `${data.Firstname} ${data.Lastname}`,
                EmailAddress: data.Email,
                PreferedLanguage: data.PreferedLanguage,
            }
        } else if (data.SelectedUser) {
            const client = st.clients?.Data.find((client) => client.getID === data.SelectedUser)

            data.User = {
                Id: data.SelectedUser,
                Name: client?.fullName || '',
                EmailAddress: client?.Email || '',
                PreferedLanguage: client?.PreferedLanguage || '',
            }
        }

        const dropOffData: ReservationDropOff = {
            User: data.User,
            RentalId: data.RentalId,
            Id: data.Id,
            VehicleId: data.VehicleId,
            DropOffDate: data.DropOffDate || '',
        }
        const pickUpData: ReservationPickUp = {
            User: data.User,
            RentalId: data.RentalId,
            Id: data.Id,
            VehicleId: data.VehicleId,
            PickUpDate: data.PickUpDate || '',
        }

        // Create reservation
        if (data.DropOffDate && data.PickUpDate) {
            const createDropOff = await st.http.createReservationDropoff(dropOffData)
            if (createDropOff.status >= 200 && createDropOff.status <= 204) {
                const createPickup = await st.http.createReservationPickup(pickUpData)

                displayNotif(t, createPickup.status, { key: 'feedback.confirmAction' })
            }
        } else if (data.DropOffDate) {
            const createDropOff = await st.http.createReservationDropoff(dropOffData)
            displayNotif(t, createDropOff.status, { key: 'feedback.confirmAction' })
        } else if (data.PickUpDate) {
            const createPickup = await st.http.createReservationPickup(pickUpData)
            displayNotif(t, createPickup.status, { key: 'feedback.confirmAction' })
        }

        getReservations()
    }

    useEffect(() => {
        !reservations && getReservations()
    }, [])

    return (
        <Fragment>
            <HeaderUI />
            <section className="container">
                <Row justify="space-between">
                    <Col>
                        <Title level={2}>{t('listReservations.title')}</Title>
                        <Text type="secondary" className="mt-1 fz-16">
                            {t('listReservations.subtitle')}
                        </Text>
                    </Col>
                    <Col className="my-sm-2">
                        <Button
                            type="primary"
                            icon={<Icon name="add" />}
                            onClick={() =>
                                st.addModalForm({
                                    schema: ReservationSchema,
                                    steps: [
                                        {
                                            comp: <VehiclesModal />,
                                            title: t('vehiclesModal.title'),
                                            description: t('vehiclesModal.description'),
                                            icon: <Icon name="car" circleType="primary" />,
                                        },
                                        {
                                            comp: <SelectClientModal />,
                                            alternativeComp: <ClientModal selectClient />,
                                            title: t('selectClientModal.title'),
                                            description: t('selectClientModal.description'),
                                            icon: <Icon name="userPlus" circleType="primary" />,
                                            size: 'sm',
                                        },
                                        {
                                            comp: <ReservationsDateModal />,
                                            title: t('reservationsDateModal.title'),
                                            description: t('reservationsDateModal.description'),
                                            icon: <Icon name="calendar" circleType="primary" />,
                                            size: 'sm',
                                        },
                                    ],
                                    submit: (data: CreateReservationType) => createReservation(data),
                                })
                            }
                        >
                            {t('listReservations.add')}
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <CustomGrid
                    name="reservations"
                    dataset={reservations}
                    columns={columnsReservations(t)}
                    tabs={tabsReservations(t)}
                    onFilter={delay((params) => {
                        const { page, pageSize, ...filters } = params

                        setFilterParams(filters)
                        getReservations(page, pageSize, filters)
                    }, 1000)}
                    searchPlaceholder={t('gridReservations.searchPlaceholder')}
                    onRowClick={(reservation: ReservationData) => history.push(`/reservation/${reservation.Id}`)}
                    onPageChange={(page, pageSize) => getReservations(page, pageSize, filterParams)}
                />
            </section>
        </Fragment>
    )
})
export default ListReservations
