import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { renderIcon } from 'helpers/icons'
import { useSt } from 'state/state'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import Button from 'antd/lib/button'

type VehicleAccessibilityParams = {
    vehicleId: string
    status: string
    refreshVehicle: () => void
}

const VehicleAccessibility: FC<VehicleAccessibilityParams> = observer(({ vehicleId, status, refreshVehicle }) => {
    const st = useSt()
    const { t } = useTranslation()
    if (status === 'Unaccessible') {
        return (
            <Button
                type="primary"
                onClick={() =>
                    st.addModal({
                        Comp: (
                            <ExecuteModal
                                onExec={() => st.http.makeVehicleAccessible(vehicleId)}
                                onFinish={() => {
                                    refreshVehicle()
                                    st.http.loadAllVehicles()
                                }}
                            />
                        ),
                        title: { key: 'feedback.locker.open' },
                        description: 'feedback.locker.description',
                        icon: renderIcon({ name: 'unlock', circleType: 'primary' }),
                    })
                }
            >
                {t('common.dropOffKey')}
            </Button>
        )
    }
    return (
        <Button
            type="primary"
            onClick={() =>
                st.addModal({
                    Comp: (
                        <ExecuteModal
                            onExec={() => st.http.makeVehicleUnaccessible(vehicleId)}
                            onFinish={() => {
                                refreshVehicle()
                                st.http.loadAllVehicles()
                            }}
                        />
                    ),
                    title: { key: 'feedback.vehicle.pickUp' },
                    icon: renderIcon({ name: 'unlock', circleType: 'primary' }),
                })
            }
        >
            {t('common.retrieveKey')}
        </Button>
    )
})
export default VehicleAccessibility
