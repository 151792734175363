import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { gridColumns, tabsExchanges } from 'static/exchange'
import { ExchangeData } from 'types/exchange'
// import { Icon } from 'helpers/icons'
import HeaderUI from 'components/Header'
import CustomGrid from 'components/grid/CustomGrid'

// import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Divider from 'antd/lib/divider'
import { delay } from 'helpers/delay'

const ListExchanges: FunctionComponent = observer(() => {
    const st = useSt()
    const history = useHistory()
    const { t } = useTranslation()

    const [filterParams, setFilterParams] = useState({})

    useEffect(() => {
        !st.exchanges && st.http.loadExchanges()

        return () => {
            st.exchanges = null
        }
    }, [])

    return (
        <Fragment>
            <HeaderUI />
            <section className="container">
                <Row justify="space-between">
                    <Col>
                        <Title level={2}>{t('listExchanges.title')}</Title>
                        <Text type="secondary" className="mt-1 fz-16">
                            {t('listExchanges.subtitle')}
                        </Text>
                    </Col>
                    {/* <Col className="my-sm-2">
                        <Button type="primary" icon={<Icon name="add" />}>
                            {t('listExchanges.add')}
                        </Button>
                    </Col> */}
                </Row>
                <Divider />
                <CustomGrid
                    name="exchanges"
                    dataset={st.exchanges}
                    columns={gridColumns(t)}
                    tabs={tabsExchanges(t)}
                    onFilter={delay((params) => {
                        const { page, pageSize, ...filters } = params
                        setFilterParams(filters)

                        st.http.loadExchanges(page, pageSize, filters)
                    }, 1000)}
                    searchPlaceholder={t('gridExchange.searchPlaceholder')}
                    onRowClick={(exchange: ExchangeData) => {
                        const clientID = exchange.DropOffUser.Id.includes('@')
                            ? exchange.PickUpUser.Id
                            : exchange.DropOffUser.Id

                        history.push(`/client/${clientID}`)
                    }}
                    onPageChange={(page, pageSize) => st.http.loadExchanges(page, pageSize, filterParams)}
                />
            </section>
        </Fragment>
    )
})

export default ListExchanges
