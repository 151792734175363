import { Fragment } from 'react'
import type { TFunction } from 'react-i18next'
import type { ColumnsType } from 'antd/lib/table'
import { VehicleData, VehicleType } from 'types/vehicle'
import { renderStatusVehicle } from 'helpers/renderStatus'
import { GridTab } from 'types/grid'
import { Icon } from 'helpers/icons'

export const columnsVehicles = (t: TFunction): ColumnsType<VehicleData> => [
    {
        title: t('gridVehicles.columns.id'),
        dataIndex: 'Id',
        key: 'Id',
    },
    {
        title: t('gridVehicles.columns.registrationNumber'),
        dataIndex: 'RegistrationNumber',
        key: 'RegistrationNumber',
        render: (registration: string) => <b>{registration}</b>,
    },
    {
        title: t('gridVehicles.columns.brandModel'),
        dataIndex: 'Brand/Model',
        key: 'Brand/Model',
        render: (text, row) => {
            return (
                <Fragment>
                    <p className="c-gray-900">{row.Brand}</p>
                    <p>{row.Model}</p>
                </Fragment>
            )
        },
    },
    // {
    //     title: 'Berline',
    //     dataIndex: 'category',
    //     key: 'category',
    // },
    // {
    //     title: 'Couleur',
    //     dataIndex: 'color',
    //     key: 'color',
    // },
    // {
    //     title: 'Transmission',
    //     dataIndex: 'transmission',
    //     key: 'transmission',
    // },
    // {
    //     title: 'GPS',
    //     dataIndex: 'gps',
    //     key: 'gps',
    //     render: (gps: boolean) => (gps ? 'Oui' : 'Non'),
    // },
    // {
    //     title: 'Agence',
    //     dataIndex: 'agency',
    //     key: 'agency',
    // },
    {
        title: t('gridVehicles.columns.status'),
        dataIndex: 'Status',
        key: 'Status',
        render: (status: string) => renderStatusVehicle(t, status),
    },
]

export const columnsVehiclesModal = (t: TFunction): ColumnsType<VehicleType> => [
    {
        title: t('gridVehicles.columns.id'),
        dataIndex: 'Id',
        key: 'Id',
    },
    {
        title: t('gridVehicles.columns.registrationNumber'),
        dataIndex: 'RegistrationNumber',
        key: 'RegistrationNumber',
        render: (registration) => <b>{registration}</b>,
    },
    {
        title: t('gridVehicles.columns.brandModel'),
        dataIndex: 'Brand/Model',
        key: 'Brand/Model',
        render: (text, row) => {
            return (
                <Fragment>
                    <p className="c-gray-900">{row.Brand}</p>
                    <p>{row.Model}</p>
                </Fragment>
            )
        },
    },
]

export const tabsVehicles = (t: TFunction): GridTab[] => [
    {
        name: t('common.all'),
        key: '',
    },
    {
        name: t('gridVehicles.tabs.available'),
        key: 'Accessible',
        icon: <Icon name="dot" color="#12B76A" />,
    },
    {
        name: t('gridVehicles.tabs.booked'),
        key: 'Booked',
    },
    {
        name: t('gridVehicles.tabs.unavailable'),
        key: 'Unaccessible',
    },
]
