import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { displayNotif } from 'helpers/displayNotif'
import { ResponsePost } from 'api/types'
import Button from 'antd/lib/button'

type ExecuteModalParams = {
    onExec: () => ResponsePost<null>
    onFinish?: (status: number) => void
    isDangerBtn?: boolean
}

const ExecuteModal: FunctionComponent<ExecuteModalParams> = observer(({ onExec, onFinish, isDangerBtn }) => {
    const st = useSt()
    const { t } = useTranslation()
    const [isLoading, setLoading] = useState(false)
    const execAction = async () => {
        setLoading(true)
        const req = await onExec()

        displayNotif(t, req.status, { key: 'feedback.confirmAction' })

        if (req.status >= 200 || req.status <= 204) {
            onFinish && onFinish(req.status)
            st.modal = null
        }

        setLoading(false)
    }

    return (
        <div className="form-footer ">
            <Button type="ghost" onClick={() => (st.modal = null)}>
                {t('common.cancel')}
            </Button>
            <Button type="primary" danger={isDangerBtn} onClick={() => execAction()} loading={isLoading}>
                {t('common.confirm')}
            </Button>
        </div>
    )
})

export default ExecuteModal
