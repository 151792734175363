import { ReactNode } from 'react'
import { Tag } from 'antd'
import { TFunction } from 'react-i18next'

export const renderStatusExchange = (t: TFunction, status: number): ReactNode => {
    if (status === 0) {
        return <Tag color="orange">{t('gridExchange.tags.waiting')}</Tag>
    } else if (status === 1) {
        return <Tag color="purple">{t('gridExchange.tags.delivered')}</Tag>
    } else if (status === 2) {
        return <Tag color="success">{t('gridExchange.tags.retrieved')}</Tag>}
    
    else {
        return <Tag color="yellow">{t('gridExchange.tags.canceled')}</Tag>
    }
}

export const renderStatusVehicle = (t: TFunction, status: string, isLarge?: boolean): ReactNode => {
    const largeClass = isLarge ? 'ant-tag-lg' : ''
    if (status === 'Unaccessible') {
        return (
            <Tag color="error" className={largeClass}>
                {t('gridVehicles.tags.unavailable')}
            </Tag>
        )
    } else if (status === 'Accessible') {
        return (
            <Tag color="success" className={largeClass}>
                {t('gridVehicles.tags.available')}
            </Tag>
        )
    } else {
        return (
            <Tag color="orange" className={largeClass}>
                {t('gridVehicles.tags.booked')}
            </Tag>
        )
    }
}

export const renderStatusReservations = (t: TFunction, status: string, renderStr?: boolean): ReactNode | string => {
    switch (status) {
        case 'WaitingVehicleAssociation':
            if (renderStr) return t('gridReservations.tags.waitingVehicleAssociation')
            else return <Tag color="error">{t('gridReservations.tags.waitingVehicleAssociation')}</Tag>
            break
        case 'WaitingVehiclePickup':
            if (renderStr) return t('gridReservations.tags.waitingVehiclePickup')
            else return <Tag color="purple">{t('gridReservations.tags.waitingVehiclePickup')}</Tag>
            break
        case 'WaitingVehicleDropOff':
            if (renderStr) return t('gridReservations.tags.waitingVehicleDropOff')
            else return <Tag color="orange">{t('gridReservations.tags.waitingVehicleDropOff')}</Tag>
            break
            case 'Canceled':
                if (renderStr) return t('gridReservations.tags.canceled')
                else return <Tag color="yellow">{t('gridReservations.tags.canceled')}</Tag>
                break
        default:
            if (renderStr) return t('gridReservations.tags.closed')
            else return <Tag color="success">{t('gridReservations.tags.closed')}</Tag>
    }
}
