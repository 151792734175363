import { Fragment, FunctionComponent, ReactNode, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { ExchangeData } from 'types/exchange'
import { useSt } from 'state/state'
import { Icon, renderIcon } from 'helpers/icons'
import { displayExpandedRow } from 'helpers/displayExpandedRow'
import { Grid } from 'state/grid'

import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Radio from 'antd/lib/radio'
import Input from 'antd/lib/input'
import Text from 'antd/lib/typography/Text'

type GridViewParams = {
    grid: Grid<any>
    searchPlaceholder?: string
    isSmall?: {
        title: string
        withSearch?: boolean
    }
}

const GridView: FunctionComponent<GridViewParams> = observer(({ grid, searchPlaceholder, isSmall }) => {
    const st = useSt()
    const { t } = useTranslation()

    return (
        <Fragment>
            <div className="grid-header">
                {isSmall && <Text strong>{isSmall.title}</Text>}
                {grid.tabs && (
                    <Radio.Group
                        id="grid-radio-group"
                        onChange={(e) => grid.updateTab(e.target.value)}
                        defaultValue={grid.tabs[0].key}
                    >
                        {grid.tabs.map((tab) => (
                            <Radio.Button id={tab.key} value={tab.key} key={tab.key}>
                                {tab.icon && <div className="grid-tab-icon">{tab.icon}</div>}
                                {tab.name}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                )}
                {(!isSmall || isSmall?.withSearch) && (
                    <div className="grid-header_search">
                        <form
                            onKeyUp={(e: KeyboardEvent<HTMLFormElement>) => {
                                const value = (e.target as HTMLInputElement).value

                                if (value.length >= 3 || e.key === 'Backspace') grid.filterBySearch(e)
                            }}
                            onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    const value = (e.target as HTMLInputElement).value

                                    if (value.length >= 3) grid.filterBySearch(e)
                                }
                            }}
                        >
                            <Input
                                type="text"
                                id="grid-search"
                                placeholder={searchPlaceholder ? searchPlaceholder : t('common.search')}
                                prefix={renderIcon({ name: 'search' })}
                                value={grid.searchValue}
                                onChange={(e) => (grid.searchValue = e.target.value)}
                            />
                        </form>
                    </div>
                )}
            </div>
            <Table
                dataSource={grid.getData || []}
                columns={grid.columns}
                rowKey={grid.rowKey}
                pagination={{
                    itemRender: (page, type, el) => paginationRender(t, type, el),
                    onChange: (page, pageSize) => grid.changePage(page, pageSize),
                    total: grid.total,
                    current: grid.currentPage,
                    // pageSize: grid.currentPageSize,
                    showSizeChanger: true,
                }}
                loading={!grid.getData}
                expandable={
                    st.windowWidth < 768
                        ? { expandedRowRender: (exchange: ExchangeData) => displayExpandedRow(t, exchange) }
                        : undefined
                }
                onRow={(column) => ({
                    onClick: () => (grid.onRowClick ? grid.onRowClick(column) : undefined),
                })}
            />
        </Fragment>
    )
})

const paginationRender = (t: TFunction, type: string, originalElement: ReactNode) => {
    if (type === 'prev') {
        return (
            <Button type="ghost">
                <Icon name="arrowLeft" />
                {t('common.previous')}
            </Button>
        )
    }
    if (type === 'next') {
        return (
            <Button type="ghost">
                {t('common.next')}
                <Icon name="arrowRight" position="right" />
            </Button>
        )
    }
    return originalElement
}

export default GridView
