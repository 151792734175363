import * as yup from 'yup'

export type VehicleData = {
    Id: string
    RegistrationNumber: string
    Brand: string
    Model: string
    Category: string
    Color: string
    Transmission: string
    Gps: boolean
    Agency: string
    Status: 0 | 1 | 2
}

export const VehicleSchema = yup.object().shape({
    Id: yup.string().required(),
    RegistrationNumber: yup.string().required(),
    Brand: yup.string().required(),
    Model: yup.string().required(),
    Status: yup.string().required(),
})

export type VehicleType = yup.InferType<typeof VehicleSchema>
