import { securedHttpGet, securedHttpPost } from './http'
import { ResponseGet, ResponsePost } from './types'
import { EmployeeData, EmployeeType } from 'types/employee'
import { GridData } from 'types/grid'

export const getAllEmployees = (): ResponseGet<EmployeeData[]> => securedHttpGet(`/employee-account-get-all`)

export const getEmployeesByAgency = (AgencyId: string, page = 1): ResponsePost<GridData<EmployeeData>> =>
    securedHttpPost(`/employee-account-get-all-by-agency?page=${page}&limit=10`, { AgencyId })

export const getEmployee = (Id: string): ResponsePost<EmployeeData> => securedHttpPost(`/employee-account-get`, { Id })

export const createEmployee = (data: EmployeeType): ResponsePost<EmployeeData[]> =>
    securedHttpPost(`/employee-account-add`, data)

export const editEmployee = (data: EmployeeType): ResponsePost<EmployeeData> =>
    securedHttpPost(`/employee-account-update`, data)

export const deleteEmployee = (EmployeeAccountId: string): ResponsePost<any> =>
    securedHttpPost(`/employee-account-delete`, { EmployeeAccountId })
