import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'antd/lib/button'
import { useSt } from 'state/state'
import ExecuteActionModal from 'components/modals/ExecuteActionModal'
import { renderIcon } from 'helpers/icons'
import { VehicleTimeline } from 'types/timeline'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import Countdown from 'react-countdown'

type TimelineVehicleTypesParams = {
    event: VehicleTimeline
}

const RESEND_COUNTDOWN = 60

export const TimelineVehicleTypes: FunctionComponent<TimelineVehicleTypesParams> = ({ event }) => {
    const st = useSt()
    const { t } = useTranslation()

    const [resendDisabled, setResendDisabled] = useState(false)
    const [countdown, setCountdown] = useState(() => {
        const lastResend = localStorage.getItem(`vehicleLastResend_${event.AppointmentId}`)
        if (lastResend) {
            const diff = Math.floor((new Date().getTime() - parseInt(lastResend)) / 1000)
            if (diff < RESEND_COUNTDOWN) {
                setResendDisabled(true)
                return RESEND_COUNTDOWN - diff
            }
        }
        return 0
    })

    const startAction = async () => {
        st.addModal({
            Comp: (
                <ExecuteActionModal
                    onExec={() => st.http.makeVehicleAccessible(event.Vehicle.Id)}
                    onFinish={() => {
                        st.http.refreshVehicleTimeline(event.RentalId)
                    }}
                />
            ),
            title: { key: 'feedback.locker.open' },
            description: 'feedback.locker.description',
            icon: renderIcon({ name: 'unlock', circleType: 'primary' }),
        })
    }

    const sendEmail = async (emailType: 'dropoff' | 'pickup') => {
        localStorage.setItem(`vehicleLastResend_${event.AppointmentId}`, new Date().getTime().toString())

        setCountdown(RESEND_COUNTDOWN)
        setResendDisabled(true)
        switch (emailType) {
            case 'dropoff':
                st.http.sendVehicleDropoffEmail(event.AppointmentId)
                break
            case 'pickup':
                st.http.sendVehiclePickupEmail(event.AppointmentId)
                break
            default:
                return
        }
    }

    const cancel = async () => {
        st.addModal({
            Comp: (
                <ExecuteModal
                    onExec={() => st.http.cancelReservation(event.RentalId)}
                    onFinish={() => {
                        st.http.refreshVehicleTimeline(event.RentalId)
                    }}
                />
            ),
            title: { key: 'feedback.cancelReservation.title' },
            description: 'feedback.cancelReservation.description',
            icon: renderIcon({ name: 'trash', circleType: 'primary' }),
        })
    }

    switch (event.Name) {
        case 'PickUpVehicleAppointmentMadeStep':
            return (
                <p>
                    {t('events.vehicle.pickUpVehicleAppointmentMadeStep', {
                        id: event.RentalNumber,
                        name: event.User.Name,
                    })}
                </p>
            )
        case 'AppointmentVehicleAssociationStep':
            return (
                <div>
                    <p>
                        <b>{t('events.vehicle.appointmentVehicleAssociationStep')}</b>
                    </p>
                    <div className="row mt-3">
                        <Button onClick={() => cancel()} type="text" className="mr-4">
                            {t('common.cancel')}
                        </Button>
                        <Button type="primary" onClick={startAction}>
                            {t('common.dropOffKey')}
                        </Button>
                    </div>
                </div>
            )
        case 'AppointmentVehicleAssociatedStep':
            return <p>{t('events.vehicle.appointmentVehicleAssociatedStep')}</p>

        case 'AppointmentVehiclePickUpStep':
            return (
                <div>
                    <p>
                        <b>{t('events.vehicle.appointmentVehiclePickUpStep', { name: event.User.Name })}</b>
                    </p>
                    <div className="row mt-3">
                        <Button onClick={() => cancel()} type="text" className="mr-4">
                            {t('common.cancelAndKey')}
                        </Button>
                        <Button disabled={resendDisabled} onClick={() => sendEmail('pickup')} type="primary">
                            {t('common.resendEmail')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span className="ml-2">({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setResendDisabled(false)
                                        localStorage.removeItem(`vehicleLastResend_${event.AppointmentId}`)
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )
        case 'AppointmentVehiclePickedUpStep':
            return <p className="mb-3">{t('events.vehicle.appointmentVehiclePickedUpStep')}</p>
        case 'DropOffVehicleAppointmentMadeStep':
            return (
                <p>
                    {t('events.vehicle.dropOffVehicleAppointmentMadeStep', {
                        id: event.RentalNumber,
                        name: event.User.Name,
                    })}
                </p>
            )
        case 'AppointmentVehiclePickedUpStepCanceled':
            return (
                <p>
                    {t('events.vehicle.appointmentVehiclePickedUpStepCanceled', {
                        id: event.RentalNumber,
                        name: event.User.Name,
                    })}
                </p>
            )
        case 'AppointmentVehicleDroppedOffStepCanceled':
            return (
                <p>
                    {t('events.vehicle.appointmentVehicleDroppedOffStepCanceled', {
                        id: event.RentalNumber,
                        name: event.User.Name,
                    })}
                </p>
            )
        case 'AppointmentVehicleDropOffStep':
            return (
                <div>
                    <p>
                        <b>{t('events.vehicle.appointmentVehicleDropOffStep', { name: event.User.Name })}</b>
                    </p>
                    <div className="row mt-3">
                        <Button onClick={() => cancel()} type="text" className="mr-4">
                            {t('common.cancel')}
                        </Button>
                        <Button disabled={resendDisabled} onClick={() => sendEmail('dropoff')} type="primary">
                            {t('common.resendEmail')}
                            {countdown !== 0 && (
                                <Countdown
                                    date={Date.now() + countdown * 1000}
                                    renderer={({ seconds }) => <span className="ml-2">({seconds})</span>}
                                    onComplete={() => {
                                        setCountdown(0)
                                        setResendDisabled(false)
                                    }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )
        case 'AppointmentVehicleDroppedOffStep':
            return <p>{t('events.vehicle.appointmentVehicleDroppedOffStep', { name: event.User.Name })}</p>
        default:
            return <div />
    }
}
