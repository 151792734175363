import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useSt } from 'state/state'
import { User } from 'state/user'
import { DropOffData, DropOffSchema, PickUpSchema, PickUpData } from 'types/event'
import { CreateReservationType, ReservationDropOff, ReservationPickUp, ReservationSchema } from 'types/reservation'
import { renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import ObjectModal from './modals/ObjectModal'
import DropOffModal from './modals/DropOffModal'
import InvoiceModal from './modals/InvoiceModal'
import VehiclesModal from './modals/VehiclesModal'
import ReservationsDateModal from './modals/ReservationsDateModal'
import Dropdown from './Dropdown'
import { v4 as uuid } from 'uuid'

type ClientDropdownParams = {
    user: User
}

const ClientDropdown: FC<ClientDropdownParams> = ({ user }) => {
    const st = useSt()
    const { t } = useTranslation()

    const exchangeItems = [
        {
            text: t('newAction.type.dropOff'),
            onClick: () =>
                st.addModalForm({
                    schema: DropOffSchema,
                    steps: [
                        {
                            comp: <ObjectModal items={user.PersonalItems} />,
                            alternativeComp: <ObjectModal withHistoryLink />,
                            title: 'objectModal.selectTitle',
                            description: 'objectModal.selectDescription',
                            icon: renderIcon({
                                name: 'key',
                                circleType: 'primary',
                            }),
                            size: 'sm',
                        },
                        {
                            comp: <DropOffModal />,
                            title: 'dropOffModal.title',
                            description: 'dropOffModal.description',
                            icon: renderIcon({
                                name: 'calendar',
                                circleType: 'primary',
                            }),
                            size: 'sm',
                        },
                    ],
                    submit: (data: DropOffData) => submitDropoff(data),
                }),
        },
        {
            text: t('newAction.type.planFree'),
            onClick: () =>
                st.addModalForm({
                    schema: PickUpSchema,
                    steps: [
                        {
                            comp: <ObjectModal items={user.PersonalItems} />,
                            title: 'objectModal.selectTitle',
                            description: 'objectModal.selectDescription',
                            icon: renderIcon({
                                name: 'key',
                                circleType: 'primary',
                            }),
                            size: 'sm',
                        },
                        {
                            comp: <InvoiceModal isFreePlan />,
                            title: 'invoiceModal.title',
                            description: 'invoiceModal.description',
                        },
                    ],
                    submit: (data: PickUpData) => submitPickup(data, true),
                }),
        },
        {
            text: t('newAction.type.planPaid'),
            onClick: () =>
                st.addModalForm({
                    schema: PickUpSchema,
                    steps: [
                        {
                            comp: <ObjectModal items={user.PersonalItems} />,
                            title: 'objectModal.selectTitle',
                            description: 'objectModal.selectDescription',
                            icon: renderIcon({
                                name: 'key',
                                circleType: 'primary',
                            }),
                            size: 'sm',
                        },
                        {
                            comp: <InvoiceModal />,
                            title: 'invoiceModal.title',
                            description: 'invoiceModal.description',
                        },
                    ],
                    submit: (data: PickUpData) => submitPickup(data),
                }),
        },
    ]
    const reservationItems = [
        {
            text: t('newAction.type.reservation'),
            onClick: () =>
                st.addModalForm({
                    schema: ReservationSchema,
                    steps: [
                        {
                            comp: <VehiclesModal />,
                            title: t('vehiclesModal.title'),
                            description: t('vehiclesModal.description'),
                            icon: renderIcon({ name: 'car', circleType: 'primary' }),
                        },
                        {
                            comp: <ReservationsDateModal />,
                            title: t('reservationsDateModal.title'),
                            description: t('reservationsDateModal.description'),
                            icon: renderIcon({ name: 'calendar', circleType: 'primary' }),
                            size: 'sm',
                        },
                    ],
                    submit: async (data: CreateReservationType) => {
                        const uniqueId = uuid()

                        console.log('>>>> rental id : ', uniqueId)
                        const dropOffData: ReservationDropOff = {
                            User: {
                                Id: user.getID,
                                Name: user.fullName,
                                EmailAddress: user.Email,
                                PreferedLanguage: user.PreferedLanguage,
                            },
                            RentalId: data.RentalId,
                            Id: uniqueId,
                            VehicleId: data.VehicleId,
                            DropOffDate: dayjs(data.DropOffDate).toJSON() || '',
                        }
                        const pickUpData: ReservationPickUp = {
                            User: {
                                Id: user.getID,
                                Name: user.fullName,
                                EmailAddress: user.Email,
                                PreferedLanguage: user.PreferedLanguage,
                            },
                            RentalId: data.RentalId,
                            Id: uniqueId,
                            VehicleId: data.VehicleId,
                            PickUpDate: dayjs(data.PickUpDate).toJSON() || '',
                        }

                        // Create reservation
                        if (data.DropOffDate && data.PickUpDate) {
                            const createDropOff = await st.http.createReservationDropoff(dropOffData)
                            if (createDropOff.status >= 200 && createDropOff.status <= 204) {
                                const createPickup = await st.http.createReservationPickup(pickUpData)

                                displayNotif(t, createPickup.status, { key: 'feedback.confirmAction' })
                            }
                        } else if (data.DropOffDate) {
                            const createDropOff = await st.http.createReservationDropoff(dropOffData)
                            displayNotif(t, createDropOff.status, { key: 'feedback.confirmAction' })
                        } else if (data.PickUpDate) {
                            const createPickup = await st.http.createReservationPickup(pickUpData)
                            displayNotif(t, createPickup.status, { key: 'feedback.confirmAction' })
                        }
                    },
                }),
        },
    ]

    const submitDropoff = async (data: DropOffData) => {
        const dropOffReq = async () => {
            const dataSubmit: any = {
                Object: {
                    Id: data.PersonalItemName || data.SelectedObject,
                },
                AgencyId : data.AgencyId,
                DropOffUser: {
                    Id: user.Id,
                    Name: user.fullName,
                    EmailAddress: user.Email,
                    PreferedLanguage: user.PreferedLanguage,
                },
                DropOffDate: dayjs(data.DropOffDate).toJSON(),
                Locker: data.DropOffLocker,
            }

            const reqDropoff = await st.http.submitDropoff(dataSubmit)
            displayNotif(t, reqDropoff.status, { key: 'feedback.planDropOff' })
        }

        if (data.PersonalItemName) {
            const createdObject: any = await st.http.createObject(user.getID, data.PersonalItemName)
            displayNotif(t, createdObject.status, { key: 'feedback.objectCreated' })
            st.currentObject = data.PersonalItemName

            !createdObject && dropOffReq()
        } else {
            dropOffReq()
        }
    }
    const submitPickup = async (data: PickUpData, freePlan?: boolean) => {
        const dataSubmit: any = {
            Object: {
                Id: data.SelectedObject,
            },
            PickUpUser: {
                Id: user.Id,
                Name: user.fullName,
                EmailAddress: user.Email,
                PreferedLanguage: user.PreferedLanguage,
            },
            PickUpDate: dayjs(data.PickUpDate).toJSON(),
            Locker: data.PickUpLocker,
        }
        const reqPickup = await st.http.submitPickup(dataSubmit, freePlan)

        if (!freePlan) {
            const reqInvoice = await st.http.attachPDF(reqPickup.data.Id, data.Amount, data.Invoice)
            displayNotif(t, reqInvoice.status, { key: 'feedback.planReturn' })
        } else {
            displayNotif(t, reqPickup.status, { key: 'feedback.planReturn' })
        }
    }
    const renderItems = () => {
        const items: { text: string; onClick: () => void }[] = []
        if (st.claims.includes('reservations-manager')) items.push(...reservationItems)
        if (st.claims.includes('exchanges-manager')) items.push(...exchangeItems)

        return items
    }

    if (renderItems().length === 0) return null
    return <Dropdown button={{ type: 'primary', text: t('clientPage.addAction') }} items={renderItems()} />
}
export default ClientDropdown
