import { ReactNode, useEffect, useState, FC } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useAuth0 } from '@auth0/auth0-react'
import { useSt } from 'state/state'
import ProtectedRoute from 'auth/ProtectedRoute'
import Page404 from 'pages/Page404'
import ClientPage from 'pages/ClientPage'
import ListClients from 'pages/ListClients'
import ParametersPage from 'pages/ParametersPage'
import AgencyPage from 'pages/AgencyPage'
import ListReservations from 'pages/ListReservations'
import ReservationPage from 'pages/ReservationPage'
import ListVehicles from 'pages/ListVehicles'
import VehiclePage from 'pages/VehiclePage'
import ListExchanges from 'pages/ListExchanges'
import Loader from 'components/Loader'

export const ProtectedAppRoutes: FC = observer(() => {
    const st = useSt()
    const [token, setToken] = useState('')
    const { getIdTokenClaims, getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

    const routes: { path: string; component: ReactNode; permissions?: string[]; exact?: boolean }[] = [
        {
            path: '/parameters',
            component: <ParametersPage />,
            permissions: ['customer-admin'],
        },
        {
            path: '/agency/:agencyId',
            component: <AgencyPage />,
        },
        {
            path: '/exchanges',
            component: <ListExchanges />,
            permissions: ['exchanges-manager'],
        },
        {
            path: '/reservations',
            component: <ListReservations />,
            permissions: ['reservations-manager'],
        },
        {
            path: '/reservation/:reservationId',
            component: <ReservationPage />,
            permissions: ['reservations-manager'],
        },
        {
            path: '/vehicles',
            component: <ListVehicles />,
            permissions: ['vehicles-manager'],
        },
        {
            path: '/vehicle/:vehicleId',
            component: <VehiclePage />,
            permissions: ['vehicles-manager'],
        },
        {
            path: '/clients',
            component: <ListClients />,
            permissions: ['clients-manager'],
        },
        {
            path: '/client/:clientId',
            component: <ClientPage />,
            permissions: ['clients-manager'],
        },
    ]

    let redirectTo = '/exchanges'

    useEffect(() => {
        const getClaims = async () => {
            if (!isLoading && !isAuthenticated) return

            const tk = await getAccessTokenSilently()
            setToken(tk)

            const idTokenClaims = await getIdTokenClaims()
            if (idTokenClaims == null) return
            st.claims = idTokenClaims['http://locker-engine/identity/claims/role']
        }
        getClaims()
    }, [])

    if (isLoading) return <Loader />
    if (!isAuthenticated) loginWithRedirect()
    if (!token || st.claims.length === 0) return <Loader />

    localStorage.setItem('access_token', token)
    if (!st.claims.includes('exchanges-manager')) redirectTo = '/reservations'

    return (
        <Switch>
            {routes.map((route, i) => (
                <ProtectedRoute
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    component={() => (
                        <>
                            {!route.permissions || route.permissions.every((element) => st.claims.includes(element)) ? (
                                route.component
                            ) : (
                                <Page404 />
                            )}
                        </>
                    )}
                ></ProtectedRoute>
            ))}
            <ProtectedRoute exact path="/" component={() => <Redirect to={redirectTo} />}></ProtectedRoute>
            <Route path="*" component={Page404} />
        </Switch>
    )
})
