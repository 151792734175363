import { FC } from 'react'
import { Icon } from 'helpers/icons'
import Button, { ButtonType } from 'antd/lib/button'
import AntDropdown from 'antd/lib/dropdown'

type DropdownParams = {
    items: {
        text: string
        onClick: () => void
    }[]
    button: {
        type: ButtonType
        text: string
    }
}

const Dropdown: FC<DropdownParams> = ({ items, button }) => {
    return (
        <AntDropdown
            overlay={
                <div className="ant-dropdown-content">
                    {items.map((item, i) => (
                        <div className="ant-dropdown-content-item" key={i} onClick={item.onClick}>
                            <Button type="link">{item.text}</Button>
                        </div>
                    ))}
                </div>
            }
            trigger={['click']}
            placement="bottomRight"
        >
            <Button type={button.type}>
                {button.text} <Icon name="arrowDown" position="right" />
            </Button>
        </AntDropdown>
    )
}
export default Dropdown
