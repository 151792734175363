import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useModalForm } from 'state/modalForm'
import { Icon, renderIcon } from 'helpers/icons'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Dragger from 'antd/lib/upload/Dragger'
import Progress from 'antd/lib/progress'
import DatePicker from 'antd/lib/date-picker'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import localeEn from 'antd/es/date-picker/locale/en_US'
import Select from 'antd/lib/select'

type InvoiceModalParams = {
    isFreePlan?: boolean
}

const InvoiceModal: FC<InvoiceModalParams> = observer(({ isFreePlan }) => {
    const { t, i18n } = useTranslation()
    const modalForm = useModalForm()
    const locale = i18n.language === 'en' ? localeEn : localeFr

    return (
        <Fragment>
            <div className="form-body">
                {!isFreePlan && (
                    <Fragment>
                        <Dragger
                            id="invoice-file"
                            maxCount={1}
                            customRequest={(options) =>
                                setTimeout(() => {
                                    if (options.onSuccess) {
                                        options.onSuccess({}, new XMLHttpRequest())
                                    }
                                }, 1000)
                            }
                            itemRender={(node, file, fileList, actions) => (
                                <div className={`ant-upload-list-item ${file.status === 'done' && 'done'}`}>
                                    <Icon name="file" circleType="primary" />
                                    <div className="ant-upload-list-item-info">
                                        <p className="text-sm fw-500">{file.name}</p>
                                        {file.size && (
                                            <p className="text-sm c-gray-500">{Math.floor(file.size / 1000)} KB</p>
                                        )}
                                        <Progress percent={file.percent} format={(percent) => percent + '%'} />
                                    </div>
                                    {file.status === 'uploading' ? (
                                        <button onClick={actions.remove}>
                                            <Icon name="trash" />
                                        </button>
                                    ) : (
                                        <div className="ant-upload-success">
                                            <Icon name="check" />
                                        </div>
                                    )}
                                </div>
                            )}
                            onRemove={() => modalForm.setDataValue('Invoice', '')}
                            onChange={async (info) => {
                                if (info.file.status === 'done') {
                                    const file = info.file.originFileObj
                                    const blob = await file?.arrayBuffer().then((arrayBuffer) => {
                                        return new Blob([new Uint8Array(arrayBuffer)], { type: file?.type })
                                    })

                                    modalForm.setDataValue('Invoice', {
                                        name: info.file.name,
                                        blob,
                                    })
                                }
                            }}
                        >
                            <div className="btn-upload">
                                <Icon name="upload" circleType="secondary" />
                                <div className="row mt-3">
                                    <p>
                                        <b className="c-primary mr-1">{t('invoiceModal.import')}</b>
                                    </p>
                                    <p>{t('invoiceModal.drag')}</p>
                                </div>
                                <p className="mt-1 text-xs">{t('invoiceModal.restriction')}</p>
                            </div>
                        </Dragger>
                        <Form.Item
                            label={t('newAction.invoice.price')}
                            name="Amount"
                            rules={[{ required: !isFreePlan }]}
                            className="mt-4"
                        >
                            <Input id="invoice-price" type="number" placeholder={t('form.placeholder.price')} />
                        </Form.Item>
                    </Fragment>
                )}

                <Form.Item label={t('form.label.locker')} name="PickUpLocker" rules={[{ required: true }]}>
                    <Select
                        id="invoice-locker"
                        placeholder={t('form.placeholder.locker')}
                        suffixIcon={renderIcon({ name: 'arrowDown' })}
                    >
                        <Select.Option value="keycafe">KeyCafe</Select.Option>
                        <Select.Option value="sharebox">ShareBox</Select.Option>
                        <Select.Option value="acses">Acses</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('form.label.pickUp')}
                    name="PickUpDate"
                    rules={[{ required: true }]}
                    className="mt-4"
                >
                    <DatePicker
                        id="invoice-pickup-date"
                        locale={{
                            ...locale,
                            lang: {
                                ...locale.lang,
                                now: t('common.now'),
                                ok: t('common.confirm'),
                            },
                        }}
                        showTime
                        placeholder={t('form.placeholder.pickUpDate')}
                        suffixIcon={renderIcon({ name: 'calendar' })}
                    />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary" disabled={!isFreePlan && !modalForm.getField('Invoice')}>
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})

export default InvoiceModal
