import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VehicleType } from 'types/vehicle'
import HeaderUI from 'components/Header'
import ListInfo from 'components/ListInfo'
import PageContainer from 'components/PageContainer'
import Loader from 'components/Loader'
import TimelineVehicle from 'components/timeline/vehicle/TimelineVehicle'
import ExecuteActionModal from 'components/modals/ExecuteActionModal'
import VehicleAccessibility from 'components/VehicleAccessibility'
import { useSt } from 'state/state'
import { VehicleTimeline } from 'types/timeline'
import { renderStatusVehicle } from 'helpers/renderStatus'
import { displayNotif } from 'helpers/displayNotif'
import { renderIcon } from 'helpers/icons'

import Button from 'antd/lib/button'
import { Col, Row } from 'antd/lib/grid'
import Text from 'antd/lib/typography/Text'

const VehiclePage: FunctionComponent = observer(() => {
    const st = useSt()
    const { t } = useTranslation()
    const history = useHistory()
    const params: { vehicleId: string } = useParams()
    const [currentVehicle, setVehicle] = useState<VehicleType | null>(null)
    const [vehicleTimeline, setVehicleTimeline] = useState<VehicleTimeline[] | null>(null)
    const loadVehicle = async () => {
        const vehicle = await st.http.loadVehicle(params.vehicleId)

        setVehicle(vehicle[0])
        setVehicleTimeline(vehicle[1])
    }
    const deleteVehicle = async () => {
        st.addModal({
            Comp: (
                <ExecuteActionModal
                    onExec={() => st.http.deleteVehicle(params.vehicleId)}
                    onFinish={(status) => {
                        displayNotif(t, status, {
                            key: t('feedback.vehicle.deleted'),
                            options: { name: params.vehicleId },
                        })
                        if (status >= 200 && status <= 204) {
                            history.push('/vehicles')
                            st.http.loadAllVehicles()
                        }
                    }}
                    isDangerBtn
                />
            ),
            title: { key: 'feedback.vehicle.confirmDelete', options: { name: currentVehicle?.RegistrationNumber } },
            description: 'feedback.vehicle.descriptionDelete',
            icon: renderIcon({ name: 'trash', circleType: 'error' }),
        })
    }

    useEffect(() => {
        !currentVehicle && !vehicleTimeline && loadVehicle()
    }, [params.vehicleId, st])

    if (!currentVehicle || !vehicleTimeline) return <Loader />
    return (
        <Fragment>
            <HeaderUI />
            <PageContainer
                title={currentVehicle.RegistrationNumber || ''}
                subtitle={
                    <Text type="secondary">
                        {currentVehicle.Brand}, {currentVehicle.Model}
                    </Text>
                }
                suffixTitle={renderStatusVehicle(t, currentVehicle.Status, true)}
                tabs={[
                    {
                        key: 'timeline',
                        name: t('vehiclePage.timeline'),
                        render: <TimelineVehicle timeline={vehicleTimeline} />,
                        button: (
                            <VehicleAccessibility
                                vehicleId={currentVehicle.Id}
                                status={currentVehicle.Status}
                                refreshVehicle={loadVehicle}
                            />
                        ),
                    },
                    {
                        key: 'vehicle',
                        name: t('vehiclePage.vehicle'),
                        render: (
                            <Row justify="space-between">
                                <Col span={15}>
                                    <ListInfo
                                        data={{
                                            vehicleId: currentVehicle.Id,
                                            brand: currentVehicle.Brand,
                                            model: currentVehicle.Model,
                                            registrationNumber: currentVehicle.RegistrationNumber,
                                        }}
                                        title={t('common.generalInformations')}
                                    />
                                </Col>
                            </Row>
                        ),
                        button: (
                            <Button className="btn-error" onClick={deleteVehicle}>
                                {t('vehiclePage.delete')}
                            </Button>
                        ),
                    },
                    // { key: 'reservation', name: t('vehiclePage.reservation'), render: <p>Tab 3</p> },
                    // { key: 'damages', name: t('vehiclePage.damages'), render: <p>Tab 4</p> },
                ]}
            />
        </Fragment>
    )
})

export default VehiclePage
