import { createContext, useContext } from 'react'
import { makeAutoObservable } from 'mobx'
import { AuthManager } from './auth'
import { User } from './user'
import { UserTimeline, VehicleTimeline } from 'types/timeline'
import { ExchangeData } from 'types/exchange'
import { VehicleType } from 'types/vehicle'
import { ModalType, ModalFormType } from 'types/modals'
import { GlobalHttp } from 'api/global-http'
import { Employee } from './employee'
import { Agency } from './agency'
import { GridData } from 'types/grid'

export class State {
    http = new GlobalHttp(this)
    auth = new AuthManager(this)
    exchanges: GridData<ExchangeData> | null = null
    clients: GridData<User> | null = null
    vehicles: GridData<VehicleType> | null = null
    currentUser: User | null = null
    currentTimeline: UserTimeline[] | null = null
    currentVehicleTimeline: VehicleTimeline[] | null = null
    currentObject = ''
    searchValue = ''
    windowWidth = window.innerWidth
    agency = new Agency(this)
    employees = new Employee(this)
    claims: string[] = []
    lang = 'en'

    constructor() {
        makeAutoObservable(this)

        window.addEventListener('resize', () => (this.windowWidth = window.innerWidth))
    }

    modal: ModalType | null = null

    addModal = ({ Comp, title, description, className, icon }: ModalType) => {
        this.modal = {
            Comp,
            title,
            description,
            className,
            icon,
        }
    }

    modalForm: ModalFormType | null = null

    addModalForm = ({ schema, steps, submit, className }: ModalFormType) => {
        this.modalForm = {
            schema,
            steps,
            submit,
            className,
        }
    }
}

export const StateContext = createContext<State | null>(null)

export const useSt = (): State => {
    const val = useContext(StateContext)
    if (val == null) throw new Error('forgot to add ctx provider ?')
    return val
}
