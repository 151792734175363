import { Fragment, FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { User } from 'state/user'
import { useModalForm } from 'state/modalForm'
import { Icon, renderIcon } from 'helpers/icons'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'

type ClientModalParams = {
    editInfo?: User
    selectClient?: boolean
}

const ClientModal: FunctionComponent<ClientModalParams> = observer(({ editInfo, selectClient }) => {
    const { t, i18n } = useTranslation()
    const modalForm = useModalForm()

    useEffect(() => {
        if (!editInfo) {
            modalForm.fillValues({
                PreferedLanguage: i18n.language === 'fr' ? 'fr-FR' : 'en-EN',
            })
        } else {
            modalForm.fillValues(editInfo)
        }

        return () => modalForm.reset()
    }, [])

    return (
        <Fragment>
            <div className="form-body">
                <Form.Item label={t('form.label.Firstname')} name="Firstname" rules={[{ required: true }]}>
                    <Input id="client-firstname" type="text" placeholder={t(`form.placeholder.Firstname`)} />
                </Form.Item>
                <Form.Item label={t('form.label.Lastname')} name="Lastname" rules={[{ required: true }]}>
                    <Input id="client-lastname" type="text" placeholder={t(`form.placeholder.Lastname`)} />
                </Form.Item>
                <Form.Item
                    label={t('form.label.Email')}
                    name="Email"
                    rules={[{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }]}
                >
                    <Input
                        id="client-email"
                        type="email"
                        placeholder={t(`form.placeholder.Email`)}
                        disabled={!!editInfo}
                    />
                </Form.Item>
                <Form.Item
                    label={t('form.label.PhoneNumber')}
                    name="PhoneNumber"
                    rules={[{ len: 10, pattern: /^[0-9]*$/, message: t('error.phone') }]}
                >
                    <Input
                        id="client-phone-number"
                        type="tel"
                        placeholder={t(`form.placeholder.PhoneNumber`)}
                        maxLength={10}
                    />
                </Form.Item>
                <Form.Item label={t('form.label.Address')} name="Address">
                    <Input id="client-address" type="text" placeholder={t(`form.placeholder.Address`)} />
                </Form.Item>
                <Form.Item
                    label={t('form.label.PreferedLanguage')}
                    name="PreferedLanguage"
                    rules={[{ required: true }]}
                >
                    <Select id="client-prefered-language" suffixIcon={renderIcon({ name: 'arrowDown' })}>
                        <Select.Option value="fr-FR">{t('language.french')}</Select.Option>
                        <Select.Option value="en-EN">{t('language.english')}</Select.Option>
                        <Select.Option value="it-IT">{t('language.italian')}</Select.Option>
                    </Select>
                </Form.Item>
                {selectClient && (
                    <Button
                        type="link"
                        icon={<Icon name="arrowBack" />}
                        onClick={() => (modalForm.renderAlternative = false)}
                    >
                        {t('selectClientModal.existingClient')}
                    </Button>
                )}
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary" id="client-model-next-button">
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})

export default ClientModal
