import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { renderIcon } from 'helpers/icons'

import Button from 'antd/lib/button/button'

const LogoutButton: FC = () => {
    const { t } = useTranslation()
    const { logout } = useAuth0()
    return (
        <Button
            type="ghost"
            className="btn-logout ml-4"
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
            
        >
            {t('common.logout')}
            {renderIcon({ name: 'logout', position: 'right' })}
        </Button>
    )
}

export default LogoutButton
