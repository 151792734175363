import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { tabsReservations } from 'static/reservations'
import CustomGrid from './grid/CustomGrid'
import { ReservationData } from 'types/reservation'
import { Col, Row } from 'antd/lib/grid'
import ListInfo from './ListInfo'
import Loader from './Loader'
import { clientReservationsColumns } from 'static/client'
import { delay } from 'helpers/delay'

const ClientReservations: FC = observer(() => {
    const { t } = useTranslation()
    const history = useHistory()
    const st = useSt()
    const [reservations, setReservations] = useState<ReservationData[] | null>(null)
    const [filterParams, setFilterParams] = useState({})
    const loadClientReservations = async (page?: number, pageSize?: number, params?: any) => {
        console.log('Here')

        const req: any = await st.http.loadClientReservations(
            {
                Id: st.currentUser?.getID || '',
                Name: st.currentUser?.fullName || '',
                EmailAddress: st.currentUser?.Email || '',
                PreferedLanguage: st.currentUser?.PreferedLanguage || '',
            },
            page,
            pageSize,
            params,
        )

        setReservations(req.data.Data)
    }

    useEffect(() => {
        loadClientReservations()
    }, [])

    if (!reservations) return <Loader />
    return (
        <Row justify="space-between">
            <Col span={15}>
                <CustomGrid
                    name="client-reservations"
                    dataset={{
                        Total: 1,
                        CurrentPage: 1,
                        PagesCount: 1,
                        Data: reservations,
                    }}
                    columns={clientReservationsColumns(t)}
                    tabs={tabsReservations(t)}
                    onRowClick={(reservation: ReservationData) => history.push(`/reservation/${reservation.Id}`)}
                    isSmall={{
                        title: t('clientPage.historyReservations'),
                    }}
                    onFilter={delay((params) => {
                        const { page, pageSize, ...filters } = params

                        setFilterParams(filters)
                        loadClientReservations(page, pageSize, filters)
                    }, 1000)}
                    onPageChange={(page, pageSize) => loadClientReservations(page, pageSize, filterParams)}
                />
            </Col>
            <Col span={8}>
                <ListInfo
                    title={t('common.summary')}
                    data={{
                        totalReservations: reservations.length,
                        waitingVehicleAssociation: reservations.filter(
                            (item) => item.Status === 'WaitingVehicleAssociation',
                        ).length,
                        waitingVehicleDropOff: reservations.filter((item) => item.Status === 'WaitingVehicleDropOff')
                            .length,
                        waitingVehiclePickup: reservations.filter((item) => item.Status === 'WaitingVehiclePickup')
                            .length,
                        closed: reservations.filter((item) => item.Status === 'Closed').length,
                    }}
                />
            </Col>
        </Row>
    )
})
export default ClientReservations
