import { AgencyData, AgencyRoles, AgencyType } from 'types/agency'
import { GridData } from 'types/grid'
import { notSecuredHttpPost, securedHttpGet, securedHttpPost } from './http'
import { ResponseGet, ResponsePost } from './types'

export const getAllAgencies = (page = 1): ResponseGet<GridData<AgencyData>> =>
    securedHttpGet(`/agency-get-all?page=${page}&limit=10`)

export const getAgency = (Id: string): ResponsePost<AgencyData> => securedHttpPost(`/agency-get`, { Id })

export const createAgency = (data: AgencyType): ResponsePost<AgencyData[]> => securedHttpPost(`/agency-add`, data)

export const editAgency = (data: AgencyType): ResponsePost<AgencyData> => securedHttpPost(`/agency-update`, data)

export const deleteAgency = (AgencyId: string): ResponsePost<any> => securedHttpPost(`/agency-delete`, { AgencyId })

export const getAllRoles = (): ResponseGet<AgencyRoles[]> => securedHttpGet(`agency-all-granted-roles-get`)

export const getRole = (AgencyId: string): ResponsePost<AgencyRoles[]> =>
    securedHttpPost(`agency-granted-roles-get`, { AgencyId })

export const getAgencyPortalAvailability = (TenantId: string, AgencyId: string): ResponseGet<any> =>
    notSecuredHttpPost('agency-check-portal-availability', { TenantId, AgencyId })

export const openAgencyPortal = (TenantId: string, AgencyId: string, OperationId: string): ResponsePost<any> =>
    notSecuredHttpPost('agency-open-portal', { TenantId, AgencyId, OperationId })
