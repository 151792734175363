import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'

import Modal from 'antd/lib/modal'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

const Modals: FunctionComponent = observer(() => {
    const st = useSt()
    const { t } = useTranslation()
    const modal = st.modal

    if (!modal) return null
    return (
        <Modal
            visible={true}
            footer={false}
            maskClosable={false}
            className={modal.className}
            closable={false}
            width={400}
        >
            <div className="modal-container">
                <div>
                    {modal.icon && <div className="ant-modal-icon">{modal.icon}</div>}
                    <div className="mb-2">
                        <Title level={4}>{t(modal.title.key, { ...modal.title.options })}</Title>
                    </div>
                    {modal.description && <Text type="secondary">{t(modal.description)}</Text>}
                </div>
                {modal.Comp}
            </div>
        </Modal>
    )
})

export default Modals
