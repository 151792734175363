import { makeAutoObservable } from 'mobx'
import { UserData } from 'types/user'

export class User {
    Id: string
    Firstname: string
    Lastname: string
    Email: string
    PhoneNumber: string
    Address: string
    PreferedLanguage: string
    PersonalItems: { Name: string }[];
    [key: string]: any

    constructor(data: UserData) {
        this.Id = typeof data.Id === 'string' ? data.Id : data.Id.Value
        this.Firstname = data.Firstname
        this.Lastname = data.Lastname
        this.Email = data.Email
        this.PhoneNumber = data.PhoneNumber
        this.Address = data.Address
        this.PreferedLanguage = data.PreferedLanguage
        this.PersonalItems = data.PersonalItems
        makeAutoObservable(this)
    }

    get fullName(): string {
        return `${this.Firstname} ${this.Lastname}`
    }

    get getID(): string {
        return this.Id
    }
}
