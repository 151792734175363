import { Fragment, ReactNode } from 'react'
import { TFunction } from 'react-i18next'
import { ExchangeData } from 'types/exchange'
import { renderStatusExchange } from './renderStatus'
import { renderFormatDate } from './time'

export const displayExpandedRow = (t: TFunction, exchange: ExchangeData): ReactNode => {
    return (
        <Fragment>
            <p className="mb-2">
                {t('gridExchange.columns.depositDate')}: <b>{renderFormatDate(t, exchange.DropOffDate)}</b>
            </p>
            <p className="mb-2">
                {t('gridExchange.columns.pickUpDate')}:
                <b>{exchange.PickUpDate ? renderFormatDate(t, exchange.PickUpDate) : '-'}</b>
            </p>
            <p>
                {t('gridExchange.columns.status')}: {renderStatusExchange(t, exchange.Status)}
            </p>
        </Fragment>
    )
}
