import axios from 'axios'
import i18n from 'i18n/config'
import { API_URL, securedHttpGet, securedHttpPost } from './http'
import { PlanDropoff, ResponseGet, ResponsePost } from './types'
import { ExchangeData } from 'types/exchange'
import { PickUpData } from 'types/event'
import { GridData } from 'types/grid'

export const getAllGarages = (
    page = 1,
    limit = 10,
    params?: { [key: string]: any },
): ResponseGet<GridData<ExchangeData>> =>
    securedHttpGet(`/garage-operation-get-all?page=${page}&limit=${limit}`, params || {})

export const getGarage = (OperationId: string): ResponsePost<ExchangeData> =>
    securedHttpPost(`/garage-operation-get`, { OperationId })
// DROP OFF
export const garagePlanDropoff = (data: PlanDropoff): ResponsePost<null> =>
    securedHttpPost('/garage-operation-plan-user-dropoff', data)

export const garageStartDropOff = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-dropoff-start', { OperationId })

export const garageExecDropOff = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-dropoff-execute', { OperationId })
// GARAGE RETURN
export const garageFreeReturn = (data: PickUpData): ResponsePost<ExchangeData> =>
    securedHttpPost('/garage-operation-plan-free-return', data)

export const garagePaidReturn = (data: PickUpData): ResponsePost<ExchangeData> =>
    securedHttpPost('/garage-operation-plan-paid-return', data)

export const garageSendInvoice = (data: FormData): ResponsePost<null> =>
    securedHttpPost('/garage-operation-invoice-append', data)

export const garageStartPickup = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-pickup-start', { OperationId })

export const garageExecPickup = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-pickup-execute', { OperationId })

export const garageCancelDropoff = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-dropoff-cancel', { OperationId })

export const garageCancelPickup = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/garage-pickup-cancel', { OperationId })

export const sendDropoffEmail = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/send-dropoff-email', { OperationId })

export const sendPickupEmail = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/send-pickup-email', { OperationId })

export const sendPaymentEmail = (OperationId: string): ResponsePost<null> =>
    securedHttpPost('/send-payment-email', { OperationId })

export const downloadInvoice = (OperationId: string): ResponsePost<null> =>
    axios
        .post(
            `/download-invoice`,
            { OperationId },
            {
                baseURL: API_URL,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'Accept-Language': i18n.language,
                    'X-Timezone-Id': Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                responseType: 'arraybuffer',
            },
        )
        .then((response) => {
            const blob = new Blob([response.data])
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Invoice.pdf')
            document.body.appendChild(link)
            link.click()
        })
        .catch((err) => err)
