// src/auth/protected-route.js

import { ComponentType, FC } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Loader } from 'components/Loader'

type RouteParams = {
    component: ComponentType
}

const ProtectedRoute: FC<RouteProps & RouteParams> = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loader />,
        })}
        {...args}
    />
)

export default ProtectedRoute
