import { TFunction } from 'i18next'
import { ColumnsType } from 'antd/lib/table/interface'
import { AgencyData } from 'types/agency'

export const agencyColumns = (t: TFunction): ColumnsType<AgencyData> => [
    {
        title: t('gridAgencies.columns.name'),
        dataIndex: 'Name',
        key: 'Name',
        render: (user) => <b>{user}</b>,
    },
    {
        title: t('gridAgencies.columns.address'),
        dataIndex: 'Address',
        key: 'Address',
        render: (address) => (address ? address : t('common.notSpecified')),
    },
    {
        title: t('gridAgencies.columns.phoneNumber'),
        dataIndex: 'PhoneNumber',
        key: 'PhoneNumber',
        render: (phoneNumber) => (phoneNumber ? phoneNumber : t('common.notSpecified')),
    },
]
