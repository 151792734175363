import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomGrid from 'components/grid/CustomGrid'
import ListInfo from 'components/ListInfo'
import { employeesColumns } from 'static/employee'
import { useSt } from 'state/state'
import { Col, Row } from 'antd/lib/grid'

type AgencyEmployeesParams = {
    agencyId: string
}

const AgencyEmployeesView: FC<AgencyEmployeesParams> = observer(({ agencyId }) => {
    const st = useSt()
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        st.employees.loadAll(agencyId)

        return () => {
            st.employees.all = null
        }
    }, [])

    return (
        <Row justify="space-between">
            <Col span={15}>
                <CustomGrid
                    name="agency-employees"
                    dataset={st.employees.all}
                    columns={employeesColumns(t)}
                    onRowClick={(employee) => history.push(`/employee/${employee.EmployeeAccountId.Value}`)}
                    isSmall={{
                        title: t('gridEmployees.title'),
                        withSearch: true,
                    }}
                    onPageChange={(page) => st.employees.loadAll(agencyId, page)}
                />
            </Col>
            <Col span={8}>
                <ListInfo
                    title={t('gridEmployees.summary')}
                    data={{
                        totalEmployees: st.employees.all?.Data.length || 0,
                    }}
                />
            </Col>
        </Row>
    )
})
export default AgencyEmployeesView
