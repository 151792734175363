import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { AgencyData, AgencyType } from 'types/agency'
import { useSt } from 'state/state'
import { displayNotif } from 'helpers/displayNotif'
import { renderIcon } from 'helpers/icons'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import { useForm } from 'antd/lib/form/Form'

type CreateAgencyParams = {
    editInfo?: AgencyData
}

const CreateAgencyModal: FC<CreateAgencyParams> = observer(({ editInfo }) => {
    const st = useSt()
    const { t } = useTranslation()
    const [antdForm] = useForm()
    const [isLoading, setLoading] = useState(false)
    const [rolesClass, setRolesClass] = useState('')
    const isWindows = window.navigator.userAgent.includes('Win')
    const submitForm = async (data: AgencyType) => {
        setLoading(true)
        if (editInfo) data.Id = editInfo?.AgencyId.Value

        const reqAgency = editInfo
            ? await st.http.editAgency({ ...data, GrantedRoles: st.agency.getRolesObject(data) })
            : await st.http.createAgency({ ...data, GrantedRoles: st.agency.getRolesObject(data) })
        if (reqAgency.status >= 200 || reqAgency.status >= 204) {
            if (editInfo) {
                st.agency.load(editInfo.AgencyId.Value)
            }

            st.agency.loadAll()
            st.modal = null
        }

        displayNotif(
            t,
            reqAgency.status,
            {
                key: editInfo ? 'feedback.agency.modified' : 'feedback.agency.created',
                options: { name: editInfo?.Name },
            },
            undefined,
            reqAgency.request.response || 'feedback.error',
        )
        setLoading(false)
    }

    useEffect(() => {
        !st.agency.roles && st.agency.loadRoles()
        if (antdForm.getFieldValue('GrantedRoles')?.length >= 3 && isWindows) setRolesClass('mb-4')

        return () => {
            setLoading(false)
        }
    }, [])

    return (
        <Form initialValues={editInfo} form={antdForm} onFinish={(values) => submitForm(values)}>
            <div className="form-body">
                <Form.Item label={t('form.label.Name')} name="Name" rules={[{ required: true }]}>
                    <Input id="agency-name" type="text" placeholder={t(`form.placeholder.agencyName`)} />
                </Form.Item>
                <Form.Item
                    label={t('form.label.Email')}
                    name="Email"
                    rules={[{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }]}
                >
                    <Input
                        id="agency-email"
                        type="text"
                        placeholder={t(`form.placeholder.Email`)}
                        disabled={Boolean(editInfo)}
                    />
                </Form.Item>
                <Form.Item label={t('form.label.Roles')} name="GrantedRoles" rules={[{ required: true }]}>
                    <Select
                        id="agency-roles"
                        mode="multiple"
                        placeholder={t('form.placeholder.Roles')}
                        loading={!st.agency.roles}
                        removeIcon={renderIcon({ name: 'close' })}
                        className={rolesClass}
                        onChange={() =>
                            setRolesClass(
                                antdForm.getFieldValue('GrantedRoles')?.length >= 3 && isWindows ? 'mb-4' : '',
                            )
                        }
                    >
                        {st.agency.roles?.map((role) => (
                            <Select.Option value={role.RoleId} key={role.RoleId}>
                                {role.RoleName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={t('form.label.fullAddress')} name="Address">
                    <Input id="agency-full-address" type="text" placeholder={t(`form.placeholder.fullAddress`)} />
                </Form.Item>
                <Form.Item
                    label={t('form.label.PhoneNumber')}
                    name="PhoneNumber"
                    rules={[{ len: 10, pattern: /^[0-9]*$/, message: t('error.phone') }]}
                >
                    <Input type="text" id="agency-phone-number" placeholder={t(`form.placeholder.PhoneNumber`)} />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={() => (st.modal = null)}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                    {t('common.confirm')}
                </Button>
            </div>
        </Form>
    )
})
export default CreateAgencyModal
