import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { renderDate } from 'helpers/time'
import { Icon } from 'helpers/icons'
import ListInfo from 'components/ListInfo'
import CreateAgencyModal from 'components/modals/CreateAgencyModal'
import { Col, Row } from 'antd/lib/grid'
import Loader from 'components/Loader'

const AgencyGeneralView: FC = observer(() => {
    const st = useSt()
    const { t } = useTranslation()
    const currentData = st.agency.currentByIds

    if (!currentData) return <Loader />
    return (
        <Row justify="space-between">
            <Col span={15}>
                <ListInfo
                    data={{
                        Lastname: currentData.Name,
                        Address: currentData.Address || t('common.notSpecified'),
                        PhoneNumber: currentData.PhoneNumber || t('common.notSpecified'),
                        Email: currentData.Email,
                        roles: st.agency.rolesName,
                    }}
                    title={t('common.generalInformations')}
                    onEdit={() => {
                        st.addModal({
                            Comp: <CreateAgencyModal editInfo={currentData} />,
                            title: { key: 'agencyModal.modifyInfo' },
                            icon: <Icon name="userPlus" circleType="primary" />,
                        })
                    }}
                />
            </Col>
            <Col span={8}>
                <ListInfo
                    title={t('common.details')}
                    data={{
                        garageID: currentData.AgencyId.TenantId,
                        lastModified: renderDate(currentData.UpdatedOn),
                    }}
                />
                <div className="mb-8"></div>
                <ListInfo
                    title={t('common.registration')}
                    data={{
                        createdAt: renderDate(currentData.CreatedOn),
                    }}
                />
            </Col>
        </Row>
    )
})
export default AgencyGeneralView
