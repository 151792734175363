import notification from 'antd/lib/notification'
import { TFunction, TOptions } from 'i18next'
import { renderIcon } from './icons'

export const displayNotif = (
    t: TFunction,
    status: number,
    text: {
        key: string
        options?: TOptions
    },
    description?: string,
    err = 'feedback.error',
): void => {
    if (status >= 200 && status <= 204) {
        return notification.success({
            message: t(text.key, { ...text.options }),
            description: description ? t(description) : undefined,
            icon: renderIcon({ name: 'checkCircle' }),
            closeIcon: renderIcon({ name: 'close' }),
        })
    } else {
        return notification.error({
            message: t(err),
            icon: renderIcon({ name: 'alert' }),
            closeIcon: renderIcon({ name: 'close' }),
        })
    }
}

export const displayError = (t: TFunction, text: string): void => {
    return notification.error({
        message: t(text),
        icon: renderIcon({ name: 'alert' }),
        closeIcon: renderIcon({ name: 'close' }),
    })
}
