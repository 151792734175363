import * as yup from 'yup'

export type UserType = {
    Firstname: string
    Lastname: string
    Email: string
    PhoneNumber?: string
    Address?: string
    PersonalItemName?: string
    PreferedLanguage: string
    [key: string]: string | undefined
}

export type UserData = {
    Id: string | { Value: string }
    Firstname: string
    Lastname: string
    Email: string
    PhoneNumber: string
    Address: string
    PreferedLanguage: string
    PersonalItems: {
        Name: string
    }[]
}

export const UserSchema = yup.object().shape({
    Firstname: yup.string().required(),
    Lastname: yup.string().required(),
    Email: yup.string().required(),
    PhoneNumber: yup.string(),
    Address: yup.string(),
    PersonalItemName: yup.string(),
    PreferedLanguage: yup.string().required(),
})

export type UserPickUp = { Id: string; Name: string }

export type UserPostType = {
    Id: string
    Name: string
    EmailAddress: string
    PreferedLanguage: string
}

export type UserStaticPages = { lang: string; token:string }
