import * as yup from 'yup'
import { UserPostType } from './user'
import { VehicleType } from './vehicle'

export type ReservationData = {
    Id: string
    Number:string
    StartDate: string | null
    EndDate: string | null
    User: UserPostType
    Vehicle: VehicleType
    Status: 'WaitingVehicleAssociation' | 'WaitingVehiclePickup' | 'WaitingVehicleDropOff' | 'Closed'|'Canceled'
}

export type AppointmentData = {
    Id: string
    Vehicle: {
        Id: string
        RegistrationNumber: string
        Brand: string
        Model: string
        Status: string
    }
    Date: string
    User: UserPostType
    RentalAgency: {
        Id: string
        Name: string
    }
}

export const ReservationSchema = yup.object().shape({
    User: yup.object().shape({
        Id: yup.string(),
        Name: yup.string(),
        EmailAddress: yup.string(),
        PreferedLanguage: yup.string(),
    }),
    DropOffDate: yup.string(),
    PickUpDate: yup.string(),
    VehicleId: yup.string(),
    Firstname: yup.string(),
    Lastname: yup.string(),
    Email: yup.string(),
    PhoneNumber: yup.string(),
    Address: yup.string(),
    Number:yup.string()
})

export type ReservationType = yup.InferType<typeof ReservationSchema>

export type CreateReservationType = {
    User: {
        Id: string
        Name: string
        EmailAddress: string
        PreferedLanguage: string
    }
    SelectedUser?: string
    Firstname?: string
    Lastname?: string
    Email?: string
    PhoneNumber?: string
    Address?: string
    RentalId: string
    Id: string
    DropOffDate?: string
    PickUpDate?: string
    VehicleId: string
    PreferedLanguage: string
}

export type ReservationDropOff = {
    User: UserPostType
    RentalId: string
    Id:string //unique id for db
    DropOffDate: string
    VehicleId: string
}

export type ReservationPickUp = {
    User: UserPostType
    RentalId: string
    Id:string//unique id for db
    PickUpDate: string
    VehicleId: string
}
