import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'

import Modal from 'antd/lib/modal'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Form, { FormInstance } from 'antd/lib/form'
import { ModalFormContext, ModalFormState } from 'state/modalForm'

const ModalForm: FC = observer(() => {
    const st = useSt()
    const form = st.modalForm
    const [antdForm] = Form.useForm()

    if (!form) return null
    const formState = new ModalFormState(st, form.schema, form.steps || [], form.submit, antdForm)

    return (
        <ModalFormContext.Provider value={formState}>
            <Modal
                visible={true}
                footer={false}
                maskClosable={false}
                closable={false}
                onCancel={formState.close}
                className={form.className}
                width="100%"
            >
                <ModalFormBody antdForm={antdForm} formState={formState} />
            </Modal>
        </ModalFormContext.Provider>
    )
})
export default ModalForm

const ModalFormBody: FC<{ antdForm: FormInstance; formState: ModalFormState }> = observer(({ antdForm, formState }) => {
    const { t } = useTranslation()

    return (
        <div className="modal-container" style={{ width: formState.modalSize }}>
            <div>
                {formState.current.icon && <div className="ant-modal-icon">{formState.current.icon}</div>}
                <div className="mb-2">
                    <Title level={4}>{t(formState.current.title)}</Title>
                </div>
                <Text type="secondary">{t(formState.current.description || '')}</Text>
            </div>
            <Form
                className="form"
                form={antdForm}
                onFinish={(data) => formState.nextStep(data)}
                validateTrigger="onBlur"
            >
                {formState.renderAlternative ? formState.current.alternativeComp : formState.current.comp}
            </Form>
        </div>
    )
})
