import { TFunction } from 'i18next'
import Switch from 'antd/lib/switch'
import { ColumnsType } from 'antd/lib/table'
import { LockerType } from 'types/locker'
import { GridData } from 'types/grid'

export const fakeLockers: GridData<LockerType> = {
    PagesCount: 1,
    Total: 1,
    CurrentPage: 1,
    Data: [
        {
            Id: 0,
            Name: 'EFI18',
            Agency: 'Gare de Toulon',
            Address: "Gare Sncf, Place De L'Europe, Retours Parking Effia - 1, 83000 Toulon",
            OpeningHours: '00:00 - 23:59',
            isOpen: true,
        },
        // {
        //     Id: 1,
        //     Name: 'ILYAN',
        //     Agency: 'GGE Mercedes-Viry',
        //     Address: '11 Rue de Cambrai, 75019 Paris, FRANCE',
        //     OpeningHours: '00:00 - 23:59',
        //     isOpen: false,
        // },
        // {
        //     Id: 2,
        //     Name: 'LYLIAN',
        //     Agency: 'GGE Mercedes-Viry',
        //     Address: '11 Rue de Cambrai, 75019 Paris, FRANCE',
        //     OpeningHours: '00:00 - 23:59',
        //     isOpen: false,
        // },
        // {
        //     Id: 3,
        //     Name: 'JULES',
        //     Agency: 'GGE Mercedes-Viry',
        //     Address: '11 Rue de Cambrai, 75019 Paris, FRANCE',
        //     OpeningHours: '00:00 - 23:59',
        //     isOpen: false,
        // },
    ],
}

export const lockersColumns = (t: TFunction, onChangeLocker: () => void): ColumnsType<LockerType> => [
    {
        title: t('gridLocker.columns.name'),
        dataIndex: 'Name',
        key: 'Name',
        render: (user, data) => (
            <div>
                <Switch checked={data.isOpen} onChange={onChangeLocker} />
                <b className="ml-2">{user}</b>
            </div>
        ),
    },
    {
        title: t('gridLocker.columns.agency'),
        dataIndex: 'Agency',
        key: 'Agency',
    },
    {
        title: t('gridLocker.columns.address'),
        dataIndex: 'Address',
        key: 'Address',
    },
    {
        title: t('gridLocker.columns.openingHours'),
        dataIndex: 'OpeningHours',
        key: 'OpeningHours',
    },
]
