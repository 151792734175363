import ReactDOM from 'react-dom'
import { configure } from 'mobx'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { State, StateContext } from './state/state'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import { BrowserRouter as Router } from 'react-router-dom'
configure({ enforceActions: 'never' })

const st = new State()

ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <StateContext.Provider value={st}>
                <App />
            </StateContext.Provider>
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
