import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import { ReservationData } from 'types/reservation'
import { renderFormatDate } from 'helpers/time'
import { renderStatusReservations } from 'helpers/renderStatus'
import { User } from 'state/user'
import { ColumnsType } from 'antd/lib/table/interface'

export const gridClientsColumns = (t: TFunction): ColumnsType<User> => [
    {
        title: t('gridClients.columns.fullName'),
        dataIndex: 'fullName',
        key: 'fullName',
        render: (user) => <b>{user}</b>,
    },
    {
        title: t('gridClients.columns.email'),
        dataIndex: 'Email',
        key: 'Email',
    },
    {
        title: t('gridClients.columns.phone'),
        dataIndex: 'PhoneNumber',
        key: 'PhoneNumber',
        render: (phone) => (phone ? phone : t('common.notSpecified')),
    },
]

export const clientReservationsColumns = (t: TFunction): ColumnsType<ReservationData> => [
    {
        title: t('gridClients.columns.id'),
        dataIndex: 'Number',
        key: 'Number',
        sorter: (a: ReservationData, b: ReservationData): number => {
            const idA = dayjs(a.Number).valueOf()
            const idB = dayjs(b.Number).valueOf()
            return idA - idB
        },
        render: (user) => <b>{user}</b>,
    },
    {
        title: t('gridClients.columns.registrationNumber'),
        dataIndex: 'RegistrationNumber',
        key: 'RegistrationNumber',
        responsive: ['md'],
        render: (text, row) => <p className="c-gray-900">{row.Vehicle.RegistrationNumber}</p>,
    },
    {
        title: t('gridClients.columns.startDate'),
        dataIndex: 'StartDate',
        key: 'StartDate',
        defaultSortOrder: 'descend',
        sorter: (a: ReservationData, b: ReservationData): number => {
            const dateA = dayjs(a.StartDate).valueOf()
            const dateB = dayjs(b.StartDate).valueOf()
            return dateA - dateB
        },
        render: (text, row) => (row.StartDate ? renderFormatDate(t, row.StartDate) : t('common.noReturn')),
    },
    {
        title: t('gridClients.columns.returnDate'),
        dataIndex: 'ReturnDate',
        key: 'ReturnDate',
        responsive: ['md'],
        sorter: (a: ReservationData, b: ReservationData): number => {
            const dateA = dayjs(a.EndDate).valueOf()
            const dateB = dayjs(b.EndDate).valueOf()
            return dateA - dateB
        },
        render: (text, row) => (row.EndDate ? renderFormatDate(t, row.EndDate) : t('common.noReturn')),
    },
    {
        title: t('gridClients.columns.status'),
        dataIndex: 'Status',
        key: 'Status',
        responsive: ['md'],
        render: (status: string) => renderStatusReservations(t, status),
    },
]
