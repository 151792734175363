import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import { EmployeeData, EmployeeType } from 'types/employee'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import { useForm } from 'antd/lib/form/Form'

type CreateEmployeeParams = {
    agencyId?: string
    employeeData?: EmployeeData
}

const CreateEmployeeModal: FC<CreateEmployeeParams> = observer(({ agencyId, employeeData }) => {
    const st = useSt()
    const history = useHistory()
    const [antdForm] = useForm()
    const { t } = useTranslation()
    const [isLoading, setLoading] = useState(false)
    const [rolesClass, setRolesClass] = useState('')
    const isWindows = window.navigator.userAgent.includes('Win')
    const submitForm = async (data: EmployeeType) => {
        setLoading(true)
        if (agencyId) data.Agency = agencyId
        if (employeeData) {
            data.Id = employeeData.EmployeeAccountId.Value
            data.Agency = employeeData.Agency
        }
        // Change account roles data to agencyRoles object
        if (data.AccountRoles.length > 0) st.employees.convertRolesToObj(data)

        const reqEmployee = employeeData ? await st.http.editEmployee(data) : await st.http.createEmployee(data)

        if (reqEmployee.status >= 200 || reqEmployee.status >= 204) {
            if (employeeData) {
                st.employees.load(employeeData.EmployeeAccountId.Value)
                st.employees.loadAll(employeeData.Agency)
            } else if (agencyId) st.employees.loadAll(agencyId)
            else history.push(`/agency/${data.Agency}`)

            st.modal = null
        }

        displayNotif(
            t,
            reqEmployee.status,
            {
                key: employeeData ? 'feedback.employee.modified' : 'feedback.employee.created',
                options: { name: `${data.Firstname} ${data.Lastname}` },
            },
            undefined,
            reqEmployee.request.response || 'feedback.error',
        )
        setLoading(false)
    }

    useEffect(() => {
        if (agencyId || employeeData) st.employees.loadRoles(agencyId || employeeData?.Agency || '')
        if (antdForm.getFieldValue('AccountRoles')?.length >= 3 && isWindows) setRolesClass('mb-4')
        return () => {
            setLoading(false)
        }
    }, [])

    return (
        <Form initialValues={employeeData} form={antdForm} onFinish={(values) => submitForm(values)}>
            <div className="form-body">
                <Form.Item label={t('form.label.Firstname')} name="Firstname" rules={[{ required: true }]}>
                    <Input id="employee-firtname" type="text" placeholder={t(`form.placeholder.Firstname`)} />
                </Form.Item>
                <Form.Item label={t('form.label.Lastname')} name="Lastname" rules={[{ required: true }]}>
                    <Input id="employee-lastname" type="text" placeholder={t(`form.placeholder.Lastname`)} />
                </Form.Item>
                <Form.Item label={t('form.label.Post')} name="Post" rules={[{ required: true }]}>
                    <Input id="employee-post" type="text" placeholder={t(`form.placeholder.Post`)} />
                </Form.Item>
                {!agencyId && !employeeData && (
                    <Form.Item label={t('form.label.agency')} name="Agency" rules={[{ required: true }]}>
                        <Select
                            id="employee-agency"
                            placeholder={t('form.placeholder.agency')}
                            suffixIcon={renderIcon({ name: 'arrowDown' })}
                            onSelect={(id: string) => st.employees.loadRoles(id)}
                        >
                            {st.agency.all?.Data.map((agency) => (
                                <Select.Option key={agency.AgencyId.Value} value={agency.AgencyId.Value}>
                                    {agency.Name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    label={t('form.label.Email')}
                    name="Email"
                    rules={[{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }]}
                >
                    <Input
                        id="employee-email"
                        type="text"
                        placeholder={t(`form.placeholder.Email`)}
                        disabled={Boolean(employeeData)}
                    />
                </Form.Item>
                <Form.Item label={t('form.label.Roles')} name="AccountRoles" rules={[{ required: true }]}>
                    <Select
                        id="employee-roles"
                        mode="multiple"
                        placeholder={t('form.placeholder.Roles')}
                        removeIcon={renderIcon({ name: 'close' })}
                        className={rolesClass}
                        onChange={() =>
                            setRolesClass(
                                antdForm.getFieldValue('AccountRoles')?.length >= 3 && isWindows ? 'mb-4' : '',
                            )
                        }
                    >
                        {st.employees.roles?.map((role) => (
                            <Select.Option value={role.RoleId} key={role.RoleId}>
                                {role.RoleName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={t('form.label.PhoneNumber')} name="PhoneNumber">
                    <Input id="employee-phone-number" type="text" placeholder={t(`form.placeholder.PhoneNumber`)} />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={() => (st.modal = null)}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                    {t('common.confirm')}
                </Button>
            </div>
        </Form>
    )
})
export default CreateEmployeeModal
