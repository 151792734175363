import { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Icon } from 'helpers/icons'
import { useSt } from 'state/state'
import LogoutButton from 'components/LogoutButton'
import SwitchLang from './SwitchLang'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

type HeaderUIParams = {
    onlyLogo?: boolean
}

const HeaderUI: FunctionComponent<HeaderUIParams> = observer(({ onlyLogo }) => {
    const { user } = useAuth0()
    const { t } = useTranslation()
    const st = useSt()
    const headerClass = onlyLogo ? 'only-logo' : ''
    const logo = process.env.NODE_ENV === 'production' ? 'logoEfikeys' : 'logo'
    const claims = Object.values(st.claims)

    return (
        <header className={`header container ${headerClass}`}>
            <Row justify="space-between" align="middle">
                <Row align="middle">
                    <a href="/" className="header-logo">
                        <Icon name={logo} />
                    </a>
                    {!onlyLogo && (
                        <ul className="header-nav">
                            {claims.includes('exchanges-manager') && (
                                <li className="header-nav_item">
                                    <NavLink to="/exchanges" exact activeClassName="active">
                                        {t('header.exchanges')}
                                    </NavLink>
                                </li>
                            )}
                            {claims.includes('reservations-manager') && (
                                <li className="header-nav_item">
                                    <NavLink to={'/reservations'} exact activeClassName="active">
                                        {t('header.reservations')}
                                    </NavLink>
                                </li>
                            )}
                            {claims.includes('vehicles-manager') && (
                                <li className="header-nav_item">
                                    <NavLink to="/vehicles" activeClassName="active">
                                        {t('header.vehicles')}
                                    </NavLink>
                                </li>
                            )}
                            {claims.includes('clients-manager') && (
                                <li className="header-nav_item">
                                    <NavLink to={'/clients'} activeClassName="active">
                                        {t('header.clients')}
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    )}
                </Row>
                {!onlyLogo && (
                    <Col>
                        <Row wrap={false} align="middle">
                            <div className="header-avatar">
                                <img src={user?.picture} />
                                <p className="ml-2">{user?.name}</p>
                            </div>
                            {/* <button className="header-icon mr-1">
                                <Icon name="search" />
                            </button> */}
                            <SwitchLang />
                            {claims.includes('customer-admin') && (
                                <NavLink to="/parameters" className="header-icon" activeClassName="active">
                                    <Icon name="settings" />
                                </NavLink>
                            )}
                            <LogoutButton />
                        </Row>
                    </Col>
                )}
                {onlyLogo && <SwitchLang />}
            </Row>
        </header>
    )
})

export default HeaderUI

// function getTenantName(user: import('@auth0/auth0-react').User | undefined) {
//     const appMetaData = JSON.stringify(user, null, 2)
//     const data = JSON.parse(appMetaData)
//     const result = []
//     for (const i in data) result.push([i, data[i]])
//     return result[1][1].toString()
// }
