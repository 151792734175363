import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import CustomGrid from 'components/grid/CustomGrid'
import { fakeLockers, lockersColumns } from 'static/locker'

const LockerView: FC = () => {
    const { t } = useTranslation()
    const onChangeLocker = () => {
        console.log('OK')
    }

    return (
        <section>
            <CustomGrid
                name="locker-view"
                dataset={fakeLockers}
                columns={lockersColumns(t, onChangeLocker)}
                isSmall={{ title: t('gridLocker.title') }}
            />
        </section>
    )
}
export default LockerView
