import { FC, Fragment, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useSt } from 'state/state'
import { renderIcon } from 'helpers/icons'
import { displayNotif } from 'helpers/displayNotif'
import HeaderUI from 'components/Header'
import PageContainer from 'components/PageContainer'
import AgencyGeneralView from 'components/agency/AgencyGeneralView'
import AgencyEmployeesView from 'components/agency/AgencyEmployeesView'
import CreateEmployeeModal from 'components/modals/CreateEmployeeModal'
import Loader from 'components/Loader'
import ExecuteModal from 'components/modals/ExecuteActionModal'
import Button from 'antd/lib/button'

const AgencyPage: FC = observer(() => {
    const params: { agencyId: string } = useParams()
    const history = useHistory()
    const st = useSt()
    const { t } = useTranslation()
    const currentAgency = st.agency.current
    const clickAddEmployee = () => {
        st.addModal({
            Comp: <CreateEmployeeModal agencyId={params.agencyId} />,
            title: { key: 'employeeModal.title' },
            description: 'employeeModal.description',
            icon: renderIcon({ name: 'userPlus', circleType: 'primary' }),
        })
    }
    const deleteAgency = async () => {
        st.addModal({
            Comp: (
                <ExecuteModal
                    onExec={() => st.http.deleteAgency(params.agencyId)}
                    onFinish={(status) => {
                        displayNotif(t, status, {
                            key: t('feedback.agency.deleted'),
                            options: { name: currentAgency?.Name },
                        })

                        if (status >= 200 && status <= 204) {
                            history.push('/parameters')
                        }
                    }}
                    isDangerBtn
                />
            ),
            title: {
                key: 'feedback.agency.confirmDelete',
                options: {
                    name: currentAgency?.Name,
                },
            },
            description: 'feedback.agency.descriptionDelete',
            icon: renderIcon({ name: 'trash', circleType: 'error' }),
        })
    }

    useEffect(() => {
        st.agency.load(params.agencyId)
    }, [])

    if (!currentAgency) return <Loader />
    return (
        <Fragment>
            <HeaderUI />
            <PageContainer
                title={currentAgency.Name}
                tabs={[
                    {
                        key: 'general',
                        name: t('common.generalInformations'),
                        button: (
                            <div className="row">
                                <Button className="btn-error mr-3" onClick={deleteAgency}>
                                    {t('agencyPage.delete')}
                                </Button>
                                {st.claims.includes('employees-manager') && (
                                    <Button
                                        type="primary"
                                        icon={renderIcon({ name: 'add' })}
                                        onClick={clickAddEmployee}
                                    >
                                        {t('agencyPage.addEmployee')}
                                    </Button>
                                )}
                            </div>
                        ),
                        render: <AgencyGeneralView />,
                    },
                    {
                        key: 'employees',
                        name: t('gridEmployees.tabs.employee'),
                        button: st.claims.includes('employees-manager') ? (
                            <Button type="primary" icon={renderIcon({ name: 'add' })} onClick={clickAddEmployee}>
                                {t('agencyPage.addEmployee')}
                            </Button>
                        ) : undefined,
                        render: <AgencyEmployeesView agencyId={params.agencyId} />,
                    },
                ]}
            />
        </Fragment>
    )
})
export default AgencyPage
