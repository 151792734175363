import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { renderIcon } from 'helpers/icons'
import HeaderUI from 'components/Header'
import PageContainer from 'components/PageContainer'
import AgencyView from 'components/parameters/AgencyView'
import CompanyView from 'components/parameters/CompanyView'
import LockerView from 'components/parameters/LockerView'
import AgencyDropdown from 'components/agency/AgencyDropdown'
import StripeView from 'components/parameters/StripeView'
import InterfaceView from 'components/parameters/InterfaceView'

import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'

const ParametersPage: FC = () => {
    const { t } = useTranslation()

    return (
        <Fragment>
            <HeaderUI />
            <PageContainer
                title={t('parametersPage.title')}
                subtitle={<Text type="secondary">{t('parametersPage.subtitle')}</Text>}
                tabs={[
                    {
                        key: 'company',
                        name: t('parametersPage.tabs.company'),
                        render: <CompanyView />,
                    },
                    {
                        key: 'agency',
                        name: t('parametersPage.tabs.agency'),
                        render: <AgencyView />,
                        button: <AgencyDropdown />,
                    },
                    {
                        key: 'locker',
                        name: t('parametersPage.tabs.locker'),
                        render: <LockerView />,
                        button: (
                            <Button type="primary" icon={renderIcon({ name: 'add' })}>
                                {t('parametersPage.addLocker')}
                            </Button>
                        ),
                    },
                    {
                        key: 'stripe',
                        name: t('parametersPage.tabs.stripe'),
                        render: <StripeView />,
                    },
                    {
                        key: 'interface',
                        name: t('parametersPage.tabs.interface'),
                        render: <InterfaceView />,
                    },
                ]}
                hideReturn
            />
        </Fragment>
    )
}
export default ParametersPage
