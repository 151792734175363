import { Fragment, FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'helpers/icons'
import HeaderUI from 'components/Header'

import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

const PaymentSuccessPage: FunctionComponent = () => {
    const { t } = useTranslation()
    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <section className="static-section container centered">
                <Icon name="checkCircle" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('paymentSuccess.subHeading')}</Text>
                <Title className="fw-600">{t('paymentSuccess.title')}</Title>
                <Text type="secondary" className="my-10">
                    <Trans i18nKey="paymentSuccess.text.1" />
                </Text>
                <Text type="secondary" className="mt-10">
                    <Trans i18nKey="paymentSuccess.text.2" />
                </Text>
            </section>
        </Fragment>
    )
}

export default PaymentSuccessPage
