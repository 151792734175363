import { FC, Fragment } from 'react'
import i18next from 'i18next'
import Title from 'antd/lib/typography/Title'
import HeaderUI from './Header'

const ExpiredPage: FC = () => {
    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <div className="container expired-container">
                <Title level={1}>{i18next.t('expiredPage.title')}</Title>
            </div>
        </Fragment>
    )
}
export default ExpiredPage