import { FC, Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import HeaderUI from 'components/Header'
import ListInfo from 'components/ListInfo'
import PageContainer from 'components/PageContainer'
import TimelineVehicle from 'components/timeline/vehicle/TimelineVehicle'
import Loader from 'components/Loader'
import { useSt } from 'state/state'
import { renderFormatDate } from 'helpers/time'
import { renderIcon } from 'helpers/icons'
import { renderStatusReservations } from 'helpers/renderStatus'
import { ReservationData } from 'types/reservation'

import Text from 'antd/lib/typography/Text'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

const ReservationPage: FC = observer(() => {
    const st = useSt()
    const { t } = useTranslation()
    const [currentReservation, setReservation] = useState<ReservationData | null>(null)
    const params: { reservationId: string } = useParams()
    useEffect(() => {
        const fetchReservation = async () => {
            const reservation = await st.http.loadReservation(params.reservationId)
            setReservation(reservation[0])
        }
        !currentReservation && fetchReservation()
    }, [params.reservationId, st])

    if (!currentReservation || !st.currentVehicleTimeline) return <Loader />
    return (
        <Fragment>
            <HeaderUI />
            <PageContainer
                title={`Reservation n°${currentReservation.Number}`}
                subtitle={
                    <Fragment>
                        <Text type="secondary">{currentReservation.User.Name}</Text>
                        <Text type="secondary">{currentReservation.User.EmailAddress}</Text>
                        <Link to={`/client/${currentReservation.User.Id}`}>{t('listReservations.clientLink')}</Link>
                    </Fragment>
                }
                tabs={[
                    {
                        key: 'timeline',
                        name: t('reservationPage.timeline'),
                        render: <TimelineVehicle timeline={st.currentVehicleTimeline} />,
                    },
                    {
                        key: 'vehicle',
                        name: (
                            <p className="row">
                                {renderIcon({ name: 'car' })} {t('reservationPage.vehicle')}
                            </p>
                        ),
                        render: (
                            <Row justify="space-between">
                                <Col span={16}>
                                    <ListInfo
                                        data={{
                                            reservationId: currentReservation.Id,
                                            startDate: renderFormatDate(t, currentReservation.StartDate || ''),
                                            endDate: renderFormatDate(t, currentReservation.EndDate || ''),
                                            status: renderStatusReservations(t, currentReservation.Status, true),
                                        }}
                                        title={t('common.generalInformations')}
                                    />
                                </Col>
                                <Col span={7}>
                                    <ListInfo
                                        data={{
                                            vehicleId: currentReservation.Vehicle.Id,
                                            brand: currentReservation.Vehicle.Brand,
                                            model: currentReservation.Vehicle.Model,
                                            registrationNumber: currentReservation.Vehicle.RegistrationNumber,
                                        }}
                                        title={t('common.vehicle')}
                                        link={{
                                            url: `/vehicle/${currentReservation.Vehicle.Id}`,
                                            text: t('listReservations.vehicleLink'),
                                        }}
                                    />
                                </Col>
                            </Row>
                        ),
                    },
                ]}
            />
        </Fragment>
    )
})
export default ReservationPage
