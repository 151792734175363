import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSt } from 'state/state'
import { renderIcon } from 'helpers/icons'
import Dropdown from 'components//Dropdown'
import CreateAgencyModal from 'components/modals/CreateAgencyModal'
import CreateEmployeeModal from 'components/modals/CreateEmployeeModal'

const AgencyDropdown: FC = () => {
    const st = useSt()
    const { t } = useTranslation()
    const items: any[] = [
        {
            text: t('agencyPage.addAgency'),
            onClick: () =>
                st.addModal({
                    Comp: <CreateAgencyModal />,
                    title: { key: 'agencyModal.title' },
                    description: 'agencyModal.description',
                    icon: renderIcon({ name: 'add', circleType: 'primary' }),
                }),
        },
    ]

    if (st.claims.includes('employees-manager')) {
        items.push({
            text: t('agencyPage.addEmployee'),
            onClick: () =>
                st.addModal({
                    Comp: <CreateEmployeeModal />,
                    title: { key: 'employeeModal.title' },
                    description: 'employeeModal.description',
                    icon: renderIcon({ name: 'userPlus', circleType: 'primary' }),
                }),
        })
    }

    return <Dropdown button={{ type: 'primary', text: t('common.add') }} items={items} />
}
export default AgencyDropdown
