import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'helpers/icons'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Dragger from 'antd/lib/upload/Dragger'
import Progress from 'antd/lib/progress'
import { Col, Row } from 'antd/lib/grid'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'

const InterfaceView: FC = () => {
    const { t } = useTranslation()

    return (
        <section>
            <Title level={4}>{t('parametersPage.interface.title')}</Title>
            <Text type="secondary" className="mt-1">
                {t('parametersPage.interface.subtitle')}
            </Text>
            <span className="separator" />
            <Row>
                <Col span={4}>
                    <p className="text-sm c-gray-700 mr-8">{t('parametersPage.interface.logo')}</p>
                </Col>
                <Col span={8}>
                    <Dragger
                        id="interface-file"
                        maxCount={1}
                        customRequest={(options) =>
                            setTimeout(() => {
                                if (options.onSuccess) {
                                    options.onSuccess({}, new XMLHttpRequest())
                                }
                            }, 1000)
                        }
                        itemRender={(node, file, fileList, actions) => (
                            <div className={`ant-upload-list-item ${file.status === 'done' && 'done'}`}>
                                <Icon name="file" circleType="primary" />
                                <div className="ant-upload-list-item-info">
                                    <p className="text-sm fw-500">{file.name}</p>
                                    {file.size && (
                                        <p className="text-sm c-gray-500">{Math.floor(file.size / 1000)} KB</p>
                                    )}
                                    <Progress percent={file.percent} format={(percent) => percent + '%'} />
                                </div>
                                {file.status === 'uploading' ? (
                                    <button onClick={actions.remove}>
                                        <Icon name="trash" />
                                    </button>
                                ) : (
                                    <div className="ant-upload-success">
                                        <Icon name="check" />
                                    </div>
                                )}
                            </div>
                        )}
                        onRemove={() => console.log('REMOVE PDF', '')}
                        onChange={async (info) => {
                            if (info.file.status === 'done') {
                                // const file = info.file.originFileObj
                                // const blob = await file?.arrayBuffer().then((arrayBuffer) => {
                                //     return new Blob([new Uint8Array(arrayBuffer)], { type: file?.type })
                                // })
                                // modalForm.setDataValue('Invoice', {
                                //     name: info.file.name,
                                //     blob,
                                // })
                            }
                        }}
                    >
                        <div className="btn-upload">
                            <Icon name="upload" circleType="secondary" />
                            <div className="row mt-3">
                                <p>
                                    <b className="c-primary mr-1">{t('invoiceModal.import')}</b>
                                </p>
                                <p>{t('invoiceModal.drag')}</p>
                            </div>
                            <p className="mt-1 text-xs">{t('invoiceModal.restriction')}</p>
                        </div>
                    </Dragger>
                </Col>
            </Row>
            <span className="separator" />
            <Row>
                <Col span={4}>
                    <p className="text-sm c-gray-700">{t('parametersPage.interface.primaryColor')}</p>
                </Col>
                <Col span={8}>
                    <Input
                        id="interface-primary-color"
                        addonBefore={
                            <Select defaultValue="HEX" className="select-before">
                                <Select.Option value="HEX">HEX</Select.Option>
                                <Select.Option value="RGB">RGB</Select.Option>
                            </Select>
                        }
                        defaultValue="0050D2"
                    />
                </Col>
            </Row>
        </section>
    )
}
export default InterfaceView
