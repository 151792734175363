import { FC, Fragment, KeyboardEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useModalForm } from 'state/modalForm'
import Loader from 'components/Loader'
import { useSt } from 'state/state'
import { columnsVehiclesModal } from 'static/vehicle'
import { VehicleType } from 'types/vehicle'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import { Switch } from 'antd'
import Text from 'antd/lib/typography/Text'

const VehiclesModal: FC = observer(() => {
    const st = useSt()
    const { t } = useTranslation()
    const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null)
    const [list, setList] = useState<VehicleType[] | null>(null)
    const [filteredList, setFilteredList] = useState<VehicleType[] | null>(null)
    const [searchValue, setSearchValue] = useState<string>('')
    const modalForm = useModalForm()

    const filterVehicles = (value: string) => {
        if (value === '') {
            setSelectedVehicle(null)
            setFilteredList(null)
        } else {
            setFilteredList(
                list?.filter(
                    (vehicle) =>
                        vehicle.RegistrationNumber.startsWith(value) ||
                        vehicle.Brand.startsWith(value) ||
                        vehicle.Model.startsWith(value) ||
                        vehicle.Id.startsWith(value),
                ) || null,
            )
        }
    }

    useEffect(() => {
        const loadVehicles = async () => {
            const vehicles = await st.http.loadAllAvailableVehicles(true)
            setList(vehicles)
        }
        !list && loadVehicles()
    }, [])

    if (!list) return <Loader />
    return (
        <Fragment>
            <div className="form-body">
                <Form.Item label={t('form.label.searchVehicle')}>
                    <Input
                        id="vehicle-search"
                        type="text"
                        placeholder={t('form.placeholder.searchVehicle')}
                        onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                            const value = (e.target as HTMLInputElement).value
                            setSearchValue(value)
                            filterVehicles(value)
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <div className="row">
                        <Switch
                            //id="vehicle-availability"
                            defaultChecked
                            onChange={async (value) => {
                                setList(await st.http.loadAllAvailableVehicles(value))
                                filterVehicles(searchValue)
                            }}
                        />
                        <Text className="ml-2 text-sm">{t('form.label.availableVehicles')}</Text>
                    </div>
                </Form.Item>
                {list && (
                    <Table
                        dataSource={filteredList ? filteredList : list}
                        columns={columnsVehiclesModal(t)}
                        rowKey="Id"
                        rowClassName={(record) => {
                            return record.Id === selectedVehicle ? 'row-selected' : ''
                        }}
                        pagination={false}
                        loading={false}
                        className="table-selection"
                        onRow={(column) => ({
                            onClick: () => {
                                setSelectedVehicle(column.Id)

                                modalForm.setDataValue('VehicleId', column.Id)
                            },
                        })}
                    />
                )}
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary" disabled={!selectedVehicle}>
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})
export default VehiclesModal
