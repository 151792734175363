import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useModalForm } from 'state/modalForm'
import { renderIcon } from 'helpers/icons'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Text from 'antd/lib/typography/Text'
import DatePicker from 'antd/lib/date-picker'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import localeEn from 'antd/es/date-picker/locale/en_US'
import localeIt from 'antd/es/date-picker/locale/it_IT'
import Input from 'antd/lib/input'

import 'moment/locale/fr'
import 'moment/locale/it'

const ReservationsDateModal: FC = observer(() => {
    const { t, i18n } = useTranslation()
    const modalForm = useModalForm()
    const [showStart, setShowStart] = useState(true)
    const [showReturn, setShowReturn] = useState(true)

    const locales = {
        en: localeEn,
        fr: localeFr,
        it: localeIt,
    }

    let lang = 'en'
    if (i18n.language === 'fr-FR' || i18n.language === 'fr') lang = 'fr'
    if (i18n.language === 'it-IT' || i18n.language === 'it') lang = 'it'

    const locale = locales[lang]

    function disabledDate(current) {
        return current && modalForm.data['PickUpDate'] && current < dayjs(modalForm.data['PickUpDate'])
    }

    return (
        <Fragment>
            <div className="form-body form-date">
                <Form.Item>
                    <div className="row">
                        <Switch
                            //id="reservation-pickup-switch"
                            defaultChecked
                            onChange={() => {
                                modalForm.removeField('PickUpDate')
                                setShowStart((val) => !val)
                            }}
                        />
                        <Text className="ml-2 text-sm">{t('reservationsDateModal.pickUpDate')}</Text>
                    </div>
                    {showStart && (
                        <DatePicker
                            id="reservation-pickup"
                            locale={{
                                ...locale,
                                lang: {
                                    ...locale.lang,
                                    now: t('common.now'),
                                    ok: t('common.confirm'),
                                },
                            }}
                            showTime
                            className="mt-2"
                            defaultValue={undefined}
                            placeholder={t('reservationsDateModal.pickUpPlaceholder')}
                            onChange={(date, dateString) =>
                                modalForm.setDataValue('PickUpDate', dayjs(dateString).toISOString())
                            }
                            suffixIcon={renderIcon({ name: 'calendar' })}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <div className="row">
                        <Switch
                            //id="reservation-dropoff-switch"
                            defaultChecked
                            onChange={() => {
                                modalForm.removeField('DropOffDate')
                                setShowReturn((val) => !val)
                            }}
                        />
                        <Text className="ml-2 text-sm">{t('reservationsDateModal.dropOffDate')}</Text>
                    </div>
                    {showReturn && (
                        <DatePicker
                            id="reservation-dropoff"
                            locale={{
                                ...locale,
                                lang: {
                                    ...locale.lang,
                                    now: t('common.now'),
                                    ok: t('common.confirm'),
                                },
                            }}
                            showTime
                            className="mt-2"
                            defaultValue={undefined}
                            placeholder={t('reservationsDateModal.dropOffPlaceholder')}
                            onChange={(date, dateString) =>
                                modalForm.setDataValue('DropOffDate', dayjs(dateString).toISOString())
                            }
                            disabledDate={disabledDate}
                            suffixIcon={renderIcon({ name: 'calendar' })}
                        />
                    )}
                </Form.Item>
                <Form.Item label={t('form.label.rentalId')} name="RentalId" rules={[{ required: true }]}>
                    <Input id="reservation-rental-id" type="text" placeholder={t(`form.placeholder.rentalId`)} />
                </Form.Item>
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button
                    htmlType="submit"
                    type="primary"
                    disabled={
                        (showReturn && !modalForm.getField('DropOffDate')) ||
                        (showStart && !modalForm.getField('PickUpDate')) ||
                        (!showStart && !showReturn)
                    }
                >
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})
export default ReservationsDateModal
