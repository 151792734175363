import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import List from 'antd/lib/list'
import Button from 'antd/lib/button'
import Text from 'antd/lib/typography/Text'

type ListInfoParams = {
    title: string
    data: Record<string, any>
    onEdit?: () => void
    link?: {
        url: string
        text: string
    }
}

const ListInfo: FC<ListInfoParams> = ({ title, data, onEdit, link }) => {
    const { t } = useTranslation()
    const [limit, setLimit] = useState(5)

    const loadMore =
        limit < Object.keys(data).length ? (
            <Button type="ghost" onClick={() => setLimit((prevState) => prevState + 5)} className="mt-6">
                {t('common.seeMore')}
            </Button>
        ) : null

    return (
        <List
            header={
                <div className="row between">
                    <Text strong>{title}</Text>
                    {onEdit && (
                        <Button type="ghost" onClick={onEdit}>
                            {t('common.modifyInfo')}
                        </Button>
                    )}
                    {link && <Link to={link.url}>{link.text}</Link>}
                </div>
            }
            loadMore={loadMore}
            dataSource={Object.keys(data).splice(0, limit)}
            renderItem={(item) => {
                const renderText = data[item as keyof typeof data] !== undefined ? data[item] : '-'

                if (typeof data[item] === 'object') return undefined
                return (
                    <List.Item>
                        <div className="row between">
                            <p className="text-sm c-gray-500">{t(`informations.${item}`)}</p>
                            <p className="text-sm fw-500">{renderText}</p>
                        </div>
                    </List.Item>
                )
            }}
        />
    )
}
export default ListInfo
