// src/auth/auth0-provider-with-history.js

import { FC, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

type AuthParams = {
    children: ReactNode
}

const Auth0ProviderWithHistory: FC<AuthParams> = ({ children }) => {
    // eslint-disable-next-line no-undef
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    // eslint-disable-next-line no-undef
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    // eslint-disable-next-line no-undef
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE

    const history = useHistory()

    const onRedirectCallback = (appState: any) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    if (!domain || !clientId) throw new Error('missing variables in .env file')
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
