import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useModalForm } from 'state/modalForm'
import { Icon, renderIcon } from 'helpers/icons'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'

type ObjectModalParams = {
    items?: { Name: string }[]
    withHistoryLink?: boolean
    skipStep?: boolean
}

const ObjectModal: FC<ObjectModalParams> = observer(({ items, withHistoryLink, skipStep }) => {
    const { t } = useTranslation()
    const modalForm = useModalForm()

    return (
        <Fragment>
            <div className="form-body">
                {items ? (
                    <Form.Item label={t('form.label.selectObject')} name="SelectedObject" rules={[{ required: true }]}>
                        <Select
                            id="object-select"
                            placeholder={t('objectModal.selectPlaceholder')}
                            suffixIcon={renderIcon({ name: 'arrowDown' })}
                        >
                            {items.map((item, idx) => (
                                <Select.Option key={idx} value={item.Name}>
                                    {item.Name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item
                        label={t('form.label.PersonalItemName')}
                        name="PersonalItemName"
                        rules={[{ required: !skipStep }]}
                    >
                        <Input
                            id="object-personal-item-name"
                            type="text"
                            placeholder={t(`form.placeholder.PersonalItemName`)}
                        />
                    </Form.Item>
                )}
                {items && modalForm.steps[modalForm.currentStep].alternativeComp && (
                    <Button type="link" icon={<Icon name="add" />} onClick={() => (modalForm.renderAlternative = true)}>
                        {t('objectModal.addObject')}
                    </Button>
                )}
                {withHistoryLink && (
                    <Button
                        type="link"
                        icon={<Icon name="arrowBack" />}
                        onClick={() => (modalForm.renderAlternative = false)}
                    >
                        {t('objectModal.backHistory')}
                    </Button>
                )}
                {skipStep && (
                    <Button type="text" onClick={() => modalForm.nextStep(modalForm.getData)}>
                        {t('form.skipStep')}
                    </Button>
                )}
            </div>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button htmlType="submit" type="primary">
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})

export default ObjectModal
