import { renderFullDate } from './time'

export const groupBy = (key: string, array: any[], listEvents: any[]): Map<string, any> => {
    const map = new Map()
    if (!array || array.length === 0) return map

    array.forEach((item) => {
        const itemKey = key === 'DateOfOccurence' ? renderFullDate(item[key] as string) : item[key]

        if (listEvents.includes(item.Name)) {
            if (map.has(itemKey)) {
                map.get(itemKey).push(item)
            } else {
                map.set(itemKey, [item])
            }
        }
    })

    return map
}
