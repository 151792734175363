import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ListInfo from 'components/ListInfo'
import { Col, Row } from 'antd/lib/grid'

const CompanyView: FC = () => {
    const { t } = useTranslation()
    return (
        <Row justify="space-between">
            <Col span={15}>
                <ListInfo
                    data={{
                        companyName: 'EFIKEYS',
                        Address: '7-11 Rue Sainte-Lucie, 75015 Paris',
                        PhoneNumber: '+33 6 66 37 50 68',
                        Email: 'olivier.rossinelli@efikeys.com',
                    }}
                    title={t('common.generalInformations')}
                />
            </Col>
            <Col span={8}>
                <ListInfo
                    data={{
                        companyID: '879426',
                    }}
                    title={t('common.details')}
                />
            </Col>
        </Row>
    )
}
export default CompanyView
