import { autorun, makeAutoObservable } from 'mobx'
import type { State } from './state'

export class AuthManager {
    email = ''
    password = ''

    msgError = ''
    isAuthenticated = false

    login = () => {
        if (!this.areCredentialsValid()) this.msgError = 'Incorrect email or password'
        else this.isAuthenticated = true;
    }

    logout = () => {
        this.password = ''
        this.isAuthenticated = false
    }

    constructor(public state: State) {
        makeAutoObservable(this)
        this.load('email')
        this.load('password')
        if (this.areCredentialsValid()) this.login()
        this.autosave('email')
        this.autosave('password')
    }

    private areCredentialsValid = () =>
    ((this.email === 'test@test.fr' && this.password === 'test') ||
        (this.email === 'olivier.rossinelli@efikeys.com' && this.password === 'olivier') ||
        (this.email === 'anouche@efikeys.com' && this.password === 'anouche'))

    private load = (key: 'email' | 'password') => {
        const val = localStorage.getItem(key)
        if (typeof val === 'string') this[key] = val
    }

    private autosave = (key: 'email' | 'password') => {
        autorun(() => localStorage.setItem(key, this[key]))
    }
}
