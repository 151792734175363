import { makeAutoObservable } from 'mobx'
import { State } from './state'
import { AgencyData, AgencyRoles, AgencyType } from 'types/agency'
import { GridData } from 'types/grid'

export class Agency {
    state: State
    all: GridData<AgencyData> | null = null
    current: AgencyData | null = null
    currentByIds: AgencyData | null = null
    roles: AgencyRoles[] | null = null

    constructor(state: State) {
        makeAutoObservable(this)
        this.state = state
    }

    get rolesName(): string | undefined {
        return this.current?.GrantedRoles.map((role) => role.RoleName).join(', ')
    }

    loadAll = async (page?: number) => {
        const data = await this.state.http.loadAllAgencies(page)
        this.all = data
    }

    load = async (agencyId: string) => {
        const req = await this.state.http.loadAgency(agencyId)
        this.current = req.data
        req.data.GrantedRoles = this.convertRolesToIDs(req.data.GrantedRoles as AgencyRoles[])
        this.currentByIds = req.data
    }

    loadRoles = async () => {
        const data = await this.state.http.getAllRoles()
        this.roles = data
    }

    // Change roles to array with -> [roleId, ...] to prevent error on form
    convertRolesToIDs = (data: AgencyRoles[]) => {
        return data.map((role) => role.RoleId)
    }

    // // Change array of [roleId, ...] -> [{AgencyRole}, ...]
    convertRolesToObj = (data: AgencyType | AgencyData) => {
        return data.GrantedRoles.map((roleId, index) => {
            const matchRole = this.roles?.find((role) => role.RoleId === roleId)
            if (matchRole) data.GrantedRoles[index] = matchRole
        })
    }

    getRolesObject = (data: AgencyType | AgencyData): AgencyRoles[] | string[] => {
        const grantedRoles: AgencyRoles[] | string[] = []
        data.GrantedRoles.forEach((roleId, index) => {
            const matchRole = this.roles?.find((role) => role.RoleId === roleId)
            if (matchRole) grantedRoles[index] = matchRole
        })
        return grantedRoles
    }
}
