import { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Icon } from 'helpers/icons'
import { useModalForm } from 'state/modalForm'
import { useSt } from 'state/state'
import { User } from 'state/user'
import { UserData } from 'types/user'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Text from 'antd/lib/typography/Text'
import AutoComplete from 'antd/lib/auto-complete'
import Loader from 'components/Loader'

const SelectClientModal: FC = observer(() => {
    const st = useSt()
    const [searchValue, setSearchValue] = useState('')
    const [clients, setClients] = useState<User[] | null>(st.clients?.Data || null)
    const { t } = useTranslation()
    const modalForm = useModalForm()

    useEffect(() => {
        if (!st.clients) {
            const getClients = async () => {
                const dataClients = await st.http.loadClients()

                setClients(dataClients.Data)
            }
            getClients()
        }
    }, [])

    const handleSearch = async (value: string) => {
        if (value.length >= 3) {
            const filteredData =
                st.clients?.Data.filter((item: UserData) => {
                    return item.Firstname.includes(value) || item.Lastname.includes(value) || item.Email.includes(value)
                }) || null
            setClients(filteredData)
        } else if (value.length === 0) {
            setClients(st.clients?.Data || null)
        } else {
            setClients([])
        }
    }

    if (!clients) return <Loader />
    return (
        <Fragment>
            <div className="form-body mb-3">
                <Form.Item label={t('form.label.fullName')} rules={[{ required: true }]}>
                    <AutoComplete
                        id="client-autocomplete"
                        value={searchValue}
                        placeholder={t(`form.placeholder.fullName`)}
                        onSearch={handleSearch}
                        onChange={(value) => setSearchValue(value)}
                        onSelect={(value, option) => modalForm.setDataValue('SelectedUser', option.key)}
                    >
                        {clients?.map((client) => (
                            <AutoComplete.Option key={client.Id} value={client.fullName}>
                                <p className="mr-2">{client.fullName}</p>
                                <Text type="secondary">{client.Email}</Text>
                                <span className="ant-select-dropdown-icon">
                                    <Icon name="check" />
                                </span>
                            </AutoComplete.Option>
                        ))}
                        {searchValue.length >= 3 && clients?.length === 0 && (
                            <AutoComplete.Option value="" className="dropdown-search_noResults">
                                <p>{t('common.noResults')}</p>
                            </AutoComplete.Option>
                        )}
                    </AutoComplete>
                </Form.Item>
            </div>
            <Button type="link" icon={<Icon name="add" />} onClick={() => (modalForm.renderAlternative = true)}>
                {t('selectClientModal.newClient')}
            </Button>
            <div className="form-footer">
                <Button type="ghost" onClick={modalForm.prevStep}>
                    {t('common.cancel')}
                </Button>
                <Button
                    htmlType="submit"
                    type="primary"
                    //disabled={!modalForm.schema.client}
                >
                    {modalForm.currentStep < modalForm.steps.length - 1 ? t('common.next') : t('common.save')}
                </Button>
            </div>
        </Fragment>
    )
})
export default SelectClientModal
